import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import useCustomEventListener from 'hooks/useCustomEventListener';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import SpriteIcon from 'components/ui/SpriteIcon';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getColors,
  getImageType,
  getInspirationImgUrl,
  getStyleNames,
  getUserAvatarThumbUrl,
} from 'modules/app/store/selectors';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import {
  toggleTermsModalAction,
  toggleUnderConstructionModalAction,
} from 'modules/layout/store/actions';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import {
  setClaimImageIdAction,
  toggleClaimImageModalAction,
} from 'modules/report/store/actions';
import Button from 'components/ui/Button/Button';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import useFollowings from 'hooks/useFollowings';
import useCancelToken from 'hooks/useCancelToken';
import clsx from 'clsx';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import BasicAvatar from 'components/ui/BasicAvatar';
import classes from './SelectedLookBoardBlock.module.scss';
import LookBoardPreview from '../../../../components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import InspirationImagesModal from '../InspirationImagesModal';

// TODO: Get limit value from application config
const limit = 10;
const unauthorizedLimit = 100;

const SelectedLookBoardBlock = ({
  inspirationImageId,
  searchParams,
  onBack,
  userAvatarThumbUrl,
  updateIILibrary,
  authenticated,
  authorizedUserId,
  lookBoard,
  lookBoardProducts,
  handleSelectLookBoard,
  handleLookBoardsData,
  handleSetImageList,
}) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const history = useHistory();

  const containerRef = useRef(null);
  const scrollBarRef = useRef(null);
  const [initialScrollerHeight, setInitialScrollerHeight] = useState(0);
  const [customScrollerHeight, setCustomScrollerHeight] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentSearchParams, setCurrentSearchParams] = useState(searchParams);
  const [allLookBoardsData, setAllLookBoardsData] = useState(null);
  const [inspImagesModal, setInspImagesModal] = useState(false);

  const selectedImage = useSelector(
    (state) => state.inspirationImage.library[inspirationImageId]
  );

  const {
    id,
    userId,
    type,
    styles: styleIds,
    colors: colorIds,
    roomTypeId,
    itemClassId,
    subStyles,
  } = selectedImage;

  // const [createCancelTokenLoadFirst] = useCancelToken();
  const [createCancelTokenForImages, isCancelled] = useCancelToken();
  const [createCancelTokenForUser] = useCancelToken();

  const [followed, handleFollowChange] = useFollowings({
    followUserId: currentUser?.id,
    followUserName: `${currentUser?.firstName} ${currentUser?.lastName}`,
  });

  const handleUpdateContainerHeight = useCallback(() => {
    setCustomScrollerHeight(
      Math.round(document.documentElement.clientHeight + 50)
    );
  }, [setCustomScrollerHeight]);

  useCustomEventListener(SET_INITIAL_SCROLLER_HEIGHT, () => {
    setCustomScrollerHeight(initialScrollerHeight);
  });

  useCustomEventListener(UPDATE_SCROLLER_HEIGHT, () => {
    handleUpdateContainerHeight();
  });

  useEffect(() => {
    const refHeight = containerRef.current?.clientHeight;
    setInitialScrollerHeight(Math.round(refHeight));

    handleUpdateContainerHeight();

    (async () => {
      try {
        const cancelToken = createCancelTokenForUser();
        const someUser = await currentUserService.getUserById(userId, {
          cancelToken,
        });
        setCurrentUser(someUser);
      } catch (e) {
        if (!isCancelled) {
          errorToastr('Error', e.generalError);
        }
      }
    })();
    // eslint-disable-next-line
  }, [createCancelTokenForUser, isCancelled]);

  useEffect(handleUpdateContainerHeight, [
    handleUpdateContainerHeight,
    imageList,
  ]);

  const getSimilarLookBoards = async (imageIds) => {
    const promiseArr = imageIds.map((imageId) => {
      return inspirationImageService.getLookBoardDetails(imageId);
    });

    const result = await Promise.all(promiseArr);
    const lookBoardsData = imageIds?.reduce(
      (accum, item, index) => ({ ...accum, [item]: result[index].result }),
      {}
    );
    setAllLookBoardsData(lookBoardsData);
    handleLookBoardsData(lookBoardsData);
  };

  const loadFirst = useCallback(
    async () => {
      setLoading(true);
      try {
        const cancelToken = createCancelTokenForImages();
        const preparedSubStyles = {};
        subStyles.forEach((subStyle) => {
          preparedSubStyles[subStyle] = 1;
        });
        const filterParams = {
          styles: styleIds,
          colors: colorIds,
          ...(roomTypeId ? { roomTypes: [roomTypeId] } : {}),
          ...(itemClassId ? { itemClasses: [itemClassId] } : {}),
          subStyles: preparedSubStyles,
        };

        const preparedSearchParams =
          currentSearchParams.source === imageSourceKeys.requests
            ? { ...currentSearchParams, source: imageSourceKeys.all }
            : currentSearchParams;

        const [
          library,
          result,
        ] = await inspirationImageService.getSimilarImages(
          { ...preparedSearchParams, ...filterParams },
          {
            cancelToken,
          }
        );
        updateIILibrary(library);

        const imageIds = result.map((item) => item.id);
        getSimilarLookBoards(imageIds);
        const onlySimilarImagesData = result.filter(
          ({ id: currId }) => currId !== id
        );
        const onlyMainImageData = result.filter(
          ({ id: currId }) => currId === id
        );
        // const imagesData = result;
        setImageList(onlySimilarImagesData);
        handleSetImageList(onlyMainImageData);
        setCurrentSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + result.length,
          source:
            prevState.source === imageSourceKeys.requests
              ? imageSourceKeys.all
              : prevState.source,
        }));
        setHasMore(result.length === limit);
        setLoading(false);
      } catch (e) {
        if (!isCancelled(e)) {
          errorToastr('Error', e.generalError);
        }
      }
    }, // eslint-disable-next-line
    [
      createCancelTokenForImages,
      styleIds,
      subStyles,
      colorIds,
      roomTypeId,
      itemClassId,
      currentSearchParams,
      updateIILibrary,
      id,
      isCancelled,
    ]
  );

  useEffect(() => {
    setImageList([]);
    const optionalParams =
      type === imageTypeKeys.room
        ? { roomTypes: [roomTypeId] }
        : { items: itemClassId ? [itemClassId] : [] }; // This ternary only for development purposes, since seed II with type 'single' may not have itemClassId
    setCurrentSearchParams({
      ...searchParams,
      styles: styleIds,
      colors: colorIds,
      ...optionalParams,
      offset: 0,
      source:
        searchParams.source === imageSourceKeys.requests
          ? imageSourceKeys.all
          : searchParams.source,
    });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    (async () => {
      if (currentSearchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [currentSearchParams.offset]);

  const avatarUrl = useMemo(() => {
    if (currentUser && currentUser.avatar) {
      const { userId: currentUserId, hash: avatarHash } = currentUser.avatar;
      return buildResourceUrl(userAvatarThumbUrl, currentUserId, avatarHash);
    }
    return null;
  }, [currentUser, userAvatarThumbUrl]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    if (currentSearchParams.offset === 0) {
      return;
    }
    const preparedSearchParams =
      currentSearchParams.source === imageSourceKeys.requests
        ? { ...currentSearchParams, source: imageSourceKeys.all }
        : currentSearchParams;

    const preparedSubStyles = {};
    subStyles.forEach((subStyle) => {
      preparedSubStyles[subStyle] = 1;
    });
    const filterParams = {
      styles: styleIds,
      colors: colorIds,
      ...(roomTypeId ? { roomTypes: [roomTypeId] } : {}),
      ...(itemClassId ? { itemClasses: [itemClassId] } : {}),
      subStyles: preparedSubStyles,
    };

    try {
      const cancelToken = createCancelTokenForImages();
      const [library, result] = await inspirationImageService.getSimilarImages(
        { ...preparedSearchParams, ...filterParams },
        {
          cancelToken,
        }
      );
      updateIILibrary(library);
      setImageList((prevState) => {
        return [
          ...prevState,
          ...result.filter(({ id: currId }) => currId !== id),
        ];
      });
      setCurrentSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + result.length,
        source:
          prevState.source === imageSourceKeys.requests
            ? imageSourceKeys.all
            : prevState.source,
      }));

      const updatedListLength = imageList.length + result.length;
      if (!authenticated && updatedListLength >= unauthorizedLimit) {
        setHasMore(false);
      } else {
        setHasMore(result.length === limit);
      }
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [
    currentSearchParams,
    subStyles,
    styleIds,
    colorIds,
    roomTypeId,
    itemClassId,
    createCancelTokenForImages,
    updateIILibrary,
    imageList.length,
    authenticated,
    id,
    isCancelled,
  ]);

  const handleCloseImagesModal = useCallback(() => {
    setInspImagesModal(false);
  }, []);

  const handleOpenLargePage = useCallback(() => {
    history.push(routesByName.lookBoard.details(lookBoard.id, lookBoard.slug));
  }, [history, lookBoard.id, lookBoard.slug]);

  const showSimilarTitle = useMemo(() => {
    if (!imageList.length || !allLookBoardsData) {
      return false;
    }
    const arr = [];
    imageList.map(({ id: similarImageId }) => {
      const similarLookBoard = allLookBoardsData[similarImageId]?.lookBoards;

      if (similarLookBoard?.length) arr.push(...similarLookBoard);
      return true;
    });
    return !!arr.length;
  }, [allLookBoardsData, imageList]);

  return (
    <>
      <div className={clsx('flex-fill d-flex flex-column')}>
        {matchesMediaQuery ? (
          <div className="mt-2 mb-3 d-flex align-items-center" onClick={onBack}>
            <SpriteIcon
              name="left-arrow"
              size="sm"
              className="mr-1 primary-color"
            />
            <span>Back</span>
          </div>
        ) : (
          <div
            className="d-flex align-items-center cursor-pointer mb-3"
            onClick={onBack}
          >
            <Button variant="outlined" className={`${classes.goBackBtn} mt-1`}>
              <SpriteIcon
                name="left-arrow"
                size="sm"
                className="primary-color mr-1"
              />
              <span>Back To All Inspiration Images</span>
            </Button>
          </div>
        )}
        <div className="flex-fill" ref={containerRef}>
          <CustomScrollBar
            scrollBarRef={scrollBarRef}
            autoHeightMin={matchesMediaQuery ? '' : customScrollerHeight}
            // autoHeightMax={matchesMediaQuery ? '' : customScrollerHeight}
            renderView={(props) => (
              <div
                {...props}
                className={clsx({
                  'pr-2': !matchesMediaQuery,
                })}
              />
            )}
          >
            <LookBoardPreview
              title={lookBoard.title}
              color={lookBoard.color}
              isLiked={lookBoard.isLiked}
              isVoted={lookBoard.isVoted}
              banner={lookBoard.banner}
              products={lookBoardProducts}
              showLike={false}
              hoverOverlay={
                <div className={classes.lookBoardOverlay}>
                  <span
                    className={classes.lookBoardOverlayTitle}
                    onClick={handleOpenLargePage}
                  >
                    View this Look Board
                  </span>
                  <Button
                    className={`${classes.lookBoardsModalBtn} chrome-picker`}
                    size="xs"
                    color="secondary"
                    onClick={() => setInspImagesModal(true)}
                    inline
                  >
                    <span>Inspirations</span>
                  </Button>
                </div>
              }
            />

            {!matchesMediaQuery && (
              <>
                <div className={`${classes.bordered} mb-3`}>
                  {currentUser && (
                    <div className="d-flex align-items-center">
                      <div className="flex-fill d-flex align-items-center text-ellipsis mr-2">
                        <BasicAvatar
                          firstName={currentUser.firstName}
                          lastName={currentUser.lastName}
                          src={avatarUrl}
                        />
                        <p className="text-sm ml-1 text-ellipsis font-semi-bold">
                          {currentUser.displayName ??
                            `${currentUser.firstName} ${currentUser.lastName}`}
                        </p>
                      </div>
                      {currentUser?.id !== authorizedUserId && (
                        <Button
                          inline
                          color="secondary"
                          variant={followed ? 'contained' : 'outlined'}
                          size="sm"
                          onClick={handleFollowChange}
                        >
                          {followed ? 'Following' : 'Follow'}
                        </Button>
                      )}
                    </div>
                  )}
                </div>

                {showSimilarTitle && (
                  <p className="mb-3 text-lg text-capitalize font-semi-bold">
                    Similar Looks
                  </p>
                )}

                <CustomInfiniteScroll
                  parentHeight={customScrollerHeight}
                  isLoading={loading}
                  hasMore={hasMore}
                  loadMore={loadMore}
                  initialLoad={false}
                  useWindow={false}
                >
                  {allLookBoardsData &&
                    imageList.map(({ id: similarImageId }) => {
                      const similarLookBoard =
                        allLookBoardsData[similarImageId]?.lookBoards[0];
                      if (!similarLookBoard) return null;
                      const allProducts =
                        allLookBoardsData[similarImageId].products;
                      const idProductsArr = similarLookBoard.products;
                      const productsArr = allProducts.filter((obj) =>
                        idProductsArr.includes(obj.id)
                      );
                      return (
                        <div className="mt-2 mb-2" key={similarLookBoard.id}>
                          <LookBoardPreview
                            title={similarLookBoard.title}
                            color={similarLookBoard.color}
                            isLiked={similarLookBoard.isLiked}
                            isVoted={similarLookBoard.isVoted}
                            banner={similarLookBoard.banner}
                            products={productsArr}
                            showLike={false}
                            hoverOverlay={
                              <div
                                className={classes.similarLookBoardHover}
                                onClick={() =>
                                  handleSelectLookBoard(
                                    similarLookBoard.id,
                                    similarLookBoard.slug
                                  )
                                }
                              >
                                <span
                                  className={classes.similarLookBoardHoverTitle}
                                >
                                  Select this Look Board
                                </span>
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                </CustomInfiniteScroll>
              </>
            )}
          </CustomScrollBar>
        </div>
      </div>
      <InspirationImagesModal
        onClose={handleCloseImagesModal}
        visible={inspImagesModal}
        imageList={imageList}
      />
    </>
  );
};

SelectedLookBoardBlock.propTypes = {
  inspirationImageId: PropTypes.number.isRequired,
  requestId: PropTypes.number,
  searchParams: PropTypes.shape({ source: PropTypes.string }).isRequired,
  onBack: PropTypes.func.isRequired,
  onOpenPreview: PropTypes.func,
  userAvatarThumbUrl: PropTypes.string.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  imgType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleClaimImageModal: PropTypes.func.isRequired,
  setClaimImageId: PropTypes.func.isRequired,
  toggleTermsModal: PropTypes.func.isRequired,
  updateIILibrary: PropTypes.func.isRequired,
  requestInfo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    priorityItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  authenticated: PropTypes.bool.isRequired,
  authorizedUserId: PropTypes.number,
  onRequestDetailsPreview: PropTypes.func,
  lookBoardProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lookBoard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isLiked: PropTypes.number.isRequired,
    lookBoardSocialImage: PropTypes.string,
    lookBoardSocialImageFacebook: PropTypes.string,
    isVoted: PropTypes.bool,
    banner: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    ).isRequired,
    shareUrl: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  handleSelectLookBoard: PropTypes.func.isRequired,
  handleLookBoardsData: PropTypes.func,
  handleSetImageList: PropTypes.func,
};

SelectedLookBoardBlock.defaultProps = {
  requestId: null,
  requestInfo: null,
  authorizedUserId: null,
  onRequestDetailsPreview: null,
  onOpenPreview: () => {},
  handleLookBoardsData: () => {},
  handleSetImageList: () => {},
};

const mapStateToProps = (
  {
    app: { config, enums },
    auth: { user },
    inspirationImage: { library },
    curateTheLook: {
      requestInfo,
      lookBoardData: { requestId },
    },
  },
  { inspirationImageId }
) => ({
  authorizedUserId: user?.id ?? null,
  authenticated: Boolean(user),
  userAvatarThumbUrl: getUserAvatarThumbUrl(config),
  inspirationImageUrl: getInspirationImgUrl(config),
  styles: getStyleNames(library[inspirationImageId]?.styles ?? [], enums),
  imgType: getImageType(library[inspirationImageId] ?? {}, enums),
  colors: getColors(library[inspirationImageId]?.colors ?? [], enums),
  requestId,
  requestInfo,
});

const mapDispatchToProps = {
  toggleUnderConstructionModal: toggleUnderConstructionModalAction,
  toggleClaimImageModal: toggleClaimImageModalAction,
  setClaimImageId: setClaimImageIdAction,
  toggleTermsModal: toggleTermsModalAction,
  updateIILibrary: updateIILibraryAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedLookBoardBlock);
