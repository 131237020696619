import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import {
  setAmbassadorsActiveTabAction,
  syncSelectedAmbassadorDataAction,
  toggleLikeAmbassadorLookBoardAction,
  voteAmbassadorLookBoardAction,
} from 'modules/getTheLook/ambassadorPage/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import useCallbackRef from 'hooks/useCallbackRef';
import buildResourceUrl from 'utils/buildResourceUrl';
import GTLSlider from 'modules/getTheLook/components/GTLSlider';
import AmbassadorCard from 'components/userThumbnails/AmbassadorCard/AmbassadorCard';
import SpriteIcon from 'components/ui/SpriteIcon';
import { useDispatch, useSelector } from 'react-redux';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import NoLookBoardsModal from 'modules/getTheLook/ambassadorPage/components/NoLookBoardsModal/NoLookBoardsModal';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import findObjectById from 'utils/findObjectById';
import { getTheLookAmbassadorsTabKeys } from './config';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const AmbassadorListItem = ({
  ambassadorData: {
    user: {
      ambassador,
      firstName,
      lastName,
      avatar,
      lookBoards: lookBoardsCount,
      styles: ambassadorStyles,
      badge,
      displayName,
    },
    urlKey,
    id,
    userId,
    pageName,
    banner,
    featuredLookBoards,
    videoUrl,
    videoUrlIsActive,
  },
  handleSetVideoLink,
}) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sliderNode, sliderRef] = useCallbackRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [slideLength, setSlideLength] = useState(0);
  const [hover, setHover] = useState(false);

  const { activeTab, lookBoardsData } = useSelector(
    (state) => state.ambassadorsPage
  );
  const { showcaseBanner, userAvatar } = useSelector(
    (state) => state.app.config.endpoints.media
  );
  const styles = useSelector((state) => state.app.enums.styles);
  const appUser = useSelector((state) => state.auth.user);

  const currentAmbassadorStyles = ambassadorStyles?.map(
    (styleId) => findObjectById(styleId, styles)?.name
  );
  const bannerUrlPlaceholder = showcaseBanner.small;
  const userAvatarThumbUrl = userAvatar.thumb;

  const updateSlideLength = useCallback(() => {
    sliderNode.slickGoTo(0, true);
    setActiveSlideIndex(0);
    setSlideLength(featuredLookBoards.length);
  }, [featuredLookBoards, sliderNode]);

  useEffect(() => {
    if (
      featuredLookBoards.length &&
      sliderNode &&
      activeTab === getTheLookAmbassadorsTabKeys.main
    ) {
      updateSlideLength();
    }
    // eslint-disable-next-line
  }, [featuredLookBoards, sliderNode, activeTab]);

  const handleHover = useCallback(() => {
    if (activeTab !== getTheLookAmbassadorsTabKeys.showcase) {
      setHover(true);
    }
  }, [activeTab]);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      if (!appUser) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        return;
      }
      try {
        await dispatch(
          toggleLikeAmbassadorLookBoardAction(lookBoardId, likeStatus)
        );
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [appUser, history, dispatch]
  );

  const handleLookBoardVote = useCallback(
    async (lookBoardId, likeStatus) => {
      if (!appUser) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        return;
      }
      try {
        await dispatch(voteAmbassadorLookBoardAction(lookBoardId, likeStatus));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [appUser, history, dispatch]
  );

  const bannerUrl = useMemo(() => {
    const { media, type: bannerType } = banner || {};

    return bannerType === bannerTypeKeys.image && media
      ? buildResourceUrl(bannerUrlPlaceholder, media.userId, media.hash)
      : null;
  }, [bannerUrlPlaceholder, banner]);

  const avatarUrl = useMemo(() => {
    if (avatar) {
      const { userId: showcaseUserId, hash: avatarHash } = avatar;
      return buildResourceUrl(userAvatarThumbUrl, showcaseUserId, avatarHash);
    }
    return null;
  }, [avatar, userAvatarThumbUrl]);

  const handleViewShowcase = useCallback(async () => {
    if (matchesMediaQuery) {
      history.push(routesByName.showcase.details(urlKey));
    } else {
      window.open(routesByName.showcase.details(urlKey), '_blank');
    }
  }, [history, matchesMediaQuery, urlKey]);

  const handleViewAllLookBoards = useCallback(async () => {
    await dispatch(syncSelectedAmbassadorDataAction(id));
    dispatch(
      setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.lookBoards)
    );
  }, [dispatch, id]);

  const ambassadorCardProps = useMemo(
    () => ({
      badge,
      firstName,
      lastName,
      banner,
      bannerUrl,
      lookBoardsCount: lookBoardsCount || 0,
      title: pageName,
      styles: currentAmbassadorStyles,
      avatarUrl,
      id,
      userId,
      handleViewShowcase,
      handleViewAllLookBoards,
      urlKey,
      videoUrl,
      displayName,
      videoUrlIsActive,
    }),
    [
      badge,
      firstName,
      lastName,
      banner,
      bannerUrl,
      lookBoardsCount,
      pageName,
      currentAmbassadorStyles,
      avatarUrl,
      userId,
      id,
      handleViewShowcase,
      handleViewAllLookBoards,
      urlKey,
      videoUrl,
      displayName,
      videoUrlIsActive,
    ]
  );

  const handlePlayVideo = useCallback(
    (e) => {
      e.stopPropagation();
      handleSetVideoLink(videoUrl);
      // dispatch(toggleYoutubeVideoModalAction(true))
    },
    [handleSetVideoLink, videoUrl]
  );

  return (
    <div
      className={clsx('d-flex position-relative mb-1 pt-2 pb-5', {
        [classes.hoveredItem]: hover,
      })}
    >
      <div
        className={`${classes.leftPanelWrapper} justify-content-center`}
        onMouseEnter={handleHover}
        onMouseLeave={handleBlur}
      >
        <AmbassadorCard
          {...ambassadorCardProps}
          handlePlayVideo={handlePlayVideo}
          disableHover={matchesMediaQuery}
          hoverOverlay={
            <>
              <div
                className="h-100 flex-column flex-vertical-center justify-content-center align-items-center text-center"
                onClick={handleViewAllLookBoards}
              >
                <span className={classes.viewLookBoardsButton}>
                  View <br /> Ambassador&apos;s <br /> Look Boards
                </span>
                {videoUrl && videoUrlIsActive ? (
                  <div className={classes.videoIcon}>
                    <SpriteIcon
                      onClick={handlePlayVideo}
                      color="#87bdc3"
                      className="h-auto w-100 cursor-pointer"
                      name="play-btn-filled"
                    />
                  </div>
                ) : null}
              </div>
            </>
          }
        />
      </div>
      {!matchesMediaQuery && (
        <div className="flex-fill d-flex align-items-center">
          <div className={classes.mainContainer}>
            {featuredLookBoards && featuredLookBoards.length === 0 && (
              <NoLookBoardsModal />
            )}
            {featuredLookBoards.length > 0 && (
              <GTLSlider
                sliderRef={sliderRef}
                slideLength={slideLength}
                activeSlideIndex={activeSlideIndex}
                onSlidePrev={handleSlidePrev}
                onSlideNext={handleSlideNext}
                onSlideChange={handleSlideChange}
                onViewAll={handleViewAllLookBoards}
              >
                {featuredLookBoards.map(
                  (lookBoardId) =>
                    lookBoardsData[lookBoardId] && (
                      <div key={lookBoardId} className="px-1">
                        <LookBoardItem
                          lookBoard={lookBoardsData[lookBoardId]}
                          products={lookBoardsData[lookBoardId].products}
                          user={{
                            firstName,
                            lastName,
                            avatar,
                            ambassador,
                            id: userId,
                            displayName,
                          }}
                          onToggleLike={handleToggleLikeLookBoard}
                          onVote={handleLookBoardVote}
                          hideLikeVote
                        />
                      </div>
                    )
                )}
              </GTLSlider>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

AmbassadorListItem.propTypes = {
  ambassadorData: PropTypes.shape({
    user: PropTypes.shape({
      ambassador: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      badge: PropTypes.number,
      assignDate: PropTypes.string,
      displayName: PropTypes.string,
      lookBoards: PropTypes.number,
      styles: PropTypes.arrayOf(PropTypes.number),
      avatar: PropTypes.shape({
        userId: PropTypes.number,
        hash: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    userId: PropTypes.number,
    id: PropTypes.number,
    urlKey: PropTypes.string,
    lookBoards: PropTypes.arrayOf(PropTypes.number),
    pageName: PropTypes.string,
    banner: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      media: PropTypes.shape({
        id: PropTypes.number,
        userId: PropTypes.number,
        hash: PropTypes.string,
      }),
    }),
    featuredLookBoards: PropTypes.arrayOf(PropTypes.number),
    videoUrl: PropTypes.string,
    videoUrlIsActive: PropTypes.number,
  }).isRequired,
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  handleSetVideoLink: PropTypes.func.isRequired,
};

AmbassadorListItem.defaultProps = {
  lookBoards: null,
  products: {},
};

export default AmbassadorListItem;
