import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AccordionItem from 'modules/dashboard/ambassadorManagement/AmbassadorFaqs/AccordionItem';
import { useSectionRefs } from 'hooks/useSectionRefs';
import { routesByName } from 'constants/routes';
import ptlFaqImage from 'assets/img/faqs-page/ptl-ambass-faq-img.jpg';
import ptlAmbassFaqImage from 'assets/img/faqs-page/ptl-faqs-img.png';
import nominateAmbassador from 'assets/img/faqs-page/nominate-ambassador.jpg';
import ambassadorBenefits from 'assets/img/faqs-page/ambassador-benefits.jpg';
import ambassadorSocial from 'assets/img/faqs-page/faq-ambassador-social.jpg';
import livingRoomRequest from 'assets/img/faqs-page/living-room-request-image.jpg';
import ambassadorShowcaseCards from 'assets/img/faqs-page/AmbassadorShowcaseCards.jpg';
import ambassadorInnerCircle from 'assets/img/faqs-page/ambassador-inner-circle.jpg';
import faqVideos from 'assets/img/faqs-page/faq-videos.png';
import ambassadorsWhoAre from 'assets/img/faqs-page/ambassadors-who-are.jpg';
import faqSocial from 'assets/img/faqs-page/faq-social.png';
import faqJoinAffiliate from 'assets/img/faqs-page/faq-join-affiliate.jpg';
import faqBecome from 'assets/img/faqs-page/faq-become.png';
import faqPreferences from 'assets/img/faqs-page/faq-preferences.png';
import faqCommunity from 'assets/img/faqs-page/faq-community.png';
import faqPoints from 'assets/img/faqs-page/faq-points.png';
import joinAffiliatePrograms from 'assets/img/faqs-page/join-affiliate-programs.png';
import faqEarnPoints from 'assets/img/faqs-page/faq-earn-points.png';
import faqSocials from 'assets/img/faqs-page/faq-socials.png';
import sampleShowcaseImg from 'assets/img/sample-showcase-page.png';
import classes from './Faqs.module.scss';
import {
  ambassadorSectionTitles,
  faqAffiliateCommissions,
  faqAmbassadorBenefits,
  faqAmbassadorPoints,
  faqAttributes,
  faqBenefits,
  faqCreativity,
  faqEarnAffiliate,
  faqInitialActions,
  faqProcessShowcase,
  faqSupportList,
  standardFaqAttributes,
  standardSectionTitles,
} from './config';
import MetaTags from '../../../components/MetaTags';
import { metaNames } from '../../../components/MetaTags/config';

const Faqs = () => {
  const user = useSelector((state) => state.auth.user);
  const metaTags = useSelector((state) => state.app.enums.metaTags);

  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);
  const sectionTitles = useMemo(
    () => (isAmbassador ? ambassadorSectionTitles : standardSectionTitles),
    [isAmbassador]
  );
  const sectionRefs = useSectionRefs(Object.keys(sectionTitles));

  const scrollToSection = (id) => {
    const element = sectionRefs[id].current;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {metaTags && metaTags[metaNames.ambassadorFaq] && (
        <MetaTags metaData={metaTags[metaNames.ambassadorFaq]} />
      )}
      <section className={classes.root}>
        <div className={classes.topSection}>
          <div className="w-50">
            <h1 className={classes.topSection__title}>AMBASSADOR FAQs</h1>
            {isAmbassador ? (
              <p className={classes.topSection__description}>
                Pin the Look is committed to making it easy for shoppers to get
                the look they love for their home. As an Ambassadors, you are
                part of a community of decor enthusiasts who love helping others
                create beautiful spaces. We are thrilled to have you as part of
                our creative family!
              </p>
            ) : (
              <p className={classes.topSection__description}>
                Pin the Look is committed to making it easy for shoppers to get
                the look they love for their home. In addition to offering an
                array of pre-curated looks and DIY curation tools, we also match
                shoppers with Ambassadors, who help them get the look in a more
                personalized way. Our Ambassadors are decor enthusiasts with an
                eye for design who love creating beautiful spaces.
              </p>
            )}
            <ul className="mt-4">
              {Object.keys(sectionTitles).map((sectionId) => (
                <li
                  className={classes.sectionTitle}
                  onClick={() => scrollToSection(sectionId)}
                >
                  {sectionTitles[sectionId]}
                </li>
              ))}
            </ul>
          </div>
          <img
            src={isAmbassador ? ptlAmbassFaqImage : ptlFaqImage}
            alt="ptlAmbassFaqImage"
          />
        </div>
        {isAmbassador ? (
          <div className={classes.mainSection}>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.embraceYourAmbass}
            >
              <h5 className={classes.accordionSection__title}>
                EMBRACE YOUR AMBASSADORSHIP
              </h5>
              <AccordionItem question="What Does It Mean to Be a Pin the Look Ambassador?">
                <p className="mb-2">
                  As a Pin the Look Ambassador, you`re a key player in our
                  vibrant community of decor enthusiasts, dedicated to helping
                  others create their dream homes. Ambassadors are decor lovers
                  with a keen eye for design and a passion for helping others
                  create beautiful spaces.
                </p>
                <p className="mb-2">
                  Whether seasoned in interior design or skilled in personal
                  decoration, our Ambassadors see the potential in any room and
                  can transform spaces into stylish showcases. Being an
                  Ambassador provides that opportunity if you`ve always wanted a
                  platform where your creativity can flourish.
                </p>
                <p>
                  In this role, you`ll engage your love for decor by helping
                  others enhance their living spaces, combining your creative
                  passions with the potential to earn. Our platform allows you
                  to bring design inspirations to life and inspire others with
                  your unique aesthetic. As a Pin the Look Ambassador, you not
                  only make styling homes accessible and fun but also earn
                  rewards and recognition for your contributions.
                </p>
                <img src={ambassadorsWhoAre} alt="ambassadorsWhoAre" />
              </AccordionItem>
              <AccordionItem
                question="Can I nominate Pin the Look Ambassadors?"
                className={classes.nominateAmbassador}
              >
                <p className="mb-2">
                  At Pin the Look, we believe that individuals with a deep
                  passion and exceptional talent for home decor deserve a
                  platform to shine. Whether you`re a seasoned design
                  professional, an influential blogger with a knack for trends,
                  or simply a dedicated decor enthusiast with a desire to
                  express your creativity, you`re invited to join our esteemed
                  community of Ambassadors.
                </p>
                <p className="mb-2">
                  The invitation to become a Pin the Look Ambassador is extended
                  to those with a genuine love for home decor and a remarkable
                  flair for transforming spaces. If you`re ready to showcase
                  your unique style, share your expertise, and connect with
                  like-minded individuals, we encourage you to apply today.
                </p>
                <p className="mb-2">
                  In addition to applying, aspiring Ambassadors may also be
                  nominated by current members of our community. Recognizing
                  those whose passion and talent align with our values, these
                  nominations reflect the inclusive and supportive nature of our
                  Ambassador network.
                </p>
                <p className="mb-2">
                  Invite your friends with a design eye to join us in decorating
                  the world! Apply today and take the first step towards
                  becoming a Pin the Look Ambassador.
                </p>
                <a
                  className="text-center"
                  href={routesByName.nominateAmbassador}
                >
                  <img src={nominateAmbassador} alt="nominateAmbassador" />
                </a>
              </AccordionItem>
              <AccordionItem question="What attributes do we value in Pin the Look Ambassadors?">
                <ul>
                  {faqAttributes.map(({ title, text }) => (
                    <li>
                      <span className="fw-600">{title}</span> {text}
                    </li>
                  ))}
                </ul>
              </AccordionItem>
              <AccordionItem question="How does Pin the Look empower its ambassadors to pursue their creativity?">
                At Pin the Look, we`re dedicated to empowering our ambassadors
                to unleash their creative potential and thrive in the world of
                home decor. Here`s how we do it:
                <ul>
                  {faqCreativity.map((faqCreativityItem) => (
                    <li>
                      <span className="fw-600">{faqCreativityItem.title}</span>{' '}
                      {faqCreativityItem.text}
                    </li>
                  ))}
                </ul>
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.launchYourAmbassJourney}
            >
              <h5 className={classes.accordionSection__title}>
                LAUNCH YOUR JOURNEY
              </h5>
              <AccordionItem
                question="What are the initial actions upon becoming an approved ambassador for Pin the Look?"
                onlyOne
              >
                <ul>
                  {faqInitialActions.map((faqInitialItem) => (
                    <li>
                      {faqInitialItem.link ? (
                        <a className="fw-600" href={faqInitialItem.link}>
                          {faqInitialItem.title}
                        </a>
                      ) : (
                        <span className="fw-600">{faqInitialItem.title}</span>
                      )}{' '}
                      {faqInitialItem.text}
                    </li>
                  ))}
                </ul>
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.perksAndOpportunities}
            >
              <h5 className={classes.accordionSection__title}>
                PERKS AND OPPORTUNITIES
              </h5>
              <AccordionItem question="What advantages are available to Ambassadors?">
                <span className="fw-600 mb-2">
                  Ambassadors enjoy numerous benefits, including:
                </span>
                <ul>
                  {faqAmbassadorBenefits.map((faqAmbassadorBenefitsItem) => (
                    <li>
                      <span className="fw-600">
                        {faqAmbassadorBenefitsItem.title}{' '}
                      </span>
                      {faqAmbassadorBenefitsItem.text}
                    </li>
                  ))}
                </ul>

                <img src={ambassadorBenefits} alt="ambassadorBenefits" />
              </AccordionItem>
              <AccordionItem
                question="How Can I Increase My Visibility and Social Media Following as a Pin the Look Ambassador?"
                className={classes.nominateAmbassador}
              >
                Becoming an ambassador for Pin the Look presents a unique
                opportunity to elevate your visibility and expand your following
                on social media platforms. As an ambassador, you`ll have access
                to a supportive community of decor enthusiasts and
                professionals, providing ample opportunities for collaboration
                and networking. By sharing your curated look boards, design
                tips, and creative insights with this engaged audience, you can
                showcase your expertise and unique style, attracting new
                followers who share your passion for home decor. Additionally,
                Pin the Look often features ambassador content on its own social
                media channels, further amplifying your reach and exposure. With
                the support of the Pin the Look community, you can enhance your
                online presence and grow your following organically.
                <img src={ambassadorSocial} alt="ambassadorSocial" />
              </AccordionItem>
              <AccordionItem question="What opportunities are there for growth and recognition as an ambassador?">
                As ambassadors, you`re in for an exhilarating ride filled with
                opportunities for growth and recognition! Picture yourself
                featured on Pin the Look`s social media channels, basking in the
                spotlight and mingling with trendsetters and influencers at
                exclusive events. And that`s not all! You may even find yourself
                collaborating with esteemed brands and partners, making waves in
                the world of home decor. The sky`s the limit when you`re part of
                the Pin the Look ambassador family!
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.connectAndCreate}
            >
              <h5 className={classes.accordionSection__title}>
                CONNECT AND CREATE
              </h5>
              <AccordionItem question="How does the Ambassador Showcase Page work?">
                <p className="mb-2">
                  Your Ambassador Showcase Page is your personal haven, where
                  your creativity takes center stage. Here, you can craft a
                  curated collection of look boards and feature your favorite
                  inspiration images that reflect your unique style and
                  expertise in home decor. This personalized space is a platform
                  to showcase your decorating skills to the world.
                </p>
                <p className="mb-2">
                  Each Ambassador can customize their Showcase Page to align
                  with their aesthetic preferences and design sensibilities.
                  Whether you prefer a minimalist look or a maximalist approach,
                  your Showcase Page is where you can truly express yourself.
                </p>
                <p className="mb-2">
                  Sharing your Showcase Page is effortless with its unique URL,
                  allowing you to promote your work on social media and beyond.
                  It`s not just a place to display your talent—it`s your virtual
                  home in the Pin the Look community, where you can connect with
                  like-minded individuals and inspire others with your passion
                  for home decor.
                </p>
                <img src={sampleShowcaseImg} alt="sampleShowcaseImg" />
              </AccordionItem>
              <AccordionItem question="What's the process for setting up my Showcase page on Pin the Look?">
                <ul>
                  {faqProcessShowcase.map((faqProcessShowcaseItem) => (
                    <li>
                      {faqProcessShowcaseItem.link ? (
                        <a
                          className="fw-600"
                          href={faqProcessShowcaseItem.link}
                        >
                          {faqProcessShowcaseItem.title}
                        </a>
                      ) : (
                        <span className="fw-600">
                          {faqProcessShowcaseItem.title}
                        </span>
                      )}{' '}
                      {faqProcessShowcaseItem.text}
                    </li>
                  ))}
                </ul>
              </AccordionItem>
              <AccordionItem question="How do Ambassadors help others 'Get the Look'?">
                Ambassadors play a crucial role in making decor dreams a reality
                for our users. When users “Request the look” of a specific
                image, Ambassadors step in to curate personalized look boards
                tailored to their preferences. Leveraging our platform`s
                intuitive tools, Ambassadors meticulously select furniture and
                decor items sourced from the web or our extensive database to
                match the desired style. By sharing their expertise and
                creativity, Ambassadors empower users to transform their vision
                into tangible, inspiring spaces.
                <img src={livingRoomRequest} alt="livingRoomRequest" />
              </AccordionItem>
              <AccordionItem
                question="How does joining Pin the Look as an ambassador connect you with an online community of decor enthusiasts?"
                className={classes.howDoesJoinPin}
              >
                Joining Pin the Look as an ambassador offers you the opportunity
                to become part of an exclusive online community of décor
                enthusiasts. As an ambassador, you`ll gain access to our
                dedicated Facebook group, where you can engage in daily
                conversations, access and share tips and best practices, and
                connect with like-minded individuals passionate about home
                decor. This vibrant community serves as a valuable resource for
                ongoing inspiration, support, and networking opportunities,
                enhancing your journey as a Pin the Look ambassador.
                <img
                  src={ambassadorShowcaseCards}
                  alt="ambassadorShowcaseCards"
                />
              </AccordionItem>
              <AccordionItem
                question="As an Ambassador, will I have access to the Facebook group 'The Ambassador Inner Circle'?"
                className={classes.nominateAmbassador}
              >
                <p className="mb-2">
                  &quot;The Ambassador Inner Circle&quot; is an exclusive
                  Facebook group created specifically for Pin the Look
                  ambassadors. It serves as a vibrant community hub where
                  ambassadors can connect, collaborate, and exchange ideas. In
                  &quot;The Ambassador Inner Circle,&quot; ambassadors gain
                  access to valuable resources, participate in discussions, and
                  stay updated on the latest news and opportunities within the
                  Pin The Look community.
                </p>
                <p className="mb-2">
                  It`s the perfect space to network with like-minded
                  individuals, share inspiration, and grow together as
                  ambassadors. Join us in &quot;The Ambassador Inner
                  Circle&quot; and be part of a supportive and dynamic community
                  dedicated to all things home decor!
                </p>
                <img src={ambassadorInnerCircle} alt="ambassadorInnerCircle" />
              </AccordionItem>
              <AccordionItem question="Can Ambassadors collaborate with other designers or influencers?">
                Yes, our program encourages collaboration among Ambassadors. By
                connecting with other decor enthusiasts, Ambassadors can expand
                their reach and influence and exchange ideas and styles to
                enhance their own showcase pages.
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.supportAndGrowth}
            >
              <h5 className={classes.accordionSection__title}>
                SUPPORT AND GROWTH
              </h5>
              <AccordionItem
                question="What kind of support and training does Pin the Look provide to new Ambassadors?"
                className={classes.whatKindSupport}
              >
                We`ve got you covered every step of the way! As a new
                Ambassador, you`ll receive special training on how to maximize
                your success. By accessing our new Ambassadors onboarding videos
                and content, you will learn to:
                <ul>
                  {faqSupportList.map((faqSupportListItem) => (
                    <li>{faqSupportListItem}</li>
                  ))}
                </ul>
                <p>
                  But that`s just the beginning! Our commitment to your success
                  doesn`t stop there. Get ready for ongoing training webinars,
                  where you`ll sharpen your skills and stay ahead of the curve.
                </p>
                <div className="d-flex justify-content-between">
                  <a href={routesByName.dashboard.ambassadorVideos}>
                    <img src={faqVideos} alt="faqVideos" />
                  </a>
                  <a
                    href="https://www.facebook.com/groups/pinthelookinnercircle"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={faqCommunity} alt="faqCommunity" />
                  </a>
                  <a href={routesByName.dashboard.ambassadorPointsBadges}>
                    <img src={faqPoints} alt="faqPoints" />
                  </a>
                </div>
                <p>
                  Plus, in our dynamic Facebook group, you`ll find daily doses
                  of inspiration and growth strategies to fuel your journey. And
                  when you need a helping hand, our dedicated support staff are
                  always here to assist you. At Pin the Look, we`re invested in
                  your success, and we`ll do whatever it takes to see you
                  thrive!
                </p>
              </AccordionItem>
              <AccordionItem question="How can I share feedback or suggestions for improving the ambassador program?">
                Pin the Look values feedback from ambassadors and encourages
                them to share their thoughts and suggestions for improving the
                program. Ambassadors can reach out to the support team directly
                or participate in feedback surveys and discussions on the
                platform.
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.amplifyYourImpact}
            >
              <h5 className={classes.accordionSection__title}>
                AMPLIFY YOUR IMPACT
              </h5>
              <AccordionItem question="How can I participate in ambassador-exclusive events or initiatives?">
                To stay ahead of the curve, keep a close watch on announcements
                and updates from Pin the Look. Our ambassador-exclusive events
                and initiatives are broadcasted through various channels,
                including email newsletters, notifications on the platform, and
                our vibrant Facebook group community.
              </AccordionItem>
              <AccordionItem question="Can I tag Pin The Look on my social media for cross-promotion?">
                Absolutely! We encourage our ambassadors to showcase their
                creativity and passion for home decor on social media. Tagging
                Pin The Look in your posts allows us to appreciate your
                fantastic content and opens the door for potential
                cross-promotion opportunities. Plus, every time you tag us, you
                become eligible to earn extra points! The more you share, the
                more points you can accumulate, bringing you closer to exciting
                rewards. So go ahead and tag us in your posts—we can`t wait to
                see what you`ve been up to!
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.thriveWithAffiliates}
            >
              <h5 className={classes.accordionSection__title}>
                THRIVE WITH AFFILIATES
              </h5>
              <AccordionItem
                question="How can I join Pin The Look's Affiliate Programs?"
                className={classes.howCanIJoin}
              >
                Prepare to monetize your design flair with Pin The Look`s
                Affiliate Programs! Once approved as an Ambassador, expect an
                exclusive invitation to join our Affiliate program. By
                registering as an Affiliate on MyLookShops.com and integrating
                your Affiliate Link into your Pin the Look profile, you`ll
                unlock the opportunity to curate Look Boards with Affiliate
                products and start earning commissions on sales generated by
                your Look Boards!
                <a
                  className="text-center"
                  href={routesByName.dashboard.ambassadorPrograms}
                >
                  <img
                    src={joinAffiliatePrograms}
                    alt="joinAffiliatePrograms"
                  />
                </a>
              </AccordionItem>
              <AccordionItem
                question="How can I earn affiliate commissions as a Pin The Look Ambassador?"
                className={classes.nominateAmbassador}
              >
                Get ready to turn your passion into income by leveraging Pin The
                Look`s Affiliate Programs! Here`s how you can start earning
                commissions:
                <ul>
                  {faqAffiliateCommissions.map(
                    (faqAffiliateCommissionsItem) => (
                      <li>
                        <span className="fw-600">
                          {faqAffiliateCommissionsItem.title}
                        </span>{' '}
                        {faqAffiliateCommissionsItem.text}
                      </li>
                    )
                  )}
                </ul>
                <img src={faqSocials} alt="faqSocials" />
                Prepare to thrive and monetize your passion as a Pin The Look
                Affiliate!
              </AccordionItem>
              <AccordionItem question="How does the 'Request the Look' process work for Ambassadors?">
                As an Ambassador, you play a pivotal role in the `Request the
                Look` process. When a user submits a request, it is matched with
                Ambassadors who specialize in the style of the image. If you
                choose to curate the look for a request, you`ll embark on a
                search for the best-matching items, both online and from our
                extensive database. Utilizing our user-friendly drag-and-drop
                Curation tool, you`ll create Look Board ideas tailored to the
                user`s preferences. These curated boards are then sent directly
                to the user, providing inspiration and guidance for their decor
                projects.
                <img src={livingRoomRequest} alt="livingRoomRequest" />
              </AccordionItem>
              <AccordionItem
                question="How can an ambassador earn points with Pin the Look?"
                className={classes.howCanIJoin}
              >
                As a Pin the Look Ambassador, there are several ways to earn
                points and be recognized for your contributions to the platform:
                <ul>
                  {faqAmbassadorPoints.map((faqAmbassadorPointsItem) => (
                    <li>
                      <span className="fw-600">
                        {faqAmbassadorPointsItem.title}
                      </span>{' '}
                      {faqAmbassadorPointsItem.text}
                    </li>
                  ))}
                </ul>
                <a
                  className="text-center"
                  href={routesByName.dashboard.ambassadorPointsBadges}
                >
                  <img src={faqEarnPoints} alt="faqEarnPoints" />
                </a>
                Your activity on Pin the Look will be celebrated, and you can
                always view your accumulated points in the My Points section of
                your dashboard. These points can be redeemed for home furnishing
                gift cards, allowing you to enhance your decor projects while
                enjoying the rewards of your Ambassadorship.
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.elevateYourAmbass}
            >
              <h5 className={classes.accordionSection__title}>
                ELEVATE YOUR AMBASSADORSHIP
              </h5>
              <AccordionItem question="Are there Ongoing Minimum Requirements for Ambassadors?">
                Ambassadors are encouraged to dive headfirst into the Pin the
                Look community, unleashing their creativity by adding beautiful
                inspiration images and curated Look Boards. We empower
                ambassadors to forge their own path, creating at their own pace,
                and Ambassadors can opt-in to curate requests. At the same time,
                regular engagement and a commitment to excellence are the
                cornerstones of ambassadorship. Active Ambassadors will not only
                earn more points but will also be featured higher on our
                Ambassador listing page, get featured on our socials, and gain
                access to lucrative partnership opportunities.
              </AccordionItem>
              <AccordionItem question="Can Retailers Become Ambassadors?">
                Absolutely! For home furnishing manufacturers and artisans, Pin
                the Look offers a fresh channel to showcase unique items to home
                decor enthusiasts precisely when they`re ready to purchase.
                Partnership opportunities may involve featuring items in our
                extensive product database or having them promoted on social
                media by our active Ambassadors. To explore partnership
                opportunities further, simply reach out to us by using the
                &quot;Partner Inquiries&quot; subject line when contacting us
                through our Contact Us page. We look forward to discussing how
                we can collaborate to elevate your brand`s presence within the
                Pin the Look community!
              </AccordionItem>
            </div>
          </div>
        ) : (
          <div className={classes.mainSection}>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.ambassadorFeatures}
            >
              <h5 className={classes.accordionSection__title}>
                AMBASSADOR FEATURES & BENEFITS
              </h5>
              <AccordionItem question="What is a 'Pin the Look Ambassador'?">
                <p className="mb-2">
                  A Pin the Look Ambassador is a member of our dynamic online
                  community of decor enthusiasts dedicated to helping others
                  achieve the look they love for their homes. Ambassadors are
                  decor lovers with a keen eye for design and a passion for
                  helping others create beautiful spaces.
                </p>
                <p className="mb-2">
                  While some of our Ambassadors have interior design expertise,
                  most simply have years of experience decorating their homes,
                  providing design tips to friends, and shopping for hidden
                  jewels or great finds. We all know someone with an innate
                  sense of style and a knack for transforming any space into
                  something special. These individuals can walk into a room and
                  immediately see its potential, turning ordinary spaces into
                  beautifully styled areas. If you`ve ever thought that you or
                  someone you know just needed the right outlet to let your
                  creativity shine, then becoming a Pin the Look Ambassador
                  might be the perfect opportunity.
                </p>
                <p className="mb-2">
                  As an Ambassador, you`ll get to indulge your passion for
                  decorating while helping others beautify their living spaces.
                  This role is ideal for those looking to scratch their creative
                  itch and desire an outlet to express and monetize their
                  decorating skills. Our platform allows you to showcase your
                  ability to bring inspirational images to life through curated
                  look boards and offers the chance to inspire others with your
                  unique aesthetic.
                </p>
                <p>
                  Whether it’s turning an inspirational image into a beautifully
                  styled room or guiding others to find and place the perfect
                  pieces, Ambassadors play a crucial role in our community by
                  making home styling accessible and enjoyable. Plus, as a Pin
                  the Look Ambassador, you can earn rewards and recognition for
                  your contributions, effectively making money while doing what
                  you love.
                </p>
                <img src={faqBecome} alt="faqBecome" />
              </AccordionItem>
              <AccordionItem
                question="Who can become a Pin the Look Ambassador?"
                className={classes.whoCanBecome}
              >
                At Pin the Look, we believe individuals with a deep passion and
                exceptional talent for home decor deserve a platform to shine.
                Whether you`re a seasoned design professional, an influential
                blogger with a knack for trends, or simply a dedicated decor
                enthusiast with a desire to express your creativity, you`re
                invited to join our esteemed community of Ambassadors.
                <img
                  className="my-2"
                  src={faqPreferences}
                  alt="faqPreferences"
                />
                <p className="mb-2">
                  The invitation to become a Pin the Look Ambassador is extended
                  to those with a genuine love for home decor and a remarkable
                  flair for transforming spaces. If you`re ready to showcase
                  your unique style, share your expertise, and connect with
                  like-minded individuals, we encourage you to apply today!
                </p>
                <p className="mb-2">
                  In addition to applying, aspiring Ambassadors may be nominated
                  by current community members. Recognizing those whose passion
                  and talent align with our values, these nominations reflect
                  our Ambassador network`s inclusive and supportive nature.
                </p>
                <p>
                  Join us in decorating the world! Apply today and take the
                  first step towards becoming a Pin the Look Ambassador.
                </p>
              </AccordionItem>
              <AccordionItem question="What are the attributes we look for in Pin the Look Ambassadors?">
                <ul>
                  {standardFaqAttributes.map((standardFaqAttributesItem) => (
                    <li>
                      <span className="fw-600">
                        {standardFaqAttributesItem.title}
                      </span>{' '}
                      {standardFaqAttributesItem.text}
                    </li>
                  ))}
                </ul>
              </AccordionItem>
              <AccordionItem question="How long does it take to become an Ambassador after requesting an invitation?">
                We review all Ambassador requests within one week. Once
                approved, you`ll receive further information on how to get
                started, including access to our training resources.
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.ignitingJourney}
            >
              <h5 className={classes.accordionSection__title}>
                IGNITING YOUR AMBASSADOR JOURNEY
              </h5>
              <AccordionItem question="What is a 'Pin the Look Ambassador'?">
                Ambassadors enjoy numerous benefits, including:
                <ul>
                  {faqBenefits.map((faqBenefitsItem) => (
                    <li>
                      <span className="fw-600">{faqBenefitsItem.title}</span>{' '}
                      {faqBenefitsItem.text}
                    </li>
                  ))}
                </ul>
              </AccordionItem>
              <AccordionItem
                question="How do Ambassadors help others 'Get the Look'?"
                className={classes.howDoesJoinPin}
              >
                Ambassadors play a crucial role in making decor dreams a reality
                for our users. When users “Request the look” of a specific
                image, Ambassadors step in to curate personalized look boards
                tailored to their preferences. Leveraging our platform`s
                intuitive tools, Ambassadors meticulously select furniture and
                decor items sourced from the web or our extensive database to
                match the desired style. By sharing their expertise and
                creativity, Ambassadors empower users to transform their vision
                into inspiring spaces.
                <img src={livingRoomRequest} alt="livingRoomRequest" />
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.connectCreative}
            >
              <h5 className={classes.accordionSection__title}>
                CONNECT WITH A CREATIVE COMMUNITY
              </h5>
              <AccordionItem question="How can becoming an ambassador for Pin the Look enhance your visibility and following on social media?">
                Becoming an ambassador for Pin the Look presents a unique
                opportunity to elevate your visibility and expand your following
                on social media platforms. As an ambassador, you`ll have access
                to a supportive community of decor enthusiasts and
                professionals, providing ample opportunities for collaboration
                and networking. By sharing your curated look boards, design
                tips, and creative insights with this engaged audience, you can
                showcase your expertise and unique style, attracting new
                followers who share your passion for home decor. Additionally,
                Pin the Look often features ambassador content on its own social
                media channels, further amplifying your reach and exposure. With
                the support of the Pin the Look community, you can enhance your
                online presence and grow your following organically.
                <img src={faqSocial} alt="faqSocial" />
              </AccordionItem>
              <AccordionItem question="How does joining Pin the Look as an ambassador connect you with an online community of decor enthusiasts?">
                Joining Pin the Look as an ambassador offers you the opportunity
                to become part of an exclusive online community of décor
                enthusiasts. As an ambassador, you`ll gain access to our
                dedicated Facebook group, where you can engage in daily
                conversations, access and share tips and best practices, and
                connect with like-minded individuals passionate about home
                decor. This vibrant community serves as a valuable resource for
                ongoing inspiration, support, and networking opportunities,
                enhancing your journey as a Pin the Look ambassador.
              </AccordionItem>
            </div>
            <div
              className={classes.accordionSection}
              ref={sectionRefs.transformPassion}
            >
              <h5 className={classes.accordionSection__title}>
                TRANSFORM YOUR PASSION INTO PROFIT
              </h5>
              <AccordionItem question="How can I join Pin The Look's Affiliate Programs?">
                Prepare to monetize your design flair with Pin The Look`s
                Affiliate Programs! Once your Showcase on Pin the Look is
                activated, expect an exclusive invitation to join our Affiliate
                program. By registering as an Affiliate on MyLookShops.com and
                integrating your Affiliate Link into your Pin the Look profile,
                you`ll unlock the opportunity to curate Look Boards with
                Affiliate products and start earning commissions on sales
                generated by your Look Boards!
                <img src={faqJoinAffiliate} alt="faqJoinAffiliate" />
              </AccordionItem>
              <AccordionItem question="How can I earn affiliate commissions as a Pin The Look Ambassador?">
                Get ready to turn your passion into income by leveraging Pin The
                Look`s Affiliate Programs! Here`s how you can start earning
                commissions:
                <ul>
                  {faqEarnAffiliate.map((faqEarnAffiliateItem) => (
                    <li>
                      <span className="fw-600">
                        {faqEarnAffiliateItem.title}
                      </span>{' '}
                      {faqEarnAffiliateItem.text}
                    </li>
                  ))}
                </ul>
                Prepare to thrive and monetize your passion as a Pin The Look
                Affiliate!
              </AccordionItem>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Faqs;
