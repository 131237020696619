import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ItemThumbnail from 'components/lookBoardThumbnails/ItemThumbnail/ItemThumbnail';
import classes from 'components/lookBoardThumbnails/LookBoard/LookBoard.module.scss';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { publishStatusKeys } from '../../../modules/request/constants';

const columnToClass = {
  1: classes.single,
  2: classes.double,
  3: classes.triple,
};

const minRows = 2;

const LookBoard = ({
  className,
  title,
  color,
  columns,
  items,
  collapsed,
  interactive,
  withHover,
  onToggleCollapse,
  onEdit,
  isMainLookBoard,
}) => {
  const containerRef = useRef(null);
  const [imgHeight, setImgHeight] = useState('50%');
  const [containerHeight, setContainerHeight] = useState(null);
  const [itemList, setItemList] = useState([]);

  const rowsCount = useMemo(() => Math.ceil(items.length / columns), [
    columns,
    items,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      const refWidth = containerRef.current.clientWidth;
      const refHeight = columns > 2 ? Math.floor(refWidth * (2 / 3)) : refWidth; // aspect ratio = 3:2 or square
      const itemHeight = Math.round(refHeight / 2);

      setContainerHeight(refHeight);
      setImgHeight(itemHeight);
    }
  }, [columns]);

  useEffect(() => {
    const itemsLimit = columns * minRows;
    let list = [...items];

    if (collapsed) {
      list = items.slice(0, itemsLimit);
    }

    setItemList(list);
  }, [items, columns, collapsed]);

  return (
    <div className={clsx(classes.root, className)} ref={containerRef}>
      <div className={classes.title} style={{ backgroundColor: color }}>
        {title}
      </div>
      <div
        style={{ minHeight: containerHeight }}
        className={classes.imgContainer}
      >
        {itemList.map(
          ({ id, media, name, price, itemClassId, publish, url, slug }) => (
            <ItemThumbnail
              key={id}
              id={id}
              slug={slug}
              src={media}
              className={columnToClass[columns]}
              height={imgHeight}
              name={name}
              price={price}
              url={url}
              isPublished={publish === publishStatusKeys.published}
              itemClassId={itemClassId}
              withHover={withHover}
              hideInfo
              isMainLookBoard={isMainLookBoard}
            />
          )
        )}
      </div>
      {interactive && (
        <div className={classes.bottomControls}>
          <div className="d-flex">
            {rowsCount > minRows && (
              <IconButton
                variant="inverted-grey"
                color="secondary"
                size="sm"
                className="mr-1"
                onClick={onToggleCollapse}
              >
                <SpriteIcon name={collapsed ? 'plus' : 'minus'} size="sm" />
              </IconButton>
            )}
            {onEdit && (
              <IconButton
                variant="inverted-grey"
                color="secondary"
                size="sm"
                onClick={onEdit}
              >
                <SpriteIcon name="edit" size="sm" />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

LookBoard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      media: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        hash: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  columns: PropTypes.number,
  collapsed: PropTypes.bool,
  color: PropTypes.string,
  interactive: PropTypes.bool,
  withHover: PropTypes.bool,
  isMainLookBoard: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
  onEdit: PropTypes.func,
};

LookBoard.defaultProps = {
  className: '',
  title: '',
  collapsed: true,
  interactive: true,
  withHover: false,
  color: '#FBB17B',
  onToggleCollapse: null,
  onEdit: null,
  items: [],
  columns: 2,
  isMainLookBoard: false,
};

export default LookBoard;
