import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from 'modules/curateTheLook/createLookBoard/components/LookBoardExamplesModal/LookBoardExamplesModal.module.scss';

const LookBoardExamplesModal = ({
  open,
  onClose,
  title,
  lookBoards,
  loading,
  inspirationImgInfo,
}) => {
  const history = useHistory();
  const handleLookBoardClick = useCallback(
    ({ currentTarget }) => {
      const { id: lookBoardId, slug: lookBoardSlug } = currentTarget.dataset;
      history.push(routesByName.lookBoard.details(lookBoardId, lookBoardSlug));
    },
    [history]
  );

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="body"
      fullWidth
    >
      <h2 className="font-title text-uppercase mb-4">{title}</h2>
      <div className={`${classes.inner} d-flex flex-column`}>
        {lookBoards.length > 0 ? (
          <>
            <div className="row flex-fill">
              {lookBoards.map(
                ({ id, title: lookBoardTitle, color, products, slug }) => (
                  <div key={id} className="col-4 mb-2">
                    <LookBoardPreview
                      title={lookBoardTitle}
                      color={color}
                      products={products}
                      showLike={false}
                      hoverOverlay={
                        <div
                          className={classes.hoverOverlay}
                          data-id={id}
                          data-slug={slug}
                          onClick={handleLookBoardClick}
                        >
                          Click to view look board details
                        </div>
                      }
                    />
                  </div>
                )
              )}
            </div>
            {inspirationImgInfo?.id ? (
              <Button
                className="align-self-center"
                component={Link}
                to={routesByName.getTheLook.details(
                  inspirationImgInfo.id,
                  inspirationImgInfo.slug
                )}
                inline
              >
                View In Get The Look Section
              </Button>
            ) : (
              <Button
                className="align-self-start"
                component={Link}
                to={routesByName.getTheLook.index}
                variant="default"
                inline
              >
                <span className="mr-1">Next</span>{' '}
                <SpriteIcon name="right-arrow" size="sm" />
              </Button>
            )}
          </>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            {loading ? 'Loading...' : 'List is Empty'}
          </div>
        )}
      </div>
    </BasicModal>
  );
};

LookBoardExamplesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  inspirationImgInfo: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
  }),
};

LookBoardExamplesModal.defaultProps = {
  title: 'Look Board Examples',
  inspirationImgInfo: null,
};

export default LookBoardExamplesModal;
