import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import {
  toggleChooseMethodModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import { routesByName } from 'constants/routes';
import {
  productFilterModalDescriptions,
  productFilterModalTabConfig,
  productFilterModalTabKeys,
  productTypeOptions,
  productTypeOptionsRegular,
} from 'modules/curateTheLook/constants';
import {
  resetProductFilterValuesAction,
  updateProductFilterValuesAction,
} from 'modules/curateTheLook/store/actions';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';
import { signUpFormFields } from 'modules/auth/signUp/constants';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, Chip, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { extensionAddress } from 'modules/pinMedia/constants';
import findObjectById from '../../../../../utils/findObjectById';

// PTL-302 - we only include the U.S. and Canada (during PHASE 1)
const tempCountries = [
  {
    label: 'United States',
    value: 1,
  },
  {
    label: 'Canada',
    value: 2,
  },
];
const MAX_FILTER_COUNT = 2;

const ProductsSearchParams = ({
  currentType,
  currentSubType,
  onUpdateSearchParams,
}) => {
  const { styles, colorGroups, itemClasses } = useSelector(
    (state) => state.app.enums
  );

  const dispatch = useDispatch();
  const {
    productFilterValues: filterValues,
    productSearchParams: searchParams,
  } = useSelector((state) => state.curateTheLook);
  const currentUser = useSelector((state) => state.auth.user);
  const affiliateSites = useSelector((state) => state.userPage.affiliateSites);
  const history = useHistory();
  const { pathname } = useLocation();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [isUserCountry, setIsUserCountry] = useState(false);
  const [isAllCountries, setIsAllCountries] = useState(false);
  const [currentSpecificCountries, setCurrentSpecificCountries] = useState([]);
  const [maxFiltersCount, setMaxFiltersCount] = useState(MAX_FILTER_COUNT);

  const handleOpenPinProductModal = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    // customEvent.trigger(OPEN_PIN_PRODUCT_MODAL, {
    //   mediaType: mediaTypes.product,
    // });
    dispatch(
      toggleChooseMethodModalAction({ isVisible: true, type: 'product' })
    );
  }, [currentUser, dispatch, pathname, history]);

  const handleChangeProductType = useCallback(
    (type, subType) => {
      onUpdateSearchParams({
        type,
        affiliateSite: subType ? Number(subType) : null,
      });
    },
    [onUpdateSearchParams]
  );

  const handleOpenFiltersModal = useCallback(() => {
    setFiltersModalOpen(true);
  }, []);

  const handleCloseFiltersModal = useCallback(() => {
    setFiltersModalOpen(false);
  }, []);

  // reset search params if filter active
  const handleApplyFilters = useCallback(
    (filters) => {
      const userCustomFilters = { ...filters };
      userCustomFilters.imageId = null;
      dispatch(updateProductFilterValuesAction(userCustomFilters));
      onUpdateSearchParams({ search: '' });
    },
    [onUpdateSearchParams, dispatch]
  );

  const handleChangeShowProductsFromUserCountry = useCallback(
    ({ target: { checked } }) => {
      const countriesList = checked ? [currentUser?.countryId] : [];

      setIsUserCountry(!isUserCountry);
      setIsAllCountries(false);
      onUpdateSearchParams({ countries: countriesList });
    },
    [currentUser, isUserCountry, onUpdateSearchParams]
  );

  const handleChangeShowProductsFromAllCountries = useCallback(() => {
    setIsUserCountry(false);
    setIsAllCountries(!isAllCountries);
    let countriesList;

    if (!isAllCountries) {
      countriesList = currentSpecificCountries.map((item) => item.value);
    } else {
      countriesList = [];
    }

    onUpdateSearchParams({ countries: countriesList });
  }, [currentSpecificCountries, isAllCountries, onUpdateSearchParams]);

  const handleChangeCountries = useCallback(
    (e, newValue) => {
      setIsUserCountry(false);
      setCurrentSpecificCountries(newValue);

      const countriesList = newValue.map((item) => item.value);
      onUpdateSearchParams({ countries: countriesList });
    },
    [onUpdateSearchParams]
  );

  useEffect(() => {
    onUpdateSearchParams({ type: searchParams.type });
    // eslint-disable-next-line
  }, []);

  const preparedSubOptions = useMemo(() => {
    const mappedData = affiliateSites.map((site) => {
      return {
        value: site.id,
        label: site.name,
      };
    });
    return mappedData;
  }, [affiliateSites]);

  const combinedFiltersArr = useMemo(() => {
    setMaxFiltersCount(MAX_FILTER_COUNT);
    const {
      styles: filterStyles,
      colors,
      itemClasses: filterItems,
    } = filterValues;
    const filterArr = [];
    filterItems.forEach((value) => {
      filterArr.push({ value, source: filterTabKeys.itemClasses });
    });
    filterStyles.forEach((value) => {
      filterArr.push({ value, source: filterTabKeys.styles });
    });
    colors.forEach((value) => {
      if (colorGroups[value])
        filterArr.push({ value, source: filterTabKeys.colors });
    });
    return filterArr;
  }, [colorGroups, filterValues]);

  const handleToggleFiltersCount = () => {
    setMaxFiltersCount((prevState) =>
      prevState === MAX_FILTER_COUNT
        ? combinedFiltersArr.length
        : MAX_FILTER_COUNT
    );
  };

  const getFilterOption = (option) => {
    const { value, source } = option;
    switch (source) {
      case filterTabKeys.styles: {
        return findObjectById(value, styles)?.name;
      }
      case filterTabKeys.colors: {
        return colorGroups[value]?.name;
      }
      case filterTabKeys.itemClasses: {
        return findObjectById(value, itemClasses)?.name;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.filterPreferencesWrapper}>
        {currentUser && (
          <>
            {currentUser?.ambassador ? (
              <div className="position-relative d-flex align-items-center mb-1 mt-1">
                <IconButton onClick={handleOpenPinProductModal}>
                  <SpriteIcon name="pin" />
                </IconButton>
                <h3 className={classes.pinTitle}>Pin a New Product</h3>
                <a
                  href={extensionAddress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={clsx(
                      'd-flex align-items-center',
                      classes.pinBtn
                    )}
                  >
                    <IconButton size="sm" className={classes.pinBtnIcon}>
                      <SpriteIcon name="pin" size="sm" />
                    </IconButton>
                    <p className={classes.pinBtnText}>
                      Get the Chrome Extension
                    </p>
                  </div>
                </a>
              </div>
            ) : null}
            <div className="position-relative d-flex mb-1 mt-2">
              <FilterInput
                className="flex-fill"
                options={
                  currentUser?.ambassador
                    ? productTypeOptions
                    : productTypeOptionsRegular
                }
                subOptions={preparedSubOptions}
                currentValue={currentType}
                currentSubValue={currentSubType}
                onChange={handleChangeProductType}
              />
            </div>
            <div className="d-flex align-items-start mb-1">
              <div className="d-flex mb-1">
                <IconButton onClick={handleOpenFiltersModal}>
                  <SpriteIcon name="filters" size="md" />
                </IconButton>
              </div>
              <div className="d-flex flex-wrap">
                {combinedFiltersArr.slice(0, maxFiltersCount).map((style) => (
                  <div className={classes.filterOption} key={style.value}>
                    {getFilterOption(style)}
                  </div>
                ))}
                {combinedFiltersArr.length > MAX_FILTER_COUNT && (
                  <div
                    className={clsx(classes.filterOption, 'cursor-pointer')}
                    onClick={handleToggleFiltersCount}
                  >
                    {maxFiltersCount === combinedFiltersArr.length ? '-' : '+'}
                  </div>
                )}
              </div>
            </div>
            <div
              onClick={(e) => dispatch(resetProductFilterValuesAction(e))}
              className={classes.resetFilter}
            >
              <SpriteIcon name="trash" size="xs" />
              Clear all to view more products
            </div>
            <p className="font-weight-bold text-sm">
              Filter Products by Country Sold:
            </p>
            <FormControlLabel
              className="mt-1"
              label={<span className="text-xs">My country</span>}
              control={
                <Checkbox
                  type="checkbox"
                  checked={isUserCountry}
                  onChange={handleChangeShowProductsFromUserCountry}
                  name={signUpFormFields.showProducts}
                  className="py-0"
                />
              }
            />

            <FormControlLabel
              className="mt-1"
              label={<span className="text-xs">Specified Countries</span>}
              control={
                <Checkbox
                  type="checkbox"
                  checked={isAllCountries}
                  onChange={handleChangeShowProductsFromAllCountries}
                  name="all-countries"
                  className="py-0"
                />
              }
            />

            {isAllCountries && (
              <div className="mb-2 ml-4">
                <Autocomplete
                  className="pb-2"
                  multiple
                  id="countries-select"
                  getOptionSelected={(option, item) =>
                    option.value === item.value
                  }
                  disabled={isUserCountry || !isAllCountries}
                  getOptionLabel={(option) => option.label}
                  options={tempCountries}
                  value={currentSpecificCountries}
                  onChange={handleChangeCountries}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Specify Countries"
                      variant="standard"
                    />
                  )}
                />
              </div>
            )}
          </>
        )}
      </div>
      <FiltersModal
        open={filtersModalOpen}
        onClose={handleCloseFiltersModal}
        filterValues={filterValues}
        title="Product"
        disabledFilters={[
          filterTabKeys.vibes,
          filterTabKeys.subStyles,
          filterTabKeys.roomTypes,
          filterTabKeys.videos,
          filterTabKeys.metaTags,
        ]}
        descriptions={productFilterModalDescriptions}
        onReset={(e) => dispatch(resetProductFilterValuesAction(e))}
        onApply={handleApplyFilters}
        customTabKeys={productFilterModalTabKeys}
        customTabsConfig={productFilterModalTabConfig}
      />
    </>
  );
};

ProductsSearchParams.propTypes = {
  currentType: PropTypes.string.isRequired,
  currentSubType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdateSearchParams: PropTypes.func.isRequired,
};

ProductsSearchParams.defaultProps = {
  currentSubType: null,
};

export default ProductsSearchParams;
