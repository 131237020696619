import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import Button from 'components/ui/Button/Button';
import { subTypeImageOptions } from 'modules/requestTheLook/constants';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import imagesSearchParamsBlockClasses from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock.module.scss';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';
import {
  resetFilterValuesAction,
  updateFilterValuesAction,
} from 'modules/requestTheLook/store/actions';
import { createImageInstantlyForAction } from 'modules/inspirationImage/store/actions';
import featureNameKeys from 'constants/featureNames';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import ConfirmModal from 'components/modals/ConfirmModal';
import { routesByName } from 'constants/routes';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import findObjectById from 'utils/findObjectById';
import classes from '../../../curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock.module.scss';

const MAX_FILTER_COUNT = 2;
const ImagesSearchParamsBlock = ({
  currentSource,
  selectedImage,
  onUpdateSearchParams,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const searchParams = useSelector(
    (state) => state.requestTheLook.inspirationImageSearchParams
  );
  const filterValues = useSelector(
    (state) => state.requestTheLook.filterValues
  );
  const { styles, colorGroups, itemClasses } = useSelector(
    (state) => state.app.enums
  );

  const history = useHistory();
  const { pathname } = useLocation();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [subTypeImage, setSubTypeImage] = useState(imageSourceKeys.all);
  const [activeTypeTab, setActiveTypeTab] = useState(imageSourceKeys.all);
  const [maxFiltersCount, setMaxFiltersCount] = useState(MAX_FILTER_COUNT);

  const handleAddImageModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(createImageInstantlyForAction(featureNameKeys.request));
  }, [currentUser, dispatch, pathname, history]);

  const handleOpenFiltersModal = useCallback(() => {
    setFiltersModalOpen(true);
  }, []);

  const handleCloseFiltersModal = useCallback(() => {
    setFiltersModalOpen(false);
  }, []);

  const handleApplyFilters = useCallback(
    (filters) => {
      dispatch(updateFilterValuesAction(filters));
    },
    [dispatch]
  );

  useEffect(() => {
    if (history.location.search.includes('update=true')) {
      history.push(history.location.pathname);
      const parsedData = JSON.parse(localStorage.getItem('parsedData'));
      const type = parsedData ? parsedData.type : null;

      onUpdateSearchParams({ type });
      const updatedFilter = {};
      if (type === imageTypeKeys.single) {
        updatedFilter[filterTabKeys.roomTypes] = [];
      }
      dispatch(updateFilterValuesAction(updatedFilter));
    }
    // eslint-disable-next-line
  }, [history.location.search]);

  const handleOpenClearAllModal = useCallback(() => {
    setClearAllModalOpen(true);
  }, []);

  const handleClearAllFilters = useCallback(
    (confirm) => {
      if (confirm) {
        onUpdateSearchParams({ search: '' });
        dispatch(resetFilterValuesAction());
      }
      setClearAllModalOpen(false);
    },
    [dispatch, onUpdateSearchParams]
  );

  const handleOpenResetModal = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const handleResetFilters = useCallback(
    (confirm) => {
      if (confirm) {
        onUpdateSearchParams({ search: '' });
        dispatch(resetFilterValuesAction());
      }
      setResetModalOpen(false);
    },
    [onUpdateSearchParams, dispatch]
  );

  const handleChangeSubType = useCallback(
    (subType) => {
      setActiveTypeTab(imageSourceKeys.all);
      setSubTypeImage(subType);
      onUpdateSearchParams({ search: '', source: subType });
    },
    [onUpdateSearchParams]
  );

  const isTabActive = useCallback(
    (tab) => {
      return activeTypeTab === tab;
    },
    [activeTypeTab]
  );

  const handleChangeActiveTab = async (tab) => {
    setActiveTypeTab(tab);
    onUpdateSearchParams({ search: '', source: tab });
  };

  useEffect(() => {
    setActiveTypeTab(
      searchParams.source === imageSourceKeys.my
        ? imageSourceKeys.my
        : imageSourceKeys.all
    );
  }, [searchParams]);

  const combinedFiltersArr = useMemo(() => {
    setMaxFiltersCount(MAX_FILTER_COUNT);
    const { styles: filterStyles, colors } = filterValues;
    const filterArr = [];
    filterStyles.forEach((value) => {
      filterArr.push({ value, source: 'styles' });
    });
    colors.forEach((value) => {
      if (colorGroups[value]) filterArr.push({ value, source: 'colors' });
    });
    return filterArr;
  }, [colorGroups, filterValues]);

  const handleToggleFiltersCount = () => {
    setMaxFiltersCount((prevState) =>
      prevState === MAX_FILTER_COUNT
        ? combinedFiltersArr.length
        : MAX_FILTER_COUNT
    );
  };

  const getFilterOption = (option) => {
    const { value, source } = option;
    switch (source) {
      case filterTabKeys.styles: {
        return findObjectById(value, styles)?.name;
      }
      case filterTabKeys.colors: {
        return colorGroups[value]?.name;
      }
      case filterTabKeys.itemClasses: {
        return findObjectById(value, itemClasses)?.name;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <div className="position-relative d-flex mb-2">
        <div className={imagesSearchParamsBlockClasses.filtersBlock}>
          <h3 className="text-lg font-semi-bold mb-1">Request The Look</h3>
        </div>
      </div>
      <div
        className={clsx(
          createLookBoardClasses.stepTitle,
          createLookBoardClasses.addImage,
          'mb-1',
          'flex-column'
        )}
      >
        <div className={createLookBoardClasses.addImageInner}>
          {selectedImage && (
            <SpriteIcon
              name="checked"
              size="sm"
              className="mr-1 primary-color"
            />
          )}
          <span className="flex-fill mr-2">
            Step 1: Select inspiration image
          </span>
          {currentSource === imageSourceKeys.all && !selectedImage && (
            <Button
              className={imagesSearchParamsBlockClasses.pinBtn}
              size="xs"
              inline
              style={{ minWidth: 130 }}
              variant="outlined"
              onClick={handleAddImageModalOpen}
            >
              <div className="d-flex align-items-center">
                or Add New Image
                <div className={imagesSearchParamsBlockClasses.pinBtnIcon}>
                  <SpriteIcon name="pin" size="xs" />
                </div>
              </div>
            </Button>
          )}
        </div>
      </div>
      <Button
        className={imagesSearchParamsBlockClasses.pinImageBtn}
        size="xs"
        style={{ minWidth: 130 }}
        variant="outlined"
        onClick={handleAddImageModalOpen}
      >
        <div className="d-flex align-items-center justify-content-center">
          Pin a new Image
          <div className={imagesSearchParamsBlockClasses.pinBtnIcon}>
            <SpriteIcon name="pin" size="md" />
          </div>
        </div>
      </Button>
      <div className={imagesSearchParamsBlockClasses.myAndLikedImages}>
        <FilterInput
          className={clsx(imagesSearchParamsBlockClasses.sortTypeSelect, {
            [imagesSearchParamsBlockClasses.active]: isTabActive(
              imageSourceKeys.all
            ),
          })}
          options={subTypeImageOptions}
          currentValue={subTypeImage}
          onChange={handleChangeSubType}
        />
        <div
          className={clsx(
            'd-flex flex-column align-items-center',
            imagesSearchParamsBlockClasses.tabBtnWrapper
          )}
        >
          <button
            type="button"
            className={clsx(imagesSearchParamsBlockClasses.tabBtn, {
              [imagesSearchParamsBlockClasses.active]: isTabActive(
                imageSourceKeys.my
              ),
            })}
            data-tab={imageSourceKeys.my}
            onClick={() => handleChangeActiveTab(imageSourceKeys.my)}
          >
            My Added Images
          </button>
        </div>
      </div>
      {!selectedImage && (
        <>
          <div className="text-xs font-italic py-1 mb-1">
            Use filters for Best Results
          </div>
          {/*
          <CustomRadioGroup
            controls={imageSearchRadioOptions}
            value={currentImgType}
            onChange={handleChangeImgType}
          />
          */}
          {/* {!selectedImage && (
            <div className="d-flex mb-2">
              <div className="flex-fill">
                <SearchInput
                  inputRef={inputRef}
                  value={searchQuery}
                  placeholder="Search by keywords"
                  onChange={handleChangeSearchQuery}
                  onCrossClick={handleCrossClick}
                />
              </div>

              <IconButton
                variant="inverted-white"
                onClick={handleOpenFiltersModal}
                className="ml-1"
              >
                <SpriteIcon name="filters" size="sm" />
              </IconButton>
            </div>
          )} */}
        </>
      )}
      <div className={imagesSearchParamsBlockClasses.resetArea}>
        <Button
          inline
          variant="outlined"
          onClick={handleOpenFiltersModal}
          className="ml-1"
          size="sm"
        >
          <SpriteIcon name="filters" size="sm" className="mr-1" />
          <span>Filters</span>
        </Button>
        <Button
          inline
          variant="default"
          size="xs"
          className="d-flex align-items-center"
          onClick={handleOpenResetModal}
        >
          <SpriteIcon name="processing" size="sm" className="mr-1" />
          <span>Reset</span>
        </Button>
        <div className={imagesSearchParamsBlockClasses.decorLine} />
        <Button
          variant="default"
          size="custom"
          onClick={handleOpenClearAllModal}
        >
          <div className={imagesSearchParamsBlockClasses.spriteBg}>
            <SpriteIcon name="trash" size="sm" className="mr-1" />
          </div>
          <span>Clear All</span>
        </Button>
      </div>
      <div className="d-flex flex-wrap">
        {combinedFiltersArr.slice(0, maxFiltersCount).map((filterItem) => (
          <div className={classes.filterOption} key={filterItem.value}>
            {getFilterOption(filterItem)}
          </div>
        ))}
        {combinedFiltersArr.length > MAX_FILTER_COUNT && (
          <div
            className={classes.filterOption}
            onClick={handleToggleFiltersCount}
          >
            {maxFiltersCount === combinedFiltersArr.length ? '-' : '+'}
          </div>
        )}
      </div>
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetFilters}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> filters to default
            {currentUser?.preferences && (
              <span>
                <br />
                preferences
              </span>
            )}
            ?
          </>
        }
      />
      <ConfirmModal
        onClose={handleClearAllFilters}
        open={clearAllModalOpen}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">clear</span> all filters?
          </>
        }
      />
      <FiltersModal
        showQuickFilter
        disabledFilters={[
          filterTabKeys.vibes,
          filterTabKeys.itemClasses,
          filterTabKeys.videos,
          filterTabKeys.metaTags,
        ]}
        open={filtersModalOpen}
        onClose={handleCloseFiltersModal}
        imageType={searchParams.type}
        filterValues={filterValues}
        onReset={() => dispatch(resetFilterValuesAction())}
        onApply={handleApplyFilters}
      />
    </>
  );
};

ImagesSearchParamsBlock.propTypes = {
  currentSource: PropTypes.string.isRequired,
  selectedImage: PropTypes.number,
  onUpdateSearchParams: PropTypes.func.isRequired,
};

ImagesSearchParamsBlock.defaultProps = {
  selectedImage: null,
};

export default ImagesSearchParamsBlock;
