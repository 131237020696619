import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import mediaTypes from 'constants/mediaTypes';
import mediaService from 'modules/media/mediaService';
import errorToastr from 'libs/toastr/errorToastr';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import ColorPickerPopover from 'modules/dashboard/showcase/components/ColorPickerPopover/ColorPickerPopover';
import BannerColor from 'modules/dashboard/showcase/components/BannerColor';
import BannerImage from 'modules/dashboard/showcase/components/BannerImage';
import {
  addOwnColorBannerAction,
  addOwnImageBannerAction,
} from 'modules/showcase/store/actions';
import classes from './BannerModal.module.scss';
import InfoIconPopover from '../../../../../components/ui/InfoIconPopover/InfoIconPopover';

const BannerModal = ({
  open,
  onClose,
  currentValue: { type, color, media },
  onApply,
}) => {
  const dispatch = useDispatch();

  const showcaseBannerImages = Object.values(
    useSelector((state) => state.app.enums.showcaseBannerImages)
  );
  const showcaseBannerColors = Object.values(
    useSelector((state) => state.app.enums.showcaseBannerColors)
  );
  const { ownColorBanners, ownImageBanners } = useSelector(
    (state) => state.showcase
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [colorBanners, setColorBanners] = useState(showcaseBannerColors);
  const [imageBanners, setImageBanners] = useState(showcaseBannerImages);
  const [currentBanner, setCurrentBanner] = useState({
    type,
    color,
    mediaId: media?.id,
  });

  useEffect(() => {
    if (!open) {
      setColorBanners(showcaseBannerColors);
      setImageBanners(showcaseBannerImages);
      setCurrentBanner({
        type,
        color,
        mediaId: media?.id,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const handleColorPickerOpen = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeColor = useCallback((value) => {
    setCurrentBanner({
      type: bannerTypeKeys.color,
      color: value,
      mediaId: undefined,
    });
  }, []);

  const handleAddColor = useCallback(
    (value) => {
      const newColor = {
        id: uuidv4(),
        color: value,
      };

      dispatch(addOwnColorBannerAction(newColor));
      handleChangeColor(value);
      setAnchorEl(null);
    },
    [dispatch, handleChangeColor]
  );

  const handleChangeImage = useCallback((mediaId) => {
    setCurrentBanner({
      type: bannerTypeKeys.image,
      color: undefined,
      mediaId,
    });
  }, []);

  const handleAddFile = useCallback(
    async ({ target: { files } }) => {
      const newImage = files[0];
      if (!files.length) return;
      if (files[0].type.startsWith('image')) {
        const imageSize = files[0].size / (1024 * 1024);
        if (imageSize > 1.5) {
          errorToastr('Error', 'Max image size is 1.5MB');
          return;
        }
      }
      if (newImage) {
        try {
          const formData = new FormData();

          formData.append('type', mediaTypes.showcaseBanner);
          formData.append('file', newImage);
          const { result } = await mediaService.uploadMedia(formData);
          dispatch(addOwnImageBannerAction(result));
          handleChangeImage(result.id);
        } catch (e) {
          errorToastr('Error', e.message);
        }
      }
    },
    [dispatch, handleChangeImage]
  );

  const handleSaveBanner = useCallback(() => {
    onApply(currentBanner);
  }, [onApply, currentBanner]);

  return (
    <>
      <BasicModal
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        scroll="body"
      >
        <h3 className="font-title text-lg text-center text-uppercase mb-3">
          Choose Banner Design or Upload Your Image
        </h3>
        <div className="row">
          <div className="col-6">
            <p className="font-title text-lg text-center text-uppercase mb-1">
              Color Banners
            </p>
            <div className="flex-center">
              <p className="text-sm font-italic text-capitalize mr-1">
                Or Select a Custom Color
              </p>
              <IconButton size="sm" onClick={handleColorPickerOpen}>
                <SpriteIcon name="palette" size="sm" />
              </IconButton>
            </div>
          </div>
          <div className="col-6">
            <p className="font-title text-lg text-center text-uppercase mb-1">
              Graphical Banners
            </p>
            <div className="flex-center mb-1">
              <p className="text-sm font-italic text-capitalize mr-1">
                Or Upload a Banner
              </p>
              <label>
                <input
                  type="file"
                  accept="image/*"
                  className="d-none"
                  onChange={handleAddFile}
                />
                <div className={classes.imageBannerFileUploader}>
                  <SpriteIcon name="image-upload" size="sm" />
                </div>
              </label>
            </div>
            <div className="flex-center mb-1">
              <InfoIconPopover
                iconProps={{
                  className: 'ml-1',
                  variant: 'inverted-grey',
                  size: 'xs',
                  color: 'primary',
                }}
                fullWidth
              >
                <p className="text-xs">
                  You can select one of our banner images below or upload your
                  own banner image. Baner images should be cropped to 1200
                  pixels wide x 350 pixels high, and no larger than 1.5 MB.
                </p>
              </InfoIconPopover>
              <span className="text-sm font-italic">
                Image Size: 1200 x 350 px (1.5 MB Max)
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            {ownColorBanners.map(({ id, color: bannerColor }) => (
              <BannerColor
                key={id}
                color={bannerColor}
                active={
                  currentBanner.type === bannerTypeKeys.color &&
                  currentBanner.color === bannerColor
                }
                onChange={handleChangeColor}
              />
            ))}
            {colorBanners.map(({ id, color: bannerColor }) => (
              <BannerColor
                key={id}
                color={bannerColor}
                active={
                  currentBanner.type === bannerTypeKeys.color &&
                  currentBanner.color === bannerColor
                }
                onChange={handleChangeColor}
              />
            ))}
          </div>
          <div className="col-6">
            {ownImageBanners.map(({ id, userId, hash }) => (
              <BannerImage
                key={id}
                active={
                  currentBanner.type === bannerTypeKeys.image &&
                  currentBanner.mediaId === id
                }
                mediaId={id}
                userId={userId}
                hash={hash}
                onChange={handleChangeImage}
              />
            ))}
            {imageBanners.map(({ mediaId, userId, hash }) => (
              <BannerImage
                key={mediaId}
                active={
                  currentBanner.type === bannerTypeKeys.image &&
                  currentBanner.mediaId === mediaId
                }
                mediaId={mediaId}
                userId={userId}
                hash={hash}
                onChange={handleChangeImage}
              />
            ))}
          </div>
        </div>
        <div className="text-center">
          <Button inline size="sm" onClick={handleSaveBanner}>
            Save
          </Button>
        </div>
      </BasicModal>
      <ColorPickerPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onApply={handleAddColor}
      />
    </>
  );
};

BannerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    color: PropTypes.string,
    media: PropTypes.shape({id:PropTypes.string}),
  }).isRequired,
  onApply: PropTypes.func.isRequired,
};

export default BannerModal;
