import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import myImagesClasses from 'modules/dashboard/myImages/MyImages.module.scss';
import { myProductsSortOptions } from 'modules/dashboard/constants';
import { productSortKeys } from 'constants/productSearchParams';
import mixAndMatchService from 'modules/mixAndMatch/mixAndMatchService';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import errorToastr from 'libs/toastr/errorToastr';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import MixItem from 'modules/dashboard/myMixAndMatches/MixItem/MixItem';
import productService from 'modules/product/productService';
import transformArrayToMap from 'utils/transformArrayToMap';
import { updateProductsLibraryAction } from 'modules/product/store/actions';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import { changeActiveTabAction } from 'modules/getTheLook/store/actions';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import { routesByName } from 'constants/routes';
import ConfirmModal from 'components/modals/ConfirmModal';
import useCancelToken from 'hooks/useCancelToken';

// TODO: Get limit value from application config
const limit = 10;

const initialSearchParams = {
  sort: productSortKeys.newest,
  offset: 0,
};

const MixAndMatchContainer = () => {
  const productsLibrary = useSelector((state) => state.product.library);
  const dispatch = useDispatch();

  const history = useHistory();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [mixList, setMixList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedMix, setSelectedMix] = useState(null);
  const [mixToDelete, setMixToDelete] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createCancelToken, isCancelled] = useCancelToken();

  const syncProductsLibrary = useCallback(
    async (list) => {
      const allProductIds = list?.reduce((accum, currVal) => {
        return [...accum, ...currVal.products];
      }, []);
      const needToRetrieveProductIds = Array.from(
        new Set(allProductIds)
      ).filter((productId) => !productsLibrary[productId]);

      if (needToRetrieveProductIds.length > 0) {
        const productList = await productService.getProductsByIds(
          needToRetrieveProductIds
        );
        const productsMap = transformArrayToMap(productList);
        dispatch(updateProductsLibraryAction(productsMap));
      }
    },
    [productsLibrary, dispatch]
  );

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();
      const list = await mixAndMatchService.getList(searchParams, {
        cancelToken,
      });

      await syncProductsLibrary(list);

      setMixList(list);
      if (list.length > 0) {
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + list.length,
        }));
      }
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [syncProductsLibrary, searchParams, isCancelled, createCancelToken]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const list = await mixAndMatchService.getList(searchParams, {
        cancelToken,
      });

      await syncProductsLibrary(list);

      setMixList((prevState) => [...prevState, ...list]);

      if (list.length > 0) {
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + list.length,
        }));
      }
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [syncProductsLibrary, searchParams, createCancelToken, isCancelled]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleChangeSort = useCallback((sort) => {
    setSearchParams((prevState) => ({ ...prevState, sort, offset: 0 }));
  }, []);

  const handleOpenPreviewModal = useCallback(
    ({ currentTarget }) => {
      const { id: mixId } = currentTarget.dataset;
      const currentMix = mixList.find(
        ({ id }) => id === Number.parseInt(mixId, 10)
      );
      setSelectedMix(currentMix);
      setPreviewModalOpen(true);
    },
    [mixList]
  );

  const handlePreviewModalClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  const handleEditMix = useCallback(
    (inspirationImageId, slug) => {
      dispatch(changeActiveTabAction(getTheLookTabKeys.mixAndMatch));
      history.push(routesByName.getTheLook.details(inspirationImageId, slug));
    },
    [dispatch, history]
  );

  const handleConfirmDeleteMix = useCallback((mixId) => {
    setMixToDelete(mixId);
    setDeleteModalOpen(true);
  }, []);

  const handleDeleteMix = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          await mixAndMatchService.removeMix(mixToDelete);
          const updatedList = mixList.filter(
            ({ id: itemId }) => itemId !== mixToDelete
          );
          setMixList(updatedList);
        } catch (e) {
          errorToastr('Error', e.message);
        }
      }
      setDeleteModalOpen(false);
    },
    [mixList, mixToDelete]
  );

  return (
    <>
      <div className={myImagesClasses.section}>
        <div className="d-flex justify-content-end mb-3">
          <FilterInput
            additionalLabel="Sort by:"
            className={myImagesClasses.sortTypeSelect}
            options={myProductsSortOptions}
            currentValue={searchParams.sort}
            onChange={handleChangeSort}
          />
        </div>
        {mixList.length > 0 ? (
          <CustomInfiniteScroll
            loadMore={loadMore}
            isLoading={loading}
            hasMore={hasMore}
            initialLoad={false}
          >
            <div className="row">
              {mixList.map((mix) => (
                <div key={mix.id} className="col-3 mb-2">
                  <MixItem
                    item={{ ...mix, isActive: !!mix.isActive }}
                    onOpenPreview={handleOpenPreviewModal}
                    onEditMix={handleEditMix}
                    onDeleteMix={handleConfirmDeleteMix}
                  />
                </div>
              ))}
            </div>
          </CustomInfiniteScroll>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            List is Empty
          </div>
        )}
      </div>
      <BasicModal
        open={previewModalOpen}
        onClose={handlePreviewModalClose}
        fullWidth
        maxWidth="md"
        scroll="body"
        classes={{ root: 'p-0' }}
      >
        <MixItem expanded item={selectedMix} />
      </BasicModal>
      <ConfirmModal
        open={deleteModalOpen}
        onClose={handleDeleteMix}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">remove</span> Mix & Match?
          </>
        }
      />
    </>
  );
};

export default MixAndMatchContainer;
