import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AmbassadorCard from 'components/userThumbnails/AmbassadorCard/AmbassadorCard';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import buildResourceUrl from 'utils/buildResourceUrl';
import findObjectById from 'utils/findObjectById';
import ShowcaseDetails from 'modules/showcase/ShowcaseDetails';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import showcaseClasses from '../../dashboard/showcase/Showcase.module.scss';

const AmbassadorShowcaseView = () => {
  const { pathname } = useLocation();

  const {
    user,
    urlKey,
    userId,
    pageName,
    banner,
    youtubeLink,
    featuredLookBoards,
  } = useSelector((state) => state.ambassadorsPage.selectedAmbassador);
  const { showcaseBanner, userAvatar } = useSelector(
    (state) => state.app.config.endpoints.media
  );

  const styles = useSelector((state) => state.app.enums.styles);
  const { activeTab } = useSelector((state) => state.ambassadorsPage);
  const currentAmbassadorStyles = user.styles?.map(
    (styleId) => findObjectById(styleId, styles)?.name
  );
  const bannerUrlPlaceholder = showcaseBanner.small;
  const userAvatarThumbUrl = userAvatar.thumb;
  const { firstName, lastName, assignAmbassadorAt, badge, displayName } = user;

  const isAmbassadorShowcasePage = useMemo(() => {
    const id = pathname.split('/').pop();
    return routesByName.getTheLook.ambassadors.details(id) === pathname;
  }, [pathname]);

  const bannerUrl = useMemo(() => {
    if (user) {
      const { media, type: bannerType } = banner || {};

      return bannerType === bannerTypeKeys.image && media
        ? buildResourceUrl(bannerUrlPlaceholder, media.userId, media.hash)
        : null;
    }
    return null;
  }, [banner, bannerUrlPlaceholder, user]);

  const avatarUrl = useMemo(() => {
    if (user.avatar) {
      const { userId: showcaseUserId, hash: avatarHash } = user.avatar;
      return buildResourceUrl(userAvatarThumbUrl, showcaseUserId, avatarHash);
    }
    return null;
  }, [user, userAvatarThumbUrl]);

  const ambassadorCardProps = useMemo(
    () => ({
      badge,
      firstName,
      lastName,
      assignAmbassadorAt,
      banner,
      bannerUrl,
      lookBoardsCount: user.lookBoards || 0,
      title: pageName,
      styles: currentAmbassadorStyles,
      avatarUrl,
      userId,
      urlKey,
      curatedLookBoards: featuredLookBoards.length || 0,
      onShowcasePage: activeTab === 'showcase',
      displayName,
    }),
    [
      badge,
      firstName,
      lastName,
      assignAmbassadorAt,
      banner,
      bannerUrl,
      user.lookBoards,
      pageName,
      currentAmbassadorStyles,
      avatarUrl,
      userId,
      urlKey,
      featuredLookBoards.length,
      activeTab,
      displayName,
    ]
  );

  return (
    <>
      <div className="flex-fill d-flex">
        <div
          className={clsx(`${classes.leftPanelWrapper} pb-3`)}
          style={{ marginTop: isAmbassadorShowcasePage ? '' : '-110px' }}
        >
          <div className="flex-fill d-flex flex-column">
            <AmbassadorCard {...ambassadorCardProps} showMoreInfo />
            {youtubeLink && (
              <div className="mt-2">
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  src={youtubeLink}
                  preload="metadata"
                  // onPause={handlePause}
                  controls
                  className={clsx(
                    showcaseClasses.video,
                    showcaseClasses.getTheLookVideo
                  )}
                  // poster={posterImg}
                  autoPlay
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex-fill">
          <div className={classes.mainContainer}>
            <ShowcaseDetails
              onGetTheLookSection
              ambassadorUrlKey={urlKey}
              userId={userId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AmbassadorShowcaseView;
