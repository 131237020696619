import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import { getStyleImgUrl, getSubStyleImgUrl } from 'modules/app/store/selectors';
import Button from 'components/ui/Button/Button';
import transformArrayToMap from 'utils/transformArrayToMap';
import subStylesDescriptionsArray from 'constants/subStylesDescriptions';
import classes from 'modules/account/profileAndPreferences/preferences/Preferences.module.scss';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import clsx from 'clsx';
import { saveStyleQuizAsPreferencesAction } from 'modules/currentUser/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { maxWidthMd } from 'constants/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import findObjectById from '../../../utils/findObjectById';

const SummaryPage = ({ currentValues, onApply }) => {
  const dispatch = useDispatch();
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const { styles, subStyles, roomTypes, colors, vibes } = useSelector(
    (state) => state.app.enums
  );
  const config = useSelector((state) => state.app.config);
  const styleQuiz = useSelector((state) => state.layout.styleQuizResult);

  const styleImgUrl = useMemo(() => getStyleImgUrl(config), [config]);
  const subStyleImgUrl = useMemo(() => getSubStyleImgUrl(config), [config]);
  const subStylesDescriptions = useMemo(
    () => transformArrayToMap(subStylesDescriptionsArray),
    []
  );

  const { pathname } = useLocation();
  const history = useHistory();

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const [saved, setSaved] = useState(false);
  const handleSaveQuizPreference = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`
      );
      return;
    }
    try {
      await dispatch(saveStyleQuizAsPreferencesAction(styleQuiz.data));
      setSaved(true);
      successToastr('Success', 'Your preferences successfully updated');
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [authenticated, history, pathname, dispatch, styleQuiz.data]);

  const colorOption = useMemo(() => {
    return Object.values(colors)
      .filter((color) => currentValues.colors?.includes(color.color_group_id))
      .map((color) => color.id);
  }, [colors, currentValues]);

  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById('title');
      // eslint-disable-next-line no-unused-expressions
      elem?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 100);
  }, []);

  return (
    <>
      {currentValues.vibes?.length > 0 && (
        <div className="mb-4">
          <p className={classes.preferenceTitle}>Your Style Vibe</p>
          <div className="d-flex flex-wrap">
            {currentValues.vibes.map((id) => (
              <div className={classes.vibeCard} key={id}>
                {vibes[id].publicName}
              </div>
            ))}
          </div>
        </div>
      )}
      {currentValues.styles && currentValues.styles.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Main Styles</p>
          <div className="d-flex flex-wrap">
            {currentValues.styles.map((id) => {
              const style = findObjectById(id, styles);
              return (
                <div className={classes.styleCard} key={id}>
                  <div className={classes.stylesImgContainer}>
                    <img
                      src={styleImgUrl.replace(
                        urlPlaceholders.hash,
                        style?.media.hash
                      )}
                      alt={style?.name || ' Name'}
                    />
                  </div>
                  <p className="text-ellipsis text-nowrap text-uppercase font-title text-sm p-2">
                    {style?.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {currentValues.subStyles && currentValues.subStyles.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Your Style Flavors</p>
          <div className="d-flex flex-wrap">
            {currentValues.subStyles.map((id) => {
              return (
                <div className={classes.styleCard} key={id}>
                  <div className={classes.stylesImgContainer}>
                    <img
                      src={subStyleImgUrl.replace(
                        urlPlaceholders.hash,
                        findObjectById(id, subStyles).media.hash
                      )}
                      alt={findObjectById(id, subStyles).name}
                    />
                  </div>
                  <div className={classes.subStylesAbout}>
                    <div className="font-semi-bold">
                      {subStylesDescriptions[id]?.name}
                    </div>
                    <div className={classes.subStylesDesc}>
                      {subStylesDescriptions[id]?.description}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {currentValues.roomTypes && currentValues.roomTypes.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Room Types</p>
          <div className="d-flex flex-wrap">
            {currentValues.roomTypes.map((id) => (
              <div
                className="d-flex flex-column align-items-center mr-5 mb-2"
                key={id}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: roomTypes[id].icon }}
                  className={classes.roomTypeIcon}
                />
                <p className="text-uppercase font-title text-sm">
                  {roomTypes[id].name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {currentValues.colors && currentValues.colors.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Favorite Colors</p>
          <div className="d-flex flex-wrap">
            {colorOption.map((id) => (
              <ColorPoint
                key={id}
                id={id}
                size={matchesMediaQuery ? 'md' : 'xl'}
                title={colors[id].name}
                selected
                className="mr-4 mb-3"
                color={colors[id].code}
              />
            ))}
          </div>
        </div>
      )}
      <div className="d-flex flex-column justify-content-center align-items-center mt-4">
        <Button
          size="sm"
          onClick={handleSaveQuizPreference}
          variant={saved ? 'contained' : 'outlined'}
          className={clsx('mb-2', classes.saveBtn, {
            'w-100': matchesMediaQuery,
          })}
        >
          Save as Preferences
        </Button>
        <Button
          size="sm"
          onClick={onApply}
          className={clsx(classes.resultsBtn, {
            'w-100': matchesMediaQuery,
          })}
        >
          View Results
        </Button>
      </div>
    </>
  );
};

SummaryPage.propTypes = {
  currentValues: PropTypes.shape({
    vibes: PropTypes.arrayOf(PropTypes.number),
    styles: PropTypes.arrayOf(PropTypes.number),
    subStyles: PropTypes.arrayOf(PropTypes.number),
    roomTypes: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  onApply: PropTypes.func.isRequired,
};

export default SummaryPage;
