import React, { useCallback, useMemo, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  curateTheLookInfoConfig,
  getTheLookInfoConfig,
  requestTheLookInfoConfig,
} from 'modules/layout/Header/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import OverviewModal from 'modules/layout/Header/OverviewModal/OverviewModal';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import clsx from 'clsx';
import { localStorageKeys } from 'modules/app/constants';
import { setAmbassadorsActiveTabAction } from 'modules/getTheLook/ambassadorPage/store/actions';
import { getTheLookAmbassadorsTabKeys } from 'modules/getTheLook/ambassadorPage/config';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import { userShape } from 'modules/currentUser/propTypes';
import classes from './Header.module.scss';

const SectionsNavigation = ({
  changeActiveTab,
  styleQuizResult,
  user,
  toggleModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const [getTheLookModalOpen, setGetTheLookModalOpen] = useState(false);
  const [curateTheLookModalOpen, setCurateTheLookModalOpen] = useState(false);
  const [requestTheLookModalOpen, setRequestTheLookModalOpen] = useState(false);

  const getTheLookModalSettings = useMemo(
    () => ({
      modalTitle: 'Get The Look Overview',
      modalDescription:
        'Get the Look of Your Favorite Inspiration Images in the Styles You Love!',
    }),
    []
  );

  const requestTheLookModalSettings = useMemo(
    () => ({
      modalTitle: 'Request the Look Overview',
      modalDescription:
        'Request the Look of Your Favorite Room Images to Get Look Board Recommendations from Ambassadors Specializing in Your Style',
    }),
    []
  );
  const curateTheLookModalSettings = useMemo(
    () => ({
      modalTitle: 'Curate the Look Overview',
      modalDescription: 'Curate the Look of Your Favorite Inspiration Images',
    }),
    []
  );

  const handleOpenGetTheLookModal = useCallback(() => {
    setGetTheLookModalOpen(true);
  }, []);

  const handleCloseGetTheLookModal = useCallback(() => {
    setGetTheLookModalOpen(false);
  }, []);

  const handleOpenCurateTheLookModal = useCallback(() => {
    setCurateTheLookModalOpen(true);
  }, []);

  const handleCloseCurateTheLookModal = useCallback(() => {
    setCurateTheLookModalOpen(false);
  }, []);

  const handleOpenRequestTheLookModal = useCallback(() => {
    setRequestTheLookModalOpen(true);
  }, []);

  const handleCloseRequestTheLookModal = useCallback(() => {
    setRequestTheLookModalOpen(false);
  }, []);

  const getClasses = useCallback(
    (tab) => {
      if (!history.location.pathname.includes(tab)) return classes.navLink;
      return `${classes.navLink} ${classes.navLinkActive}`;
    },
    [history.location.pathname]
  );
  // useCustomEventListener(GTL_RELOAD, resetFilterValues);

  const showNewSectionInfo = useCallback(
    (section) => {
      // if (section === 'getTheLook') {
      //   customEvent.trigger(GTL_RELOAD);
      // }
      const status = JSON.parse(
        localStorage.getItem(localStorageKeys.introModalsStatus)
      );
      if (status) {
        if (!status[section]) {
          status[section] = true;
          localStorage.setItem(
            localStorageKeys.introModalsStatus,
            JSON.stringify(status)
          );
          toggleModal(true);
        }
      }
    },
    [toggleModal]
  );
  const getRoute = (page) => {
    if (matchesMediaQuery) {
      switch (page) {
        case 'getTheLook': {
          return routesByName.getTheLook.index;
        }
        case 'curateTheLook': {
          return routesByName.curateTheLook.canvas;
        }
        case 'requestTheLook': {
          return routesByName.requestTheLook.index;
        }
        default:
          return routesByName.home;
      }
    }
    const status = JSON.parse(
      localStorage.getItem(localStorageKeys.introModalsStatus)
    );

    if (!status && !matchesMediaQuery) {
      return routesByName.home;
    }

    switch (page) {
      case 'getTheLook': {
        if (!status[page]) {
          return routesByName.getTheLook.index;
        }
        if (user && !user.preferences) {
          return routesByName.getTheLook.index;
        }
        // if (!user && !Object.keys(styleQuizResult.data).length) {
        //   return `${routesByName.getTheLook.index}?${routesByName.auth.key}=${routesByName.auth.signUp}`;
        // }

        return routesByName.getTheLook.index;
      }
      case 'curateTheLook': {
        if (!status[page]) {
          return routesByName.curateTheLook.canvas;
        }
        if (user && !user.preferences) {
          return routesByName.curateTheLook.canvas;
        }
        if (!user && !Object.keys(styleQuizResult.data).length) {
          return `${routesByName.curateTheLook.canvas}?${routesByName.auth.key}=${routesByName.auth.signUp}`;
        }

        return routesByName.curateTheLook.canvas;
      }
      case 'requestTheLook': {
        if (!status[page]) {
          return routesByName.requestTheLook.index;
        }
        if (user && !user.preferences) {
          return routesByName.requestTheLook.index;
        }
        // if (!user && !Object.keys(styleQuizResult.data).length) {
        //   return `${routesByName.requestTheLook.index}?${routesByName.auth.key}=${routesByName.auth.signUp}`;
        // }

        return routesByName.requestTheLook.index;
      }
      default:
        return routesByName.home;
    }
  };
  return (
    <>
      <div
        className={clsx('d-flex align-items-center', {
          'mb-4': !matchesMediaQuery,
          'mb-1': matchesMediaQuery,
        })}
      >
        <div className={`${classes.menuItem} d-flex justify-content-center`}>
          <div className="position-relative">
            <Link
              to={getRoute('getTheLook')}
              className={getClasses(routesByName.getTheLook.index)}
              onClick={() => {
                changeActiveTab(getTheLookTabKeys.galleryView);
                dispatch(
                  setAmbassadorsActiveTabAction(
                    getTheLookAmbassadorsTabKeys.main
                  )
                );
                showNewSectionInfo('getTheLook');
              }}
            >
              <span>Get</span>
              <span className="secondary-color">The Look</span>
            </Link>
            {!matchesMediaQuery && (
              <Route path={routesByName.getTheLook.index}>
                <button
                  onClick={handleOpenGetTheLookModal}
                  className={classes.infoBtn}
                  type="button"
                >
                  <SpriteIcon className={classes.infoIcon} name="info" />
                  Get The Look Overview
                </button>
              </Route>
            )}
          </div>
        </div>
        <div className={classes.navLinkDivider} />
        <div className={`${classes.menuItem} d-flex justify-content-center`}>
          <div className="position-relative">
            <Link
              to={getRoute('requestTheLook')}
              className={getClasses(routesByName.requestTheLook.index)}
              onClick={() => showNewSectionInfo('requestTheLook')}
            >
              <span>Request</span>
              <span className="secondary-color">The Look</span>
            </Link>
            {!matchesMediaQuery && (
              <Route path={routesByName.requestTheLook.index}>
                <button
                  onClick={handleOpenRequestTheLookModal}
                  className={classes.infoBtn}
                  type="button"
                >
                  <SpriteIcon className={classes.infoIcon} name="info" />
                  Request The Look Overview
                </button>
              </Route>
            )}
          </div>
        </div>
        <div className={classes.navLinkDivider} />
        <div className={`${classes.menuItem} d-flex justify-content-center`}>
          <div className="position-relative">
            <Link
              to={getRoute('curateTheLook')}
              className={getClasses(routesByName.curateTheLook.canvas)}
              onClick={() => showNewSectionInfo('curateTheLook')}
            >
              <span>Curate</span>
              <span className="secondary-color">The Look</span>
            </Link>
            {!matchesMediaQuery && (
              <Route path={routesByName.curateTheLook.index}>
                <button
                  onClick={handleOpenCurateTheLookModal}
                  className={classes.infoBtn}
                  type="button"
                >
                  <SpriteIcon className={classes.infoIcon} name="info" />
                  Curate The Look Overview
                </button>
              </Route>
            )}
          </div>
        </div>
      </div>
      <OverviewModal
        config={getTheLookInfoConfig}
        open={getTheLookModalOpen}
        onClose={handleCloseGetTheLookModal}
        {...getTheLookModalSettings}
      />
      <OverviewModal
        config={curateTheLookInfoConfig}
        open={curateTheLookModalOpen}
        onClose={handleCloseCurateTheLookModal}
        {...curateTheLookModalSettings}
      />
      <OverviewModal
        config={requestTheLookInfoConfig}
        open={requestTheLookModalOpen}
        onClose={handleCloseRequestTheLookModal}
        {...requestTheLookModalSettings}
      />
    </>
  );
};

SectionsNavigation.propTypes = {
  changeActiveTab: PropTypes.func.isRequired,
  styleQuizResult: PropTypes.oneOfType([PropTypes.object]).isRequired,
  resetFilterValues: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  user: userShape,
};

SectionsNavigation.defaultProps = {
  user: null,
};

export default SectionsNavigation;
