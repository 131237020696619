import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BasicAvatar from 'components/ui/BasicAvatar';
import profImg from 'assets/img/pin-image-img.svg';
import nomineesService from 'modules/dashboard/nominees/nomineesService';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import { useDispatch, useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import buildResourceUrl from 'utils/buildResourceUrl';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import { professionsMap } from 'modules/account/profileAndPreferences/preferences/constants';
import useUserStatus from 'hooks/useUserStatus';
import { toggleUserFollowedAction } from '../store/actions';
import classes from '../userPage.module.scss';

const UserCard = ({ user }) => {
  const {
    firstName,
    lastName,
    avatar,
    styles,
    colors,
    profession,
    roomTypes,
    lookBoards,
    id,
    isFollowed,
    banner,
    badge,
  } = user;

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    styles: stylesEnum,
    colors: colorsEnum,
    roomTypes: roomTypesEnum,
  } = useSelector((state) => state.app.enums);
  const appUser = useSelector((state) => state.auth.user);

  const { showcaseBanner, userAvatar } = useSelector(
    (state) => state.app.config.endpoints.media
  );
  const status = useUserStatus(badge);

  const [followLoading, setFollowLoading] = useState(false);

  const handleFollow = useCallback(async () => {
    if (!appUser) {
      history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
      return;
    }
    try {
      setFollowLoading(true);
      if (isFollowed) {
        await nomineesService.unFollowUser(id);
        successToastr(`You are not following ${firstName} ${lastName} anymore`);
      } else {
        await nomineesService.followUser(id);
        successToastr(`You are now following ${firstName} ${lastName}`);
      }
      dispatch(toggleUserFollowedAction(id));
    } catch (e) {
      errorToastr('Error', e.generalError);
    } finally {
      setFollowLoading(false);
    }
  }, [appUser, dispatch, firstName, history, id, isFollowed, lastName]);

  const avatarUrl = useMemo(() => {
    if (!avatar) return null;
    return buildResourceUrl(userAvatar.thumb, avatar.userId, avatar.hash);
  }, [avatar, userAvatar.thumb]);

  const bannerUrl = useMemo(() => {
    if (!banner) return null;
    const { media, type: bannerType } = banner || {};

    return bannerType === bannerTypeKeys.image && media
      ? buildResourceUrl(showcaseBanner.small, media.userId, media.hash)
      : null;
  }, [banner, showcaseBanner.small]);

  const userProfession = useMemo(() => {
    if (!profession) return null;
    return professionsMap[profession].title;
  }, [profession]);

  return (
    <div className={classes.root}>
      <div
        className={classes.bannerContainer}
        style={
          banner
            ? {
                background:
                  banner.type === bannerTypeKeys.color ? banner.color : 'none',
              }
            : {}
        }
      >
        <div className={classes.mask} />
        {banner?.type === bannerTypeKeys.image && (
          <img src={bannerUrl} alt="" />
        )}
        {status && (
          <div className={classes.status}>
            <img src={status.icon} alt="status" />
          </div>
        )}
      </div>

      <BasicAvatar
        firstName={firstName}
        lastName={lastName}
        src={avatarUrl}
        className={classes.avatar}
      />

      <div className={classes.ambassadorInfo}>
        <div className={classes.nameContainer}>
          <b>{`${firstName} ${lastName}`}</b>
        </div>

        <div className="mt-1 text-xs font-italic text-center text-gray">
          {lookBoards.length === 1
            ? `${lookBoards.length} Look Board`
            : `${lookBoards.length} Look Boards`}
        </div>

        <div className="d-flex justify-content-between mt-4 align-items-center">
          <div className="d-flex align-items-center">
            <img src={profImg} alt="" className={classes.professionImg} />
            <h2 className={classes.profession}>
              {userProfession || 'Pin the Look Designer'}
            </h2>
          </div>
          <button
            type="button"
            onClick={handleFollow}
            className={classes.followBtn}
            disabled={followLoading}
          >
            {isFollowed ? 'Unfollow' : 'Follow'}
          </button>
        </div>

        <ul className={classes.list}>
          <li className={classes.listItem}>
            <span className={classes.listItemTitle}>Styles:</span>
            <span className={classes.stylesList}>
              {!styles.length && 'Not specified'}
              {styles.slice(0, 2).map((style, idx) => (
                <>
                  {stylesEnum[style].name}
                  {idx + 1 !== styles.slice(0, 2).length && ', '}
                </>
              ))}
            </span>
            {styles.length > 2 && (
              <div className={classes.stylesMoreIcon}>+{styles.length - 2}</div>
            )}
          </li>
          <li className={classes.listItem}>
            <span className={classes.listItemTitle}>Type:</span>
            <span className={classes.stylesList}>
              {!roomTypes.length && 'Not specified'}
              {roomTypes.map((type, idx) => (
                <>
                  {roomTypesEnum[type].name}
                  {idx + 1 !== roomTypes.slice(0, 2).length && ', '}
                </>
              ))}
            </span>
            {roomTypes.length > 2 && (
              <div className={classes.stylesMoreIcon}>
                +{roomTypes.length - 2}
              </div>
            )}
          </li>
          <li className={classes.listItem}>
            <span className={classes.listItemTitle}>Colors:</span>
            <div className="d-flex">
              {!colors.length && (
                <span className={classes.stylesList}>Not specified</span>
              )}
              {colors.slice(0, 10).map((colorId) => (
                <div
                  key={colorId}
                  className={classes.color}
                  style={{ backgroundColor: colorsEnum[colorId].code }}
                />
              ))}
            </div>
            {colors.length > 10 && (
              <div className={classes.stylesMoreIcon}>
                +{colors.length - 10}
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    badge: PropTypes.number,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isFollowed: PropTypes.bool.isRequired,
    lookBoards: PropTypes.arrayOf(PropTypes.number),
    styles: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.arrayOf(PropTypes.number),
    roomTypes: PropTypes.arrayOf(PropTypes.number),
    profession: PropTypes.string,
    avatar: PropTypes.shape({
      avatarUrl: PropTypes.string,
      userId: PropTypes.number.isRequired,
      hash: PropTypes.string.isRequired,
    }),
    banner: PropTypes.shape({
      type: PropTypes.string.isRequired,
      color: PropTypes.string,
      media: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        hash: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default UserCard;
