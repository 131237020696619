import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { mediaShape } from 'modules/media/propTypes';
import { productShape } from 'modules/product/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import HoverOverlay from 'modules/getTheLook/components/HoverOverlay/HoverOverlay';
import { routesByName } from 'constants/routes';
import SharePopover from 'components/modals/SharePopover/SharePopover';

const ProductItem = ({
  product: { id, name, media, itemClassId, price, isLiked, shareUrl, slug },
  user,
  onToggleLike,
}) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const userAvatarUrl = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );

  const history = useHistory();
  const { pathname } = useLocation();

  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const avatarUrl = useMemo(
    () =>
      user.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );

  const productPrice = useMemo(() => {
    const calculatedPrice = price / 100;
    return Number.isNaN(calculatedPrice) ? 0 : calculatedPrice;
  }, [price]);

  const handleToggleLike = useCallback(() => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    onToggleLike(id, Number(!isLiked));
  }, [authenticated, onToggleLike, id, isLiked, history, pathname]);

  const moveToDetails = useCallback(() => {
    history.push(routesByName.product.details(id, slug));
  }, [history, id, slug]);

  const handleOpenSharePopover = useCallback(({ currentTarget }) => {
    setShareAnchorEl(currentTarget);
  }, []);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  return (
    <>
      <ProductThumbnail
        name={name}
        media={media}
        itemClassId={itemClassId}
        price={productPrice}
        isLiked={isLiked}
        staticOverlay={
          <OverlayLabel
            text="product is discontinued"
            labelClasses={{ label: 'text-lg' }}
          />
        }
        hoverOverlay={
          <HoverOverlay
            firstName={user.firstName}
            lastName={user.lastName}
            displayName={user?.displayName}
            shareUrl={user?.shareUrl}
            avatarUrl={avatarUrl}
            isLiked={isLiked}
            onToggleLike={handleToggleLike}
            onClick={moveToDetails}
            onShare={handleOpenSharePopover}
          />
        }
      />
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        placement="bottom"
        shareUrl={shareUrl}
      />
    </>
  );
};

ProductItem.propTypes = {
  product: productShape.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    avatar: mediaShape,
  }).isRequired,
  onToggleLike: PropTypes.func.isRequired,
};

export default ProductItem;
