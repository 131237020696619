import React, { useCallback, useEffect, useState } from 'react';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import PointsAndBadges from 'modules/dashboard/ambassadorManagement/PointsAndBadges/PointsAndBadges';
import AmbassadorRewards from 'modules/dashboard/ambassadorManagement/AmbassadorRewards/AmbassadorRewards';
import { useDispatch, useSelector } from 'react-redux';
import useUserStatus from 'hooks/useUserStatus';
import PropTypes from 'prop-types';
import { syncCurrentUserAction } from 'modules/auth/store/actions';
import classes from './AmbassadorLevel.module.scss';

const AmbassadorLevel = ({ stats }) => {
  const dispatch = useDispatch();
  const badge = useSelector((state) => state.auth.user?.badge);
  const status = useUserStatus(badge);

  const [systemModalOpen, setSystemModalOpen] = useState(false);

  const onSystemModalClose = useCallback(() => {
    setSystemModalOpen(false);
  }, []);

  const openSystemModal = useCallback(() => {
    setSystemModalOpen(true);
  }, []);

  useEffect(() => {
    dispatch(syncCurrentUserAction());
  }, [dispatch]);

  return (
    <>
      <div className={classes.root}>
        <h3 className={classes.title}>Ambassador Level</h3>
        {status && (
          <div className={classes.status} style={{ background: status.color }}>
            <img
              src={status.id === 1 ? status.invertedIcon : status.icon}
              alt={status.title}
              style={{ filter: status.id === 1 ? '' : 'brightness(100)' }}
            />
            <span>{status.name}</span>
          </div>
        )}
        <div className={classes.total}>
          Total Points: <b>{Number(stats?.pointsLeft) || 0}</b>
        </div>
        <button
          className={classes.viewBtn}
          type="button"
          onClick={openSystemModal}
        >
          View Point System
        </button>
      </div>

      <BasicModal
        open={systemModalOpen}
        onClose={onSystemModalClose}
        fullWidth
        maxWidth="lg"
        classes={{ root: classes.pointSystemModal }}
      >
        <h2>PIN THE LOOK POINTS SYSTEM</h2>
        <div className={classes.systemItem}>
          <PointsAndBadges />
        </div>
        <div className={classes.systemItem}>
          <AmbassadorRewards />
        </div>
      </BasicModal>
    </>
  );
};

AmbassadorLevel.propTypes = {
  stats: PropTypes.shape({pointsLeft:PropTypes.number}).isRequired,
};

export default AmbassadorLevel;
