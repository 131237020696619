import React from 'react';
import PropTypes from 'prop-types';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import Button from 'components/ui/Button/Button';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import clsx from 'clsx';
import { lookBoardDetailsFormFields } from 'modules/curateTheLook/constants';
import classes from 'modules/curateTheLook/lookBoardDetails/LookBoardDetails.module.scss';
import findObjectById from '../../../../utils/findObjectById';

const LookBoardInfo = ({
  selectedImage,
  lookBoardDetailsFormValues,
  imgUrl,
  roomTypes,
  itemClasses,
  styles,
  // colorList,
  productList,
  lookBoardStatus,
  onChangeStatus,
  onSubmitLookBoard,
  onEditDetails,
  radioOptions,
  requestTitle,
  onDetailsPreview,
  loading,
  activeTab,
  onChangeTab,
}) => (
  <div className={classes.detailsSection}>
    <div className={classes.lookBoardInfo}>
      <div className="mb-2">
        <div className="mr-5">
          <div className="d-flex mb-2">
            <span className={classes.infoTitle}>Title</span>
            <span className={classes.infoText}>
              {lookBoardDetailsFormValues.title}
            </span>
          </div>
          <div className="d-flex mb-2">
            {selectedImage.type === imageTypeKeys.room ? (
              <>
                <span className={classes.infoTitle}>Room Type</span>
                <span className={classes.infoText}>
                  {roomTypes[lookBoardDetailsFormValues.roomTypeId].name}
                </span>
              </>
            ) : (
              <>
                <span className={classes.infoTitle}>Item Class</span>
                <span className={classes.infoText}>
                  {
                    findObjectById(
                      lookBoardDetailsFormValues.itemClassId,
                      itemClasses
                    )?.name
                  }
                </span>
              </>
            )}
          </div>
          <div className="d-flex mb-2">
            <span className={classes.infoTitle}>Style</span>
            <span className={classes.infoText}>
              {styles[lookBoardDetailsFormValues.styleId].name}
            </span>
          </div>
        </div>
        <div>
          {/* <div className="d-flex mb-2">
          <span className={classes.infoTitle}>Primary Colors</span>
          <div className="d-flex">
            {colorList.map(({ id: colorId, name, code }) => (
              <div
                key={colorId}
                className={classes.colorPoint}
                title={name}
                style={{ background: code }}
              />
            ))}
          </div>
        </div> */}
          <div className="d-flex mb-2">
            <span className={classes.infoTitle}>Items</span>
            <span className={classes.infoText}>
              {productList
                .filter(
                  ({ itemClassId }) =>
                    findObjectById(itemClassId, itemClasses)?.singular !==
                    'Item Class Not Specified'
                )
                .map(
                  ({ itemClassId }) =>
                    findObjectById(itemClassId, itemClasses)?.singular
                )
                .join(', ')}
            </span>
          </div>
        </div>
      </div>
      {!requestTitle && (
        <div className="mb-2">
          {radioOptions.length > 1 && (
            <p className={`${classes.infoTitle} mb-1`}>Make your look board</p>
          )}
          <CustomRadioGroup
            controls={radioOptions}
            value={lookBoardStatus}
            onChange={onChangeStatus}
          />
        </div>
      )}
      <div className="mb-2 d-flex">
        <div>
          <p
            className={clsx(classes.infoTitle, {
              [classes.activeTab]:
                activeTab === lookBoardDetailsFormFields.description,
            })}
            data-tab={lookBoardDetailsFormFields.description}
            onClick={onChangeTab}
          >
            Design Description
          </p>
        </div>
        {lookBoardDetailsFormValues.message && (
          <div className={classes.messageTitle}>
            <p
              className={clsx(classes.infoTitle, 'ml-2', {
                [classes.activeTab]:
                  activeTab === lookBoardDetailsFormFields.message,
              })}
              data-tab={lookBoardDetailsFormFields.message}
              onClick={onChangeTab}
            >
              Message to Requestor
            </p>
          </div>
        )}
      </div>
      <div className="mb-2">
        <span className={classes.infoText}>
          {activeTab === lookBoardDetailsFormFields.description
            ? lookBoardDetailsFormValues.description
            : lookBoardDetailsFormValues.message ?? ''}
        </span>
      </div>
      <div className="flex-fill d-flex align-items-end">
        <div className="w-100 position-relative text-center">
          {onSubmitLookBoard && (
            <Button inline onClick={onSubmitLookBoard} isLoading={loading}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
    <div
      className={clsx(classes.selectedImgContainer, { 'pb-2': requestTitle })}
    >
      <div className={classes.imgWrapper}>
        <img alt={selectedImage?.title || ' title'} src={imgUrl} />
        <div className={classes.imgTitle}>
          <div className={classes.label}>
            <div className={classes.marker} />
            <span>Inspiration image</span>
          </div>
          <span
            className={clsx('text-xs text-nowrap text-ellipsis', {
              [classes.selectedImageTitle]: requestTitle,
            })}
          >
            {selectedImage?.title}
          </span>
          {requestTitle && (
            <Button
              inline
              color="secondary"
              variant="default"
              onClick={onDetailsPreview}
              className={clsx(
                'link-underline text-xs primary-color p-0',
                classes.viewRequestBtn
              )}
            >
              View Request
            </Button>
          )}
        </div>
      </div>
      {requestTitle && (
        <div className={classes.requestInfo}>
          <div>
            <b>Request Title: </b> {requestTitle}
          </div>
          <div>
            <b>Request Type: </b> Look of the{' '}
            <span className="text-capitalize">{selectedImage.type}</span>
          </div>
        </div>
      )}
      {onEditDetails && (
        <IconButton
          className={classes.editBtn}
          variant="inverted-grey"
          color="secondary"
          size="sm"
          onClick={onEditDetails}
        >
          <SpriteIcon name="edit" size="sm" />
        </IconButton>
      )}
    </div>
  </div>
);

LookBoardInfo.propTypes = {
  selectedImage: inspirationImageShape,
  lookBoardDetailsFormValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    roomTypeId: PropTypes.number,
    itemClassId: PropTypes.number,
    styleId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    message: PropTypes.string,
  }),
  requestTitle: PropTypes.string,
  radioOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  imgUrl: PropTypes.string,
  // colorList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roomTypes: PropTypes.shape({}).isRequired,
  itemClasses: PropTypes.shape({}).isRequired,
  styles: PropTypes.shape({}).isRequired,
  productList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lookBoardStatus: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func,
  onSubmitLookBoard: PropTypes.func,
  onEditDetails: PropTypes.func,
  onDetailsPreview: PropTypes.func,
  loading: PropTypes.bool,
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
};

LookBoardInfo.defaultProps = {
  selectedImage: null,
  lookBoardDetailsFormValues: null,
  onSubmitLookBoard: null,
  onEditDetails: null,
  imgUrl: null,
  onChangeStatus: null,
  requestTitle: null,
  onDetailsPreview: null,
  loading: false,
};

export default LookBoardInfo;
