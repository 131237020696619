import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useCallbackRef from 'hooks/useCallbackRef';
import {
  changeStepAction,
  selectImageAction,
  setImagesAction,
  unselectImageAction,
  updateCurateStateAction,
  updateIISearchParamsAction,
  updateImageFilterValuesAction,
  updateImagesAction,
} from 'modules/curateTheLook/store/actions';
import { uniq } from 'lodash-es';
import {
  createLookBoardStepKeys,
  infoMenuConfig,
} from 'modules/curateTheLook/constants';
import errorToastr from 'libs/toastr/errorToastr';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import ImageItem from 'modules/curateTheLook/createLookBoard/components/ImageItem/ImageItem';
import SideBar from 'modules/curateTheLook/createLookBoard/components/SideBar';
import ImagesSearchParamsBlock from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock';
import ScrollableDatabase from 'modules/curateTheLook/createLookBoard/components/ScrollableDatabase';
import SelectedImageBlock from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock';
import requestService from 'modules/request/requestService';
import {
  imagePageTypes,
  imageSourceKeys,
} from 'constants/inspirationImageSearchParams';
import { requestSourceKeys } from 'constants/requestSearchParams';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import useCancelToken from 'hooks/useCancelToken';
import mergeLookBoardsWithProducts from 'utils/mergeLookBoardsWithProducts';
import LookBoardExamplesModal from 'modules/curateTheLook/createLookBoard/components/LookBoardExamplesModal/LookBoardExamplesModal';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'components/ui/Button/Button';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import { createImageInstantlyForAction } from 'modules/inspirationImage/store/actions';
import { initialFilterValues } from 'modules/getTheLook/store/reducer';
import { routesByName } from 'constants/routes';
import featureNameKeys from 'constants/featureNames';
import { limit, unauthorizedLimit } from 'constants/inspirationImages';
import noImagesPlug from 'assets/img/curate-the-look-page/noImagesPlug.jpg';
import SelectedLookBoardBlock from '../../getTheLook/components/SelectedLookBoardBlock';
import lookBoardService from '../../lookBoard/lookBoardService';
import productService from '../../product/productService';
import transformArrayToMap from '../../../utils/transformArrayToMap';

const LeftPanel = ({
  onOpenPreview,
  onOpenDragModal,
  onRequestDetailsPreview,
  setCurrentLookBoardExtend,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const {
    currentStep,
    imageList: iList,
    inspirationImageSearchParams: searchParams,
    imageFilterValues: filterValues,
    lookBoardData: { inspirationImageId: selectedImage, lookBoardId },
  } = useSelector((state) => state.curateTheLook);
  const IILibrary = useSelector((state) => state.inspirationImage.library);
  const imageList = useMemo(() => iList.map((id) => IILibrary[id]), [
    IILibrary,
    iList,
  ]);
  const enums = useSelector((state) => state.app.enums);

  const history = useHistory();
  const { pathname } = useLocation();
  const preferences = useSelector((state) => state.auth.user?.preferences);
  const [scrollBarNode, scrollBarRef] = useCallbackRef();
  const [scrollPosition, setScrollPosition] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    initialScrollableDatabaseHeight,
    setInitialScrollableDatabaseHeight,
  ] = useState(0);
  const [sourceRequests, setSourceRequests] = useState(false);
  const [requestsList, setRequestsList] = useState([]);
  const [lookBoardExamplesModalOpen, setLookBoardExamplesModalOpen] = useState(
    false
  );
  const [lookBoardExamples, setLookBoardExamples] = useState([]);
  const [lookBoardsImgInfo, setLookBoardsImgInfo] = useState(null);
  const [lookBoardExamplesLoading, setLookBoardExamplesLoading] = useState(
    false
  );
  const [currentLookBoard, setCurrentLookBoard] = useState(null);
  const [currentLookBoardProducts, setCurrentLookBoardProducts] = useState(
    null
  );

  const isFirstStepSkipped = useMemo(
    () => !selectedImage && currentStep !== createLookBoardStepKeys.selectImage,
    [selectedImage, currentStep]
  );

  const [createCancelToken, isCancelled] = useCancelToken();

  const loadFirst = useCallback(async () => {
    setLoading(true);

    const cancelToken = createCancelToken();

    if (searchParams.source === imageSourceKeys.requests) {
      setSourceRequests(true);

      try {
        const list = await requestService.getRequests(
          requestSourceKeys.pending,
          searchParams,
          { cancelToken }
        );

        setRequestsList(list);

        if (list.length > 0) {
          const imagesIds = uniq(list.map(({ imageId }) => imageId));
          const [
            transformedImagesList,
            imagesList,
          ] = await inspirationImageService.getImagesByIds(imagesIds);

          dispatch(setImagesAction(transformedImagesList, imagesList));

          dispatch(
            updateIISearchParamsAction({
              offset: searchParams.offset + list.length,
            })
          );
        }
        if (scrollBarNode) {
          scrollBarNode.scrollToTop();
        }
        setHasMore(list.length === limit);
        setLoading(false);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      return;
    }
    setSourceRequests(false);

    const colors = Object.values(enums.colors)
      .filter((color) => filterValues.colors.includes(color.color_group_id))
      .map((color) => color.id);

    try {
      const [transformedList, list] = await inspirationImageService.getImages(
        {
          ...searchParams,
          ...(searchParams.search.trim().length
            ? initialFilterValues
            : { ...filterValues, colors }),
          pageType: imagePageTypes.database,
        },
        { cancelToken }
      );
      const imageArrLength = Object.keys(transformedList).length;

      dispatch(setImagesAction(transformedList, list));
      if (imageArrLength > 0) {
        dispatch(
          updateIISearchParamsAction({
            offset: searchParams.offset + imageArrLength,
          })
        );
      }

      if (scrollBarNode) {
        scrollBarNode.scrollToTop();
      }

      setHasMore(imageArrLength === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error loadFirst', e.generalError);
      }
    }
  }, [
    dispatch,
    searchParams,
    filterValues,
    scrollBarNode,
    createCancelToken,
    isCancelled,
  ]);

  useEffect(() => {
    const filters = {
      colors: preferences
        ? [...new Set(Object.values(preferences.colors).map((c) => Number(c)))]
        : [],
      styles: preferences ? preferences.styles : [],
      roomTypes: preferences ? preferences.roomTypes : [],
      subStyles: preferences?.subStyles ?? [],
    };
    dispatch(updateImageFilterValuesAction(filters));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(updateIISearchParamsAction({ offset: 0 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      if (lookBoardId) {
        try {
          const { result: lookBoard } = await lookBoardService.getLookBoardById(
            lookBoardId
          );
          const products = await productService.getProductsByIds(
            lookBoard.products
          );
          const productsMap = transformArrayToMap(products);
          const preparedProductList = lookBoard.products.map(
            (id) => productsMap[id]
          );
          setCurrentLookBoard(lookBoard);
          setCurrentLookBoardExtend({
            lookBoard,
            lookBoardProducts: preparedProductList,
          });

          setCurrentLookBoardProducts(preparedProductList);
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      }
    };
    fetchData().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [lookBoardId]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0 && scrollBarNode) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams, scrollBarNode]);

  useEffect(() => {
    if (!isFirstStepSkipped && !selectedImage && scrollBarNode) {
      scrollBarNode.scrollTop(scrollPosition);
    }
    // eslint-disable-next-line
  }, [selectedImage, scrollBarNode]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    const cancelToken = createCancelToken();

    if (searchParams.source === imageSourceKeys.requests) {
      try {
        const list = await requestService.getRequests(
          requestSourceKeys.pending,
          searchParams,
          { cancelToken }
        );

        setRequestsList((prevState) => [...prevState, ...list]);
        const updatedListLength = searchParams.offset + list.length;

        const imagesIds = uniq(list.map(({ imageId }) => imageId));
        const [
          transformedImagesList,
          imagesList,
        ] = await inspirationImageService.getImagesByIds(imagesIds);

        dispatch(updateImagesAction(transformedImagesList, imagesList));
        dispatch(updateIISearchParamsAction({ offset: updatedListLength }));

        if (!currentUser && updatedListLength >= unauthorizedLimit) {
          setHasMore(false);
        } else {
          setHasMore(list.length === limit);
        }
        setLoading(false);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      return;
    }

    const colors = Object.values(enums.colors)
      .filter((color) => filterValues.colors.includes(color.color_group_id))
      .map((color) => color.id);

    try {
      const [transformedList, list] = await inspirationImageService.getImages(
        {
          ...searchParams,
          ...(searchParams.search.trim().length
            ? initialFilterValues
            : { ...filterValues, colors }),
          pageType: imagePageTypes.database,
        },
        { cancelToken }
      );

      const imageArrLength = Object.keys(transformedList).length;
      const updatedListLength = searchParams.offset + imageArrLength;

      dispatch(updateImagesAction(transformedList, list));
      dispatch(updateIISearchParamsAction({ offset: updatedListLength }));

      if (!currentUser && updatedListLength >= unauthorizedLimit) {
        setHasMore(false);
      } else {
        setHasMore(imageArrLength === limit);
      }
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [
    currentUser,
    dispatch,
    searchParams,
    filterValues,
    isCancelled,
    createCancelToken,
  ]);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      dispatch(
        updateIISearchParamsAction({
          ...params,
          offset: 0,
        })
      );

      setHasMore(false);
    },
    [dispatch]
  );

  const handleSelectImage = useCallback(
    (imgId, reqId) => {
      if (scrollBarNode) {
        const currentScroll = scrollBarNode.getScrollTop();
        setScrollPosition(currentScroll);
      }

      if (reqId) {
        const request = requestsList.find(({ id }) => id === reqId);

        dispatch(
          updateCurateStateAction({
            requestInfo: {
              title: request.title,
              priorityItems: request.priorityItems,
              requesterId: request.userId,
              message: request.message,
            },
          })
        );
      }
      dispatch(selectImageAction(imgId, reqId));
    },
    [dispatch, scrollBarNode, requestsList]
  );

  const handleSelectLookBoard = useCallback(
    async (similarId) => {
      const {
        result: similarLookBoard,
      } = await lookBoardService.getLookBoardById(similarId);
      if (similarLookBoard.inspirationId) {
        dispatch(
          selectImageAction(
            similarLookBoard.inspirationId,
            null,
            null,
            similarId
          )
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      if (lookBoardsImgInfo) {
        setLookBoardExamples([]);
        setLookBoardExamplesLoading(true);

        try {
          const {
            result: { lookBoards, products },
          } = await inspirationImageService.getLookBoardDetails(
            lookBoardsImgInfo.id,
            {
              params: {
                limit: 6,
              },
            }
          );
          const lookBoardList = mergeLookBoardsWithProducts(
            lookBoards,
            products
          );

          setLookBoardExamplesLoading(false);
          setLookBoardExamples(lookBoardList);
        } catch (e) {
          setLookBoardExamplesLoading(false);
          errorToastr('Error', e.message);
        }
      }
    })();
  }, [lookBoardsImgInfo]);

  const handleOpenLookBoardExamplesModal = useCallback((id, slug) => {
    setLookBoardsImgInfo({ id, slug });
    setLookBoardExamplesModalOpen(true);
  }, []);

  const handleCloseLookBoardExamplesModal = useCallback(() => {
    setLookBoardExamplesModalOpen(false);
  }, []);

  const handleRegisterClick = useCallback(() => {
    history.push(
      `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
      { title: 'REGISTER NOW' }
    );
  }, [history, pathname]);

  const handleAddImageModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(createImageInstantlyForAction(featureNameKeys.curate));
  }, [currentUser, dispatch, pathname, history]);

  return (
    <div className="d-flex">
      <SideBar
        currentSource={searchParams.source}
        onUpdateSearchParams={handleUpdateSearchParams}
        unselectImage={(e) => dispatch(unselectImageAction(e))}
        infoMenuConfig={infoMenuConfig}
      />
      <div className={classes.sidePanel}>
        <ImagesSearchParamsBlock
          currentSource={searchParams.source}
          currentImgType={searchParams.type}
          searchQuery={searchParams.search}
          isFirstStepSkipped={isFirstStepSkipped}
          selectedImage={selectedImage}
          onChangeStep={(e) => dispatch(changeStepAction(e))}
          onUpdateSearchParams={handleUpdateSearchParams}
        />
        {!isFirstStepSkipped && !selectedImage && (
          <ScrollableDatabase
            initialHeight={initialScrollableDatabaseHeight}
            setInitialHeight={setInitialScrollableDatabaseHeight}
            scrollBarRef={scrollBarRef}
            loading={loading}
            hasMore={hasMore}
            loadMore={loadMore}
          >
            {sourceRequests && requestsList.length
              ? requestsList.map(
                  ({ id, imageId }) =>
                    IILibrary[imageId] && (
                      <ImageItem
                        key={id}
                        id={imageId}
                        slug={IILibrary[imageId].slug}
                        url={IILibrary[imageId].url}
                        userId={IILibrary[imageId].media.userId}
                        hash={IILibrary[imageId].media.hash}
                        isLiked={IILibrary[imageId].isLiked}
                        shareUrl={IILibrary[imageId].shareUrl}
                        sourceUrl={IILibrary[imageId].source_url}
                        lookBoardsCount={IILibrary[imageId].lookBoardsCount}
                        requestId={id}
                        onOpenPreview={onOpenPreview}
                        onSelectImage={handleSelectImage}
                        approval={IILibrary[imageId].approval}
                        publish={IILibrary[imageId].publish}
                      />
                    )
                )
              : null}
            {!sourceRequests && imageList.length ? (
              imageList.map(
                ({
                  id,
                  url,
                  isLiked,
                  shareUrl,
                  media: { userId, hash },
                  title,
                  image_source_name: imageSourceName,
                  source_url: sourceUrl,
                  lookBoardsCount,
                  approval,
                  publish,
                  slug,
                }) => (
                  <ImageItem
                    key={id}
                    id={id}
                    slug={slug}
                    url={url}
                    userId={userId}
                    hash={hash}
                    isLiked={isLiked}
                    shareUrl={shareUrl}
                    onOpenPreview={onOpenPreview}
                    onSelectImage={handleSelectImage}
                    onLookBoardsView={
                      searchParams.source === imageSourceKeys.all
                        ? handleOpenLookBoardExamplesModal
                        : undefined
                    }
                    imageSource={imageSourceName}
                    title={title}
                    sourceUrl={sourceUrl}
                    lookBoardsCount={lookBoardsCount}
                    approval={approval}
                    publish={publish}
                  />
                )
              )
            ) : (
              <div className={classes.noImagesWrapper}>
                <h5 className={classes.noImagesWrapper__title}>
                  Pin images to view them here
                </h5>
                <IconButton
                  color="primary"
                  size="lg"
                  onClick={handleAddImageModalOpen}
                  className={classes.noImagesWrapper__button}
                >
                  <SpriteIcon name="pin" size="lg" />
                </IconButton>
                <img
                  src={noImagesPlug}
                  className={classes.noImagesWrapper__plug}
                  alt=""
                />
              </div>
            )}

            {!currentUser && searchParams.offset >= unauthorizedLimit && (
              <div className="font-weight-normal d-flex justify-content-center pb-2">
                <Button
                  size="md"
                  className={clsx('w-100 mx-auto', classes.btn)}
                  onClick={handleRegisterClick}
                >
                  Register Now to View All Images
                </Button>
              </div>
            )}
          </ScrollableDatabase>
        )}
        {selectedImage && !lookBoardId && (
          <SelectedImageBlock
            inspirationImageId={selectedImage}
            searchParams={searchParams}
            onBack={(e) => dispatch(unselectImageAction(e))}
            onOpenPreview={onOpenDragModal}
            onSelectImage={(e) => dispatch(selectImageAction(e))}
            onRequestDetailsPreview={onRequestDetailsPreview}
            onViewLookBoardsModal={handleOpenLookBoardExamplesModal}
            showMagnifyIcon
          />
        )}
        {lookBoardId &&
          currentLookBoardProducts &&
          currentLookBoardProducts.length && (
            <SelectedLookBoardBlock
              inspirationImageId={Number.parseInt(
                currentLookBoard.inspirationId,
                10
              )}
              searchParams={searchParams}
              onBack={(e) => dispatch(unselectImageAction(e))}
              // onOpenPreview={handleImgModalOpen}
              onSelectImage={handleSelectImage}
              lookBoard={currentLookBoard}
              lookBoardProducts={currentLookBoardProducts}
              handleSelectLookBoard={handleSelectLookBoard}
            />
          )}
      </div>
      <LookBoardExamplesModal
        lookBoards={lookBoardExamples}
        open={lookBoardExamplesModalOpen}
        onClose={handleCloseLookBoardExamplesModal}
        loading={lookBoardExamplesLoading}
        inspirationImgInfo={lookBoardsImgInfo}
        title="Pre-curated lookboards for that image"
      />
    </div>
  );
};

LeftPanel.propTypes = {
  onOpenPreview: PropTypes.func.isRequired,
  onOpenDragModal: PropTypes.func.isRequired,
  onRequestDetailsPreview: PropTypes.func.isRequired,
  setCurrentLookBoardExtend: PropTypes.func.isRequired,
};

export default LeftPanel;
