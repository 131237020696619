import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import video from 'assets/video/style-quiz.mp4';
import posterImg from 'assets/video/style-poster.png';
import Button from 'components/ui/Button/Button';
import StyleQuizModal from 'components/modals/StyleQuizModal/StyleQuizModal';
import { routesByName } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterValuesAction } from 'modules/getTheLook/store/actions';
import classes from './StyleQuizPage.module.scss';
import { toggleQuizViewedAction } from '../layout/store/actions';
import ShowQuizResultModal from '../../components/modals/StyleQuizModal/ShowQuizResultModal';
import HowItWorksVideoModal from '../../components/modals/HowItWorksVideoModal';
import MetaTags from '../../components/MetaTags';
import { metaNames } from '../../components/MetaTags/config';

const StyleQuizPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const quizViewed = useSelector((state) => state.layout.quizViewed);
  const metaTags = useSelector((state) => state.app.enums.metaTags);

  const history = useHistory();
  const videoRef = useRef();
  const [styleQuizModalOpen, setStyleQuizModalOpen] = useState(false);
  const [quizResultModalOPen, setQuizResultModalOPen] = useState(false);

  const handleCloseStyleQuizModal = useCallback(
    () => setStyleQuizModalOpen(false),
    []
  );
  const handleOpenStyleQuizModal = useCallback(() => {
    videoRef.current.pause();
    setStyleQuizModalOpen(true);
  }, []);

  const handleCloseQuizResultModal = useCallback(
    () => setQuizResultModalOPen(false),
    []
  );
  const handleOpenQuizResultModal = useCallback(() => {
    setQuizResultModalOPen(true);
  }, []);

  const handleApplyStyleQuiz = useCallback(
    (filters) => {
      dispatch(updateFilterValuesAction(filters));
      setStyleQuizModalOpen(false);
      history.push(routesByName.getTheLook.index);
    },
    [history, dispatch]
  );

  const handlePause = useCallback((e) => e.target.blur(), []);

  useEffect(() => {
    return () => dispatch(toggleQuizViewedAction(true));
  }, [dispatch]);

  const autoPlayVideo = useMemo(() => {
    if (!user) return true;
    return !quizViewed;
  }, [quizViewed, user]);

  return (
    <>
      {metaTags && metaTags[metaNames.styleQuiz] && (
        <MetaTags metaData={metaTags[metaNames.styleQuiz]} />
      )}
      <div className={classes.root}>
        <h1 className={classes.title}>DISCOVER YOUR STYLE PROFILE</h1>
        <h3 className={classes.subTitle}>
          Take our Style Quiz to Discover your Unique Interior Design Style
        </h3>
        <div className="d-flex justify-content-center my-4">
          <Button
            variant="contained"
            className="w-auto"
            size="lg"
            onClick={handleOpenStyleQuizModal}
          >
            Take the Style Quiz
          </Button>
        </div>

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={videoRef}
          src={video}
          preload="metadata"
          autoPlay={autoPlayVideo}
          onPause={handlePause}
          controls
          className={classes.video}
          poster={posterImg}
        />
        <StyleQuizModal
          open={styleQuizModalOpen}
          onClose={handleCloseStyleQuizModal}
          onApply={handleApplyStyleQuiz}
          handleOpenQuizResultModal={handleOpenQuizResultModal}
        />
        <ShowQuizResultModal
          open={quizResultModalOPen}
          onClose={handleCloseQuizResultModal}
          handleViewResults={handleApplyStyleQuiz}
        />
        <HowItWorksVideoModal video={video} posterImg={posterImg} />
      </div>
    </>
  );
};

export default StyleQuizPage;
