import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { initialFilterValues } from 'modules/getTheLook/store/reducer';
import { transformSubStylesFilterValues } from 'components/modals/FiltersModal/FiltersModal';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import {
  filterTabKeys,
  styleQuizDescriptions,
  styleQuizSubtitles,
} from 'components/modals/FiltersModal/constants';
import { stepsConfig } from 'components/modals/StyleQuizModal/constants';
import {
  getStylesBySelectedVibes,
  getSubStylesBySelectedStyles,
} from 'modules/app/store/selectors';
import video from 'assets/video/style-quiz.mp4';
import posterImg from 'assets/video/style-poster.png';
import SpriteIcon from 'components/ui/SpriteIcon';
import StylesFilter from 'components/modals/FiltersModal/components/StylesFilter';
import SubStylesFilter from 'components/modals/FiltersModal/components/SubStylesFilter';
import VibesFilter from 'components/modals/FiltersModal/components/VibesFilter';
import RoomTypesFilter from 'components/modals/FiltersModal/components/RoomTypesFilter';
import ColorSelect from 'components/colorSelectors/ColorSelect';
import Button from 'components/ui/Button/Button';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import { saveStyleQuizAsPreferencesAction } from 'modules/currentUser/actions';
import SummaryPage from 'components/modals/StyleQuizModal/SummaryPage';
import classes from 'components/modals/FiltersModal/FiltersModal.module.scss';
import {
  setStyleQuizResultModalAction,
  toggleHowItWorksVideoModalAction,
  toggleStyleQuizResultModalAction,
} from 'modules/layout/store/actions';
import { maxWidthMd } from 'constants/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { SelectedStyles } from './SelectedStylesModal/SelectedStyles';
import findObjectById from '../../../utils/findObjectById';
import warningToastr from '../../../libs/toastr/warningToastr';
import usePersonas from '../../../hooks/usePersonas';

const MAX_SELECTION = {
  vibe: 1,
  style: 1,
};
const StyleQuizModal = ({
  open,
  onClose,
  onApply,
  handleOpenQuizResultModal,
  setModalVideo,
}) => {
  const dispatch = useDispatch();

  const enums = useSelector((state) => state.app.enums);
  const currentUser = useSelector((state) => state.auth.user);
  const styleQuis = useSelector((state) => state.layout.styleQuizResult);
  const authenticated = useMemo(() => Boolean(currentUser), [currentUser]);
  const timeout = useRef(null);

  const { pathname } = useLocation();
  const history = useHistory();

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const [quizConfig, setQuizConfig] = useState({ ...stepsConfig });

  const [isSelectedStylesActive, setIsSelectedStylesActive] = useState(false);

  const [activeTab, setActiveTab] = useState(quizConfig[filterTabKeys.vibes]);
  const [currentFilters, setCurrentFilters] = useState(
    transformSubStylesFilterValues(initialFilterValues)
  );
  const [savedFilters, setSavedFilters] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [promptShow, setPromptShow] = useState(false);

  const toggleModal = useCallback(() => {
    setIsSelectedStylesActive((prev) => !prev);
  }, []);

  const userPersona = usePersonas(savedFilters.styles);
  const nextStepTitle = useMemo(() => {
    if (quizConfig[activeTab.nextStep]) {
      return quizConfig[activeTab.nextStep].title;
    }
    return null;
  }, [activeTab, quizConfig]);
  const selectedOptions = useMemo(() => {
    if (activeTab.value === 'colors') {
      return currentFilters[activeTab.value]?.map(
        (id) => enums.colorGroups[id]
      );
    }
    if (activeTab.value === 'styles') {
      return currentFilters[activeTab.value]?.map((id) =>
        findObjectById(id, enums.styles)
      );
    }
    return currentFilters[activeTab.value].map(
      (id) => enums[activeTab.value][id]
    );
  }, [enums, currentFilters, activeTab]);

  const subStyleOptions = useMemo(
    () =>
      getSubStylesBySelectedStyles(
        enums[filterTabKeys.subStyles],
        currentFilters[filterTabKeys.styles]
      ),
    [enums, currentFilters]
  );

  const styleOptions = useMemo(
    () =>
      getStylesBySelectedVibes(
        enums[filterTabKeys.styles],
        currentFilters[filterTabKeys.vibes]
      ),
    [enums, currentFilters]
  );

  const roomTypeOptions = useMemo(
    () =>
      Object.values(enums[filterTabKeys.roomTypes])
        .slice(0, 4)
        .filter((e) => e.name !== 'Kitchen'),
    [enums]
  );

  useEffect(() => {
    if (!open) {
      timeout.current = setTimeout(() => {
        setCurrentFilters(transformSubStylesFilterValues(initialFilterValues));
        setSavedFilters({});
        setActiveTab(quizConfig[filterTabKeys.vibes]);
        setSubmitted(false);
        setPromptShow(false);
        setQuizConfig((prevState) => {
          const config = { ...prevState };
          Object.keys(config).forEach((key) => {
            config[key].checked = false;
          });
          return config;
        });
      }, 100);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [open, quizConfig]);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleUnselectOption = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;
      const updatedFilterValue = currentFilters[activeTab.value].filter(
        (value) => value !== Number(id)
      );
      setCurrentFilters((prevState) => ({
        ...prevState,
        [activeTab.value]: updatedFilterValue,
      }));
    },
    [currentFilters, activeTab]
  );

  const clearSelectedOptions = useCallback(() => {
    setCurrentFilters((prevState) => ({
      ...prevState,
      [activeTab.value]: [],
    }));
    setIsSelectedStylesActive(false);
  }, [activeTab]);

  const showWarning = (type, maxSelection) => {
    warningToastr('Warning', `Please select up to ${maxSelection} ${type}`);
  };

  const handleChangeFilterValue = useCallback(
    (id, checked) => {
      const typeNames = {
        [filterTabKeys.vibes]: 'vibe',
        [filterTabKeys.styles]: 'style',
      };
      const type = typeNames[activeTab.value];

      if (checked && type) {
        const maxSelection = MAX_SELECTION[type];

        if (currentFilters[activeTab.value].length >= 1) {
          showWarning(type, maxSelection);
          return;
        }
      }

      let currentValuesCopy = [...currentFilters[activeTab.value]];
      if (checked) {
        currentValuesCopy.push(Number(id));
      } else {
        currentValuesCopy = currentValuesCopy.filter(
          (value) => value !== Number(id)
        );
      }

      setCurrentFilters((prevState) => ({
        ...prevState,
        [activeTab.value]: currentValuesCopy,
      }));
    },
    [activeTab, currentFilters]
  );

  const handleChangeStyleFilter = useCallback(
    (id, checked) => {
      handleChangeFilterValue(id, checked);
      if (!checked) {
        setCurrentFilters((prev) => ({
          ...prev,
          subStyles: prev.subStyles.filter((subStyleId) => {
            const currentSubStyle = Object.values(enums.subStyles).find(
              (subStyle) => subStyle.id === subStyleId
            );
            return currentSubStyle.styleId !== id;
          }),
        }));
      }
      if (
        (activeTab === quizConfig[filterTabKeys.styles] ||
          activeTab === quizConfig[filterTabKeys.subStyles]) &&
        promptShow
      ) {
        setPromptShow(false);
      }
    },
    [
      handleChangeFilterValue,
      activeTab,
      quizConfig,
      promptShow,
      enums.subStyles,
    ]
  );

  const handleChangeVibeFilter = useCallback(
    (id, checked) => {
      handleChangeFilterValue(id, checked);
      if (!checked) {
        setCurrentFilters((prev) => ({
          ...prev,
          styles: prev.styles.filter((styleId) => {
            const currentStyle = Object.values(enums.styles).find(
              (style) => style.id === styleId
            );
            return currentStyle.vibeId !== id;
          }),
        }));
      }
    },
    [enums, handleChangeFilterValue]
  );

  const handleChangeColorFilter = useCallback(
    ({ target: { id, checked } }) => {
      handleChangeFilterValue(id, checked);
    },
    [handleChangeFilterValue]
  );

  const handleSkipStep = useCallback(() => {
    if (activeTab.value === filterTabKeys.styles) {
      setActiveTab(quizConfig[filterTabKeys.roomTypes]);
      return;
    }
    if (quizConfig[activeTab.nextStep]) {
      setActiveTab(quizConfig[activeTab.nextStep]);
      setQuizConfig((prevState) => {
        const config = { ...prevState };
        config[activeTab.value].checked = true;
        return config;
      });
    }
  }, [activeTab, quizConfig]);

  const scrollToElement = useCallback((elemId) => {
    setTimeout(() => {
      const elem = document.getElementById(elemId);
      // eslint-disable-next-line no-unused-expressions
      elem?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 100);
  }, []);

  const scrollToError = useCallback(() => {
    // wait until prompt will be rendered
    setTimeout(() => {
      const elem = document.getElementById('prompt');
      // eslint-disable-next-line no-unused-expressions
      elem?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }, 100);
  }, []);

  const handleMoveToNextStep = useCallback(() => {
    if (activeTab.prompt && currentFilters[activeTab.value].length === 0) {
      setPromptShow(true);
      scrollToError();
    } else {
      setSavedFilters((prevState) => ({
        ...prevState,
        [activeTab.value]: currentFilters[activeTab.value],
      }));
      if (quizConfig[activeTab.nextStep]) {
        setActiveTab(quizConfig[activeTab.nextStep]);
      }
      setPromptShow(false);
      setQuizConfig((prevState) => {
        const config = { ...prevState };
        config[activeTab.value].checked = true;
        return config;
      });
      if (matchesMediaQuery) {
        scrollToElement('title');
      }
    }
  }, [
    activeTab.prompt,
    activeTab.value,
    activeTab.nextStep,
    currentFilters,
    scrollToError,
    quizConfig,
    matchesMediaQuery,
    scrollToElement,
  ]);

  const handleMoveToPrevStep = useCallback(() => {
    if (quizConfig[activeTab.prevStep]) {
      setActiveTab(quizConfig[activeTab.prevStep]);
    }
    setPromptShow(false);
    setQuizConfig((prevState) => {
      const config = { ...prevState };
      config[activeTab.value].checked = false;
      config[activeTab.prevStep].checked = false;
      return config;
    });
  }, [activeTab, quizConfig]);

  const handleSubmitQuiz = useCallback(() => {
    dispatch(setStyleQuizResultModalAction(currentFilters));
    const filledFilters = Object.keys(currentFilters).filter(
      (filterKey) => currentFilters[filterKey].length
    );
    if (!filledFilters.length) {
      onApply(null);
    }

    setSavedFilters((prevState) => ({
      ...prevState,
      [filterTabKeys.colors]: currentFilters[filterTabKeys.colors],
    }));
    setSubmitted(true);
    setQuizConfig((prevState) => {
      const config = { ...prevState };
      config[activeTab.value].checked = true;
      return config;
    });
  }, [activeTab, onApply, currentFilters, dispatch]);

  const handleApplyFilters = useCallback(
    (filters) => {
      if (!currentUser) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`
        );
        return;
      }
      const savedSubStyles = filters[filterTabKeys.subStyles] ?? [];
      const preparedSubStyles = savedSubStyles.reduce((accum, currValue) => {
        /* eslint-disable no-param-reassign */
        accum[currValue] = 1;

        return accum;
      }, {});

      onApply({
        ...filters,
        [filterTabKeys.subStyles]: preparedSubStyles,
      });
    },
    [currentUser, onApply, history, pathname]
  );

  const handleCloseResultModal = useCallback(() => {
    dispatch(toggleStyleQuizResultModalAction(false));
    dispatch(setStyleQuizResultModalAction({}));
  }, [dispatch]);

  const handleViewResult = useCallback(
    (filters) => {
      handleApplyFilters(filters);
      handleCloseResultModal();
    },
    [handleApplyFilters, handleCloseResultModal]
  );

  useEffect(() => {
    if (currentUser && open && submitted && handleOpenQuizResultModal) {
      handleOpenQuizResultModal();
    }
    // eslint-disable-next-line
  }, [currentUser, handleOpenQuizResultModal]);

  const handleSaveQuizPreference = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`
      );
      return;
    }
    try {
      await dispatch(saveStyleQuizAsPreferencesAction(styleQuis.data));
      successToastr('Success', 'Your preferences successfully updated');
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [authenticated, history, pathname, dispatch, styleQuis.data]);

  const handleHowItWorksVideoModalOpen = () => {
    if (setModalVideo) setModalVideo({ video, poster: posterImg });
    dispatch(toggleHowItWorksVideoModalAction(true));
  };

  return (
    <>
      <BasicModal
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="lg"
        scroll="body"
        classes={classes}
        style={{ zIndex: 1298 }}
      >
        {activeTab.value !== filterTabKeys.vibes && !submitted && (
          <Button
            variant="default"
            inline
            size="sm"
            onClick={handleMoveToPrevStep}
            className={classes.backButton}
            color="default"
          >
            <SpriteIcon
              name="left-arrow"
              size="sm"
              className="mr-1 primary-color"
            />
            <span> Back</span>
          </Button>
        )}
        <h3
          id="title"
          className="text-lg text-uppercase font-title text-center primary-color"
        >
          Style Quiz
        </h3>
        <p className="text-center mb-4 text-sm font-italic color-gray-6">
          Get the Look You Love
        </p>

        {matchesMediaQuery && (
          <>
            <div className={clsx('mb-2', classes.activeStep)}>
              Step {activeTab.id + 1}: {activeTab.title}
            </div>
          </>
        )}

        <div
          className={clsx('d-flex align-items-center mb-4', {
            'justify-content-between': !matchesMediaQuery,
            'justify-content-center': matchesMediaQuery,
          })}
        >
          {matchesMediaQuery ? (
            <div className="d-flex">
              {Object.values(quizConfig).map(({ value, checked }, idx) => (
                <div
                  key={value}
                  className={clsx(classes.stepBtn, {
                    [classes.active]:
                      (!submitted && value === activeTab.value) || checked,
                  })}
                >
                  {!checked ? (
                    idx + 1
                  ) : (
                    <SpriteIcon name="check-icon" size="md" />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex">
              {Object.values(quizConfig).map(({ value, title, checked }) => (
                <div
                  key={value}
                  className={clsx(classes.stepBtn, {
                    [classes.active]: !submitted && value === activeTab.value,
                  })}
                >
                  {checked && (
                    <SpriteIcon name="checked" size="sm" className="mr-1" />
                  )}
                  {title}
                </div>
              ))}
            </div>
          )}
          {!matchesMediaQuery && (
            <Button
              inline
              className={classes.fixedBtn}
              onClick={handleHowItWorksVideoModalOpen}
            >
              How it Works
              <SpriteIcon name="play-icon" size="md" className="ml-2" />
            </Button>
          )}
        </div>
        {submitted && userPersona && (
          <div className={classes.userPersonaWrapper}>
            <h5>Your style persona</h5>
            <img src={userPersona} alt="userPersonas" />
          </div>
        )}
        {!submitted ? (
          <>
            <div className={`${classes.modalSubtitle} mb-1`}>
              {styleQuizSubtitles[activeTab.value]}
              {activeTab === quizConfig[filterTabKeys.subStyles] && (
                <div className={classes.likeIcon}>
                  <SpriteIcon name="like-filled" size="xs" />
                </div>
              )}
            </div>
            <p className={classes.mainDescription}>
              {activeTab.id !== 2
                ? styleQuizDescriptions[activeTab.value]
                : 'Click the “heart” on all the rooms you like'}
            </p>
          </>
        ) : (
          <p className={`${classes.modalSubtitle} text-center mb-1`}>
            Your style quiz summary
          </p>
        )}

        {matchesMediaQuery ? (
          <>
            <div
              className={clsx(classes.stickyButton, {
                'd-none': isSelectedStylesActive,
                'd-block': !isSelectedStylesActive,
              })}
            >
              <div
                className={clsx('mb-4', {
                  'justify-content-end': submitted,
                  'd-block': activeTab.id === 1,
                  'd-none': activeTab.id !== 1,
                })}
              >
                {!submitted && selectedOptions.length > 0 && (
                  <>
                    <div className={classes.stylesBtn} onClick={toggleModal}>
                      <div>Styles Selected ({selectedOptions.length})</div>
                      <SpriteIcon
                        name="arrow-down"
                        size="sm"
                        className="mx-1"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={clsx('mb-4 d-flex', {
                'justify-content-end': submitted,
              })}
            >
              {!submitted && (
                <div className="flex-fill d-flex flex-wrap mr-5">
                  {selectedOptions.map(({ id, name }) => (
                    <div className={classes.optionChip} key={id}>
                      <span className="text-sm font-italic mr-2">{name}</span>
                      <SpriteIcon
                        name="cross"
                        size="xs"
                        className="cursor-pointer"
                        data-id={id}
                        onClick={handleUnselectOption}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}

        {submitted ? (
          <SummaryPage
            currentValues={savedFilters}
            onApply={() => handleApplyFilters(savedFilters)}
          />
        ) : (
          <>
            <div
              className={clsx('mb-4 overflow-hidden', {
                [classes.addBottomSpaceMobile]: matchesMediaQuery,
              })}
            >
              {activeTab.value === filterTabKeys.vibes && (
                <VibesFilter
                  options={Object.values(enums[filterTabKeys.vibes])}
                  currentValue={currentFilters[filterTabKeys.vibes]}
                  onChange={handleChangeVibeFilter}
                  isPromptShow={promptShow}
                  promptText={activeTab.prompt}
                />
              )}
              {activeTab.value === filterTabKeys.styles && (
                <StylesFilter
                  vibes={currentFilters[filterTabKeys.vibes]}
                  options={styleOptions}
                  currentValue={currentFilters[filterTabKeys.styles]}
                  onChange={handleChangeStyleFilter}
                  isPromptShow={promptShow}
                  promptText={activeTab.prompt}
                  isQuiz
                />
              )}
              {activeTab.value === filterTabKeys.subStyles && (
                <SubStylesFilter
                  options={subStyleOptions}
                  currentValue={currentFilters[filterTabKeys.subStyles]}
                  onChange={handleChangeStyleFilter}
                  isPromptShow={promptShow}
                  promptText={activeTab.prompt}
                />
              )}
              {activeTab.value === filterTabKeys.roomTypes && (
                <RoomTypesFilter
                  options={roomTypeOptions}
                  currentValue={currentFilters[filterTabKeys.roomTypes]}
                  onChange={handleChangeStyleFilter}
                />
              )}
              {activeTab.value === filterTabKeys.colors && (
                <ColorSelect
                  colorList={Object.values(enums[filterTabKeys.colors])}
                  selectedColors={currentFilters[filterTabKeys.colors]}
                  onChange={handleChangeColorFilter}
                />
              )}
            </div>
            <div
              className={clsx('text-center', {
                [classes.nextBtnMobile]: matchesMediaQuery,
              })}
            >
              {activeTab.nextStep ? (
                <>
                  {!activeTab.prompt && (
                    <Button
                      inline
                      variant="outlined"
                      size="sm"
                      className="mr-2"
                      onClick={handleSkipStep}
                    >
                      Skip
                    </Button>
                  )}
                  <Button inline size="sm" onClick={handleMoveToNextStep}>
                    Next: {nextStepTitle}
                  </Button>
                </>
              ) : (
                <Button
                  inline
                  size="lg"
                  onClick={handleSubmitQuiz}
                  className={classes.submitBtn}
                >
                  <span className="text-color">Submit: </span>
                  &nbsp; Get Style Profile
                </Button>
              )}
            </div>
          </>
        )}

        <SelectedStyles
          open={isSelectedStylesActive}
          setOpen={setIsSelectedStylesActive}
          selectedOptions={selectedOptions}
          handleUnselectOption={handleUnselectOption}
          clearSelectedOptions={clearSelectedOptions}
          toggleModal={toggleModal}
        />
      </BasicModal>

      {styleQuis.isShown && (
        <BasicModal
          open={styleQuis.isShown && Object.keys(styleQuis.data).length}
          onClose={handleCloseResultModal}
          fullWidth
          maxWidth="lg"
          scroll="body"
          classes={classes}
        >
          {activeTab.value !== filterTabKeys.styles && (
            <Button
              variant="default"
              inline
              size="sm"
              onClick={handleMoveToPrevStep}
              className={classes.backButton}
              color="default"
            >
              <SpriteIcon
                name="left-arrow"
                size="sm"
                className="mr-1 primary-color"
              />
              <span> Back</span>
            </Button>
          )}
          <h3 className="text-lg text-uppercase font-title text-center primary-color">
            Style Quiz
          </h3>
          <p className="text-center mb-4 text-sm font-italic color-gray-6">
            Get the Look You Love
          </p>

          <div className="d-flex mb-4">
            {Object.values(quizConfig).map(({ value, title, checked }) => (
              <div
                key={value}
                className={clsx(classes.stepBtn, {
                  [classes.active]: !submitted && value === activeTab.value,
                })}
              >
                {checked && (
                  <SpriteIcon name="checked" size="sm" className="mr-1" />
                )}
                {title}
              </div>
            ))}
          </div>
          {!submitted ? (
            <>
              <div className={`${classes.modalSubtitle} mb-1`}>
                {styleQuizSubtitles[activeTab.value]}
                {activeTab === quizConfig[filterTabKeys.subStyles] && (
                  <div className={classes.likeIcon}>
                    <SpriteIcon name="like-filled" size="xs" />
                  </div>
                )}
              </div>
              <p className="text-sm font-italic mb-3">
                {styleQuizDescriptions[activeTab.value]}
              </p>
            </>
          ) : (
            <p className={`${classes.modalSubtitle} text-center mb-1`}>
              Your style quiz summary
            </p>
          )}
          <div
            className={clsx('d-flex mb-4', {
              'justify-content-end': submitted,
            })}
          >
            {!submitted && (
              <div
                className="flex-fill d-flex flex-wrap mr-5"
                style={{ minHeight: 40 }}
              >
                {selectedOptions.map(({ id, name }) => (
                  <div className={classes.optionChip} key={id}>
                    <span className="text-sm font-italic mr-2">{name}</span>
                    <SpriteIcon
                      name="cross"
                      size="xs"
                      className="cursor-pointer"
                      data-id={id}
                      onClick={handleUnselectOption}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={classes.resetArea}>
              <Button
                variant="outlined"
                size="sm"
                onClick={handleSaveQuizPreference}
              >
                Save as Preferences
              </Button>
            </div>
          </div>
          <SummaryPage
            currentValues={styleQuis.data}
            onApply={() => handleViewResult(styleQuis.data)}
          />
        </BasicModal>
      )}
    </>
  );
};

StyleQuizModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  handleOpenQuizResultModal: PropTypes.func,
  setModalVideo: PropTypes.func,
};

StyleQuizModal.defaultProps = {
  handleOpenQuizResultModal: null,
  setModalVideo: null,
};
export default StyleQuizModal;
