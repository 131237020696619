import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import {
  getTheLookTabKeys,
  getTheLookTabsConfig,
  productsSortOptions,
} from 'modules/getTheLook/constants';
import {
  changeActiveTabAction,
  updateMixMatchSortAction,
  updateMixMatchSubtypeAction,
  updateMixMatchTypeAction,
  updateSelectedImageAction,
} from 'modules/getTheLook/store/actions';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import TypesInfoModal from 'components/modals/TypesInfoModal/TypesInfoModal';
import ButtonHowItWorks from 'components/ui/ButtonHowItWorks';
import customEvent from 'utils/customEvent';
import { ADD_PREFERENCES } from 'constants/customEventNames';
import Button from 'components/ui/Button/Button';
import findObjectById from 'utils/findObjectById';
import { Radio, RadioGroup, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classes from './LookBoardsHeader.module.scss';
import { getTheLookAmbassadorsTabKeys } from '../../ambassadorPage/config';
import MixMatchModal from '../MixMatchModal/MixMatchModal';
import FilterInput from '../../../dashboard/components/FilterInput/FilterInput';

const LookBoardsHeader = ({
  onOpenMixMatchModal,
  onOpenLookBoardViewPage,
  toggleLikedImages,
  onModalOpen,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const {
    activeTab,
    selectedImage,
    mixMatchType,
    mixMatchSort,
    mixMatchSubtype,
  } = useSelector((state) => state.getTheLook);
  const styles = useSelector((state) => state.getTheLook.filterValues.styles);
  const ambassadorsActiveTab = useSelector(
    (state) => state.ambassadorsPage.activeTab
  );
  const currentUser = useSelector((state) => state.auth.user);
  const stylesConfig = useSelector((state) => state.app.enums.styles);
  const { selectedUser } = useSelector((state) => state.userPage);
  const { selectedAmbassador } = useSelector((state) => state.ambassadorsPage);

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [productSort, setProductSort] = useState(mixMatchSort);

  const handleMatchModalModalOpen = useCallback(
    () => history.push(`${pathname}?info=mix-match`),
    [history, pathname]
  );
  const handleMatchModalModalClose = useCallback(() => history.push(pathname), [
    history,
    pathname,
  ]);

  const mixMatchModalOpen = useMemo(() => search.includes('info=mix-match'), [
    search,
  ]);

  const handleChangeActiveTab = useCallback(
    ({ currentTarget }) => {
      if (!currentUser) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
        return;
      }
      const { tab } = currentTarget.dataset;
      if (tab === getTheLookTabKeys.mixAndMatch) {
        if (
          pathname === routesByName.getTheLook.index ||
          pathname === routesByName.getTheLook.lookBoardView.index
        ) {
          dispatch(changeActiveTabAction(tab));
          onOpenMixMatchModal({});
          return;
        }
        if (selectedImage) {
          dispatch(changeActiveTabAction(tab));
          onOpenMixMatchModal({ id: selectedImage.id });
          return;
        }
      } else if (tab === getTheLookTabKeys.lookBoardView) {
        dispatch(changeActiveTabAction(tab));
        onOpenLookBoardViewPage();
        dispatch(updateSelectedImageAction(null));
      } else if (tab === getTheLookTabKeys.galleryView) {
        history.push(routesByName.getTheLook.index);
        dispatch(updateSelectedImageAction(null));
      } else if (tab === getTheLookTabKeys.showcaseView) {
        dispatch(changeActiveTabAction(getTheLookTabKeys.mixAndMatch));
        history.push(routesByName.getTheLook.ambassadors.index);
        dispatch(changeActiveTabAction(getTheLookTabKeys.mixAndMatch));
      } else {
        const newPath = selectedImage
          ? `${routesByName.getTheLook.index}/${selectedImage.id}`
          : routesByName.getTheLook.index;
        history.push(newPath);
      }
      dispatch(changeActiveTabAction(tab));
    },
    [
      currentUser,
      dispatch,
      history,
      onOpenLookBoardViewPage,
      onOpenMixMatchModal,
      pathname,
      selectedImage,
    ]
  );

  const isAmbassadorPage = useMemo(
    () =>
      pathname === routesByName.getTheLook.ambassadors.index ||
      pathname === routesByName.getTheLook.followedAmbassadors.index,
    [pathname]
  );

  const isAmbassadorShowcasePage = useMemo(() => {
    const id = pathname.split('/').pop();
    return routesByName.getTheLook.ambassadors.details(id) === pathname;
  }, [pathname]);

  const isMixMatchPage = useMemo(
    () =>
      history.location.pathname.includes(
        routesByName.getTheLook.mixMatch.index
      ),
    [history.location.pathname]
  );

  const isLookBoardViewPage = useMemo(
    () =>
      history.location.pathname.includes(
        routesByName.getTheLook.lookBoardView.index
      ),
    [history.location.pathname]
  );

  const isUsersPage = useMemo(
    () => pathname.startsWith(routesByName.getTheLook.users.index),
    [pathname]
  );

  const handleOpenInfoModal = useCallback(() => {
    setInfoModalOpen(true);
  }, []);

  const handleCloseInfoModal = useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const handleGoToShowcase = useCallback(() => {
    window.open(
      routesByName.showcase.details(selectedAmbassador.urlKey),
      '_blank'
    );
  }, [selectedAmbassador]);

  const handleMixMatchTypeChange = useCallback(
    ({ target }) => {
      const { value } = target;
      dispatch(updateMixMatchTypeAction(value));
    },
    [dispatch]
  );

  useEffect(() => {
    setProductSort(mixMatchSort);
  }, [mixMatchSort]);

  const handleMixMatchSortChange = useCallback(
    (sortType) => {
      setProductSort(sortType);
      dispatch(updateMixMatchSortAction(sortType));
    },
    [dispatch]
  );

  const handleMixMatchSubTypeChange = useCallback(
    ({ target }) => {
      const { value } = target;
      dispatch(updateMixMatchSubtypeAction(value));
    },
    [dispatch]
  );

  const isTabActive = useCallback(
    (tab) => {
      if (
        activeTab === getTheLookTabKeys.galleryView &&
        selectedImage &&
        tab === getTheLookTabKeys.lookBoardView
      )
        return true;
      if (activeTab === getTheLookTabKeys.galleryView && selectedImage)
        return false;
      return activeTab === tab;
    },
    [selectedImage, activeTab]
  );

  const handleGoToPreferences = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    history.push(routesByName.account.profile);
    setTimeout(() => {
      customEvent.trigger(ADD_PREFERENCES);
    }, 0);
  }, [currentUser, pathname, history]);

  const pageTitle = useMemo(() => {
    if (isAmbassadorPage) return 'Ambassador Look Boards';
    if (isUsersPage && selectedUser)
      return `${
        selectedUser?.id === currentUser?.id ? 'My' : selectedUser?.firstName
      } Look Boards`;
    if (isMixMatchPage) return 'Mix & Match';
    if (isLookBoardViewPage) return 'Look Board View';
    return 'Beautiful Inspirations';
  }, [
    isAmbassadorPage,
    isUsersPage,
    selectedUser,
    currentUser,
    isMixMatchPage,
    isLookBoardViewPage,
  ]);

  useEffect(
    () => () => {
      if (history.location.pathname.includes(routesByName.product.index))
        return;
      dispatch(updateSelectedImageAction(null));
      dispatch(changeActiveTabAction(getTheLookTabKeys.galleryView));
    },
    // eslint-disable-next-line
    []
  );
  useEffect(() => {
    if (isMixMatchPage)
      dispatch(changeActiveTabAction(getTheLookTabKeys.mixAndMatch));
    else if (isLookBoardViewPage)
      dispatch(changeActiveTabAction(getTheLookTabKeys.lookBoardView));
    // eslint-disable-next-line
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    toggleLikedImages((prevState) => !prevState);
    setIsChecked(!isChecked);
  };

  if (isAmbassadorShowcasePage) return <></>;

  return (
    <div className={clsx('pt-2', classes.root)}>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h3 className={`${classes.pageTitle} mb-3 `}>{pageTitle}</h3>
          {isAmbassadorPage && (
            <p className="font-italic text-gray mb-3 text-sm">
              {ambassadorsActiveTab === getTheLookAmbassadorsTabKeys.main &&
                `Click on the Ambassador Profile to View All of their Look Boards below or Click “Go to Showcase” to view their Showcase page in a new browser`}
              {ambassadorsActiveTab === getTheLookAmbassadorsTabKeys.showcase &&
                'Showcase Page'}
              {ambassadorsActiveTab ===
                getTheLookAmbassadorsTabKeys.lookBoards &&
                'Modern Designs Look Board'}
            </p>
          )}
        </div>
        <ButtonHowItWorks text="Get The Look" onClick={onModalOpen} />
      </div>

      {isAmbassadorPage &&
      ambassadorsActiveTab === getTheLookAmbassadorsTabKeys.lookBoards ? (
        <div>
          <Button
            variant="default"
            inline
            size="sm"
            color="primary"
            style={{ padding: 0 }}
            onClick={handleGoToShowcase}
          >
            <span className="font-italic">View showcase</span>
            <SpriteIcon
              name="right-arrow"
              size="sm"
              className="ml-2 primary-color"
            />
          </Button>
        </div>
      ) : null}

      <div
        className={clsx(
          'd-flex justify-content-between align-items-start position-relative mb-5',
          classes.bottomContainer
        )}
      >
        {!isAmbassadorPage && !isUsersPage && (
          <>
            <div className={classes.chosenStyles}>
              <div className="d-flex align-items-center mb-1">
                <h3 className="m-0">Selected styles({styles.length})</h3>
                {!currentUser?.preferences && (
                  <Button
                    size="sm"
                    className="w-auto ml-2"
                    onClick={handleGoToPreferences}
                  >
                    Set Preferences
                  </Button>
                )}
              </div>
              <div className={classes.stylesContainer}>
                {styles.map((styleId) => (
                  <div key={styleId} className={classes.styleItem}>
                    {findObjectById(styleId, stylesConfig)?.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex align-items-end">
                <div className={classes.titleWrapper}>
                  <IconButton
                    onClick={handleOpenInfoModal}
                    variant="inverted-white"
                    className="mr-1"
                  >
                    <SpriteIcon name="info" />
                  </IconButton>
                </div>
                <div
                  className="d-flex align-items-start position-relative"
                  style={{ zIndex: 1 }}
                >
                  {getTheLookTabsConfig.map(({ value, title, icon }) => (
                    <div
                      key={value}
                      className={clsx(
                        'd-flex flex-column align-items-center',
                        classes.tabBtnWrapper
                      )}
                    >
                      <button
                        key={value}
                        type="button"
                        className={clsx(classes.tabBtn, {
                          [classes.active]: isTabActive(value),
                        })}
                        data-tab={value}
                        onClick={handleChangeActiveTab}
                      >
                        <SpriteIcon name={icon} size="sm" className="mr-1" />
                        {title}
                      </button>
                      {value === 'mixAndMatch' && (
                        <span
                          className={clsx(
                            classes.tabSubtitle,
                            'position-absolute'
                          )}
                        >
                          Try It!
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {currentUser && pathname === routesByName.getTheLook.index && (
                <div className={classes.likedLooksButtonWrapper}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={isChecked}
                        onClick={handleToggle}
                        color="primary"
                      />
                    }
                    label={
                      <div className="d-flex primary-color">
                        <Typography color="primary">My Liked Looks</Typography>
                        <SpriteIcon name="like-filled" className="ml-1" />
                      </div>
                    }
                  />
                </div>
              )}
            </div>
            <div className={classes.mixMatchTabs}>
              {isMixMatchPage && (
                <>
                  <div>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="curated"
                      name="radio-buttons-group"
                      className="flex-row align-items-center"
                      onChange={handleMixMatchTypeChange}
                      value={mixMatchType}
                    >
                      <FormControlLabel
                        value="curated"
                        control={<Radio />}
                        label="Curated Look Board Items"
                        className="primary"
                      />
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All Similar Items"
                        className="primary"
                      />
                    </RadioGroup>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="up24"
                      name="radio-buttons-group-1"
                      className="flex-row align-items-center"
                      onChange={handleMixMatchSubTypeChange}
                      value={mixMatchSubtype}
                    >
                      <FormControlLabel
                        value="up24"
                        control={<Radio />}
                        label="Up to 24 Items"
                        className="primary"
                      />
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All Items"
                        className="primary"
                      />
                    </RadioGroup>
                  </div>
                  <FilterInput
                    additionalLabel="Sort by:"
                    className={classes.sortTypeSelect}
                    options={productsSortOptions}
                    currentValue={productSort}
                    onChange={handleMixMatchSortChange}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      {isMixMatchPage && (
        <div
          className={clsx('d-flex position-absolute', classes.buttonWrapper)}
        >
          <Button
            inline
            className={classes.fixedBtn}
            onClick={handleMatchModalModalOpen}
          >
            How it Works
            <SpriteIcon name="play-icon" size="md" className="ml-2" />
          </Button>
        </div>
      )}
      <TypesInfoModal open={infoModalOpen} onClose={handleCloseInfoModal} />
      <MixMatchModal
        visible={mixMatchModalOpen}
        onClose={handleMatchModalModalClose}
      />
    </div>
  );
};

LookBoardsHeader.propTypes = {
  onOpenMixMatchModal: PropTypes.func.isRequired,
  onOpenLookBoardViewPage: PropTypes.func.isRequired,
  toggleLikedImages: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
};

export default LookBoardsHeader;
