import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import classes from './AmbassadorManagement.module.scss';
import { videoInfoData } from './constants';

const AmbassadorVideos = () => {
  const query = useSelector(({ router }) => router.location.query);
  const videoUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.videos.standart
  );
  const videosData = useSelector((state) => state.app.enums.videos);
  const dispatch = useDispatch();

  const [mainVideoInfo, setMainVideoInfo] = useState(null);
  const [mainVideo, setMainVideo] = useState(null);

  const videoRef = useRef();
  const handlePause = useCallback((e) => e.target.blur(), []);

  const handleSelectVideo = useCallback(
    (videoData) => {
      const { poster, videoId } = videoData;
      if (!videosData) return;
      const selectedVideo = {
        video: buildResourceUrl(
          videoUrlPlaceholder,
          null,
          null,
          videosData[videoId]
        ),
        poster,
      };
      setMainVideo(selectedVideo);
      setMainVideoInfo(videoData);
      window.scrollTo({ top: 150, behavior: 'smooth' });
    },
    [videoUrlPlaceholder, videosData]
  );

  const isSelectedVideo = useCallback(
    (id) => {
      return mainVideoInfo?.videoId === id;
    },
    [mainVideoInfo]
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    let targetVideo;
    if (Object.keys(query).length && query.videoUrl) {
      // targetVideo = videoInfoData[videoSteps[query.videoUrl]]
      targetVideo = videoInfoData.find(
        (obj) => obj.videoId.toString() === query.videoUrl
      );
      // if (!neededVideo) return;
    } else {
      [targetVideo] = videoInfoData;
    }
    const selectedVideo = {
      video: buildResourceUrl(
        videoUrlPlaceholder,
        null,
        null,
        videosData[targetVideo?.videoId] ?? null
      ),
      poster: targetVideo?.poster,
    };
    setMainVideo(selectedVideo);
    setMainVideoInfo(targetVideo);
  }, [dispatch, query, videoUrlPlaceholder, videosData]);

  return (
    <>
      <section className={classes.root}>
        <div className={classes.banner}>
          <div className="d-flex align-items-center">
            <h2 className="ml-1 text-md text-uppercase font-semi-bold">
              Ambassador onboarding videos
            </h2>
          </div>
        </div>
        <div className={clsx(classes.mainWrapper, classes.mainVideoWrapper)}>
          <div className={classes.mainVideoInfo}>
            {mainVideo && (
              <div className={classes.mainVideo}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoRef}
                  src={mainVideo.video}
                  preload="metadata"
                  onPause={handlePause}
                  controls
                  className={classes.video}
                  poster={mainVideo.poster}
                />
              </div>
            )}
            {mainVideoInfo && (
              <div className={classes.videoInfoWrapper}>
                <h3 className={classes.videoInfoWrapper__title}>
                  {mainVideoInfo.title}
                </h3>
                <p className="font-italic mb-2 fw-300">
                  {mainVideoInfo.textItalic}
                </p>
                <p className={classes.videoInfoWrapper__textMain}>
                  {mainVideoInfo.textMain}
                </p>
                <p className="mb-2">{mainVideoInfo.textList}</p>
                <ol>
                  {mainVideoInfo.list.map(({ label, text }) => {
                    return (
                      <li className={classes.listOption}>
                        <span className="fw-600">{label}</span> {text}
                      </li>
                    );
                  })}
                </ol>
                <p className={classes.videoInfoWrapper__endText}>
                  {mainVideoInfo.endText}
                </p>
              </div>
            )}
          </div>
          <div className={classes.videosList}>
            {videoInfoData.map((videoData) => (
              <div
                className={clsx(classes.videoItem)}
                onClick={() => handleSelectVideo(videoData)}
                key={videoData.videoId}
              >
                {' '}
                {/* classes.selected */}
                <img
                  className={
                    isSelectedVideo(videoData.videoId) && classes.selected
                  }
                  src={videoData.poster}
                  alt="posterImg1"
                />
                <p className="font-italic fw-300 text-center mt-2">
                  {videoData.textItalic}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AmbassadorVideos;
