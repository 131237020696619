import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getProductImgUrl } from 'modules/app/store/selectors';
import { useSelector } from 'react-redux';
import { productShape } from 'modules/product/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import MixMatchThumbnail from 'components/MixMatchThumbnail/MixMatchThumbnail';

const MixAndMatchItem = ({
  index,
  title,
  products,
  onChangeProduct,
  onRateProduct,
  onSlideEndProduct,
}) => {
  const productImgUrl = getProductImgUrl(
    useSelector((state) => state.app.config)
  );

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const transformedProducts = products.map(
      ({
        id,
        media: { userId, hash },
        averageRating,
        itemClassId,
        price,
        isAffiliate,
        url,
      }) => ({
        id,
        url: buildResourceUrl(productImgUrl.medium, userId, hash),
        averageRating,
        itemClassId,
        price,
        isAffiliate,
        externalUrl: url,
      })
    );
    setProductList(transformedProducts);
    // eslint-disable-next-line
  }, [products]);

  const handleChangeProduct = useCallback(
    (productId) => {
      onChangeProduct(index, productId);
    },
    [onChangeProduct, index]
  );

  return (
    <MixMatchThumbnail
      title={title}
      imgUrls={productList}
      onChangeProduct={handleChangeProduct}
      onRateProduct={onRateProduct}
      onSlideEndProduct={onSlideEndProduct}
    />
  );
};

MixAndMatchItem.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  products: PropTypes.arrayOf(productShape).isRequired,
  onChangeProduct: PropTypes.func.isRequired,
  onRateProduct: PropTypes.func,
  onSlideEndProduct: PropTypes.func,
};

MixAndMatchItem.defaultProps = {
  onRateProduct: undefined,
  onSlideEndProduct: undefined,
  index: 0,
  title: '',
};

export default MixAndMatchItem;
