import {
  APP_INITED,
  APP_SET_CHECKSUM,
  APP_SET_CONFIG,
  APP_UPDATE_ENUMS,
} from 'modules/app/store/constants';
import { initAuthAction } from 'modules/auth/store/actions';
import appConfig from 'constants/appConfig';
import appService from 'modules/app/appService';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import { localStorageKeys } from 'modules/app/constants';
import { setUpAuthInterceptorsAction } from 'libs/axios/AuthorizedAxiosInstance';
import { populateOwnBannersFromStorageAction } from 'modules/showcase/store/actions';
import moment from 'moment';

export const setConfigAction = (payload) => ({
  type: APP_SET_CONFIG,
  payload,
});

export const setChecksumAction = (payload) => ({
  type: APP_SET_CHECKSUM,
  payload,
});

export const updateEnumsAction = (payload) => ({
  type: APP_UPDATE_ENUMS,
  payload,
});

const getNewChecksum = async (checksumUrl) => {
  const updatedChecksumUrl = `${checksumUrl}?v=${moment().unix()}`;
  const newChecksum = await appService.getAppResource(updatedChecksumUrl);
  localStorage.setItem(localStorageKeys.checksum, JSON.stringify(newChecksum));
  return newChecksum;
};

const updateEnums = async (currentChecksum, newChecksum, enumUrlsObj) => {
  const enumsToUpdate = Object.keys(newChecksum).filter(
    (currentEnum) =>
      newChecksum[currentEnum] !== currentChecksum[currentEnum] &&
      currentEnum !== 'application'
  );

  const promiseArr = enumsToUpdate.map(async (currentEnum) => {
    const urlsObj = enumUrlsObj[currentEnum]
      ? enumUrlsObj
      : (await appService.getConfig()).endpoints.configs;
    return appService.getAppResource(
      `${urlsObj[currentEnum]}?v=${newChecksum[currentEnum]}`
    );
  });

  const enumsValues = await Promise.all(promiseArr);

  const updatedEnums = enumsToUpdate.reduce((accum, enumKey, index) => {
    /* eslint-disable no-param-reassign */
    accum[enumKey] = enumsValues[index].reduce((acc, currValue, innerIndex) => {
      //  Hide category and item type with label "None"
      if (
        // (currValue.id === 6 || currValue.id === 17) &&
        currValue.name === 'NONE'
      ) {
        return acc;
      }
      if (enumKey === filterTabKeys.styles) {
        acc[innerIndex + 1] = currValue;
      } else if (enumKey === filterTabKeys.videos) {
        acc[innerIndex] = currValue.name;
      } else if (enumKey === filterTabKeys.metaTags) {
        acc[currValue.name] = currValue;
      } else {
        acc[currValue.id] = currValue;
      }
      return acc;
    }, {});

    return accum;
  }, {});
  const enums = JSON.parse(localStorage.getItem(localStorageKeys.enums));
  const newEnums = { ...enums, ...updatedEnums };

  if (Object.keys(newEnums).length) {
    localStorage.setItem(localStorageKeys.enums, JSON.stringify(newEnums));
  }

  return updatedEnums;
};

export const initAppAction = () => async (dispatch) => {
  dispatch(setUpAuthInterceptorsAction());
  dispatch(populateOwnBannersFromStorageAction());

  const savedAppVersion = appService.getSavedAppVersion();

  if (appConfig.appVersion !== savedAppVersion) {
    localStorage.removeItem(localStorageKeys.applicationConfig);
    appService.saveAppVersion(appConfig.appVersion);
  }

  let config = JSON.parse(
    localStorage.getItem(localStorageKeys.applicationConfig)
  );

  if (!config) {
    config = await appService.getConfig();

    const {
      endpoints: { configs },
    } = config;

    const newChecksum = await getNewChecksum(configs.checksum);

    await updateEnums({}, newChecksum, configs);
  }

  dispatch(setConfigAction(config));

  const {
    endpoints: { configs },
  } = config;

  let checksum = JSON.parse(localStorage.getItem(localStorageKeys.checksum));

  if (!checksum) {
    checksum = await getNewChecksum(configs.checksum);
    await updateEnums({}, checksum, configs);
  } else {
    const newChecksum = await getNewChecksum(configs.checksum);
    await updateEnums(checksum, newChecksum, configs);
  }

  dispatch(setChecksumAction(checksum));

  let enums = JSON.parse(localStorage.getItem(localStorageKeys.enums));

  if (!enums) {
    enums = await updateEnums({}, checksum, configs);
  }

  dispatch(updateEnumsAction(enums));

  await dispatch(initAuthAction());
  dispatch({ type: APP_INITED });
};
