import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Banner from 'modules/home/components/Banner/Banner';
import GetTheLookYouLove from 'modules/home/components/GetTheLookYouLove/GetTheLookYouLove';
import HowItWorks from 'modules/home/components/HowItWorks/HowItWorks';
import Ambassadors from 'modules/home/components/Ambassadors/Ambassadors';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import MobileHomePage from 'modules/home/components/MobileHomePage/MobileHomePage';
import { useHistory } from 'react-router-dom';
import { routesByName } from '../../constants/routes';
import MetaTags from '../../components/MetaTags';
import { metaNames } from '../../components/MetaTags/config';

const HomeContainer = () => {
  const history = useHistory();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const metaTags = useSelector((state) => state.app.enums.metaTags);

  useEffect(() => {
    if (localStorage.getItem('isRedirect')) {
      return;
    }
    localStorage.setItem('isRedirect', 'redirected');
    history.push(`${routesByName.getTheLook.index}`);
  }, [history]);

  return (
    <>
      {metaTags && metaTags[metaNames.homepage] && (
        <MetaTags metaData={metaTags[metaNames.homepage]} />
      )}
      {matchesMediaQuery ? (
        <MobileHomePage />
      ) : (
        <>
          <Banner />
          <GetTheLookYouLove />
          <HowItWorks />
          <Ambassadors />
        </>
      )}
    </>
  );
};

export default HomeContainer;
