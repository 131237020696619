import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GTLSlider from 'modules/getTheLook/components/GTLSlider';
import useCallbackRef from 'hooks/useCallbackRef';
import { productShape } from 'modules/product/propTypes';
import ProductItemDetails from 'modules/product/ProductItemDetails/ProductItemDetails';
import productService from 'modules/product/productService';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { useSelector } from 'react-redux';

const SimilarGroupSlider = ({ products }) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));

  const history = useHistory();
  const { pathname } = useLocation();
  const [productsGroup, setProductsGroup] = useState(products);
  const [sliderNode, sliderRef] = useCallbackRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  const handleToggleLikeProduct = useCallback(
    async (productId, isLiked) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      await productService.toggleLike(productId, isLiked);
      const updatedProductsGroup = productsGroup.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            isLiked,
          };
        }
        return product;
      });
      setProductsGroup(updatedProductsGroup);
    },
    [authenticated, productsGroup, history, pathname]
  );

  const handleClickProduct = useCallback(
    (productId, slug) => {
      history.push(routesByName.product.details(productId, slug));
    },
    [history]
  );

  useEffect(() => {
    setProductsGroup(products);
  }, [products]);

  return (
    <GTLSlider
      sliderRef={sliderRef}
      slideLength={productsGroup.length}
      slidesToShow={4}
      activeSlideIndex={activeSlideIndex}
      onSlidePrev={handleSlidePrev}
      onSlideNext={handleSlideNext}
      onSlideChange={handleSlideChange}
    >
      {productsGroup.map((product) => (
        <div key={product.id} className="px-1 h-100">
          <ProductItemDetails
            product={product}
            onToggleLike={handleToggleLikeProduct}
            onClick={handleClickProduct}
          />
        </div>
      ))}
    </GTLSlider>
  );
};

SimilarGroupSlider.propTypes = {
  products: PropTypes.arrayOf(productShape).isRequired,
};

export default SimilarGroupSlider;
