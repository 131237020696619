import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import ItemThumbnail from 'components/lookBoardThumbnails/ItemThumbnail/ItemThumbnail';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import InActiveOverlay from 'modules/dashboard/myImages/components/AddedImageItem/InActiveOverlay';
import classes from './MixItem.module.scss';

const MixItem = ({
  expanded,
  item: { id, inspirationImageId, products, isActive, slug },
  onOpenPreview,
  onEditMix,
  onDeleteMix,
}) => {
  const productsLibrary = useSelector((state) => state.product.library);
  const history = useHistory();
  const containerRef = useRef(null);
  const [imgHeight, setImgHeight] = useState('50%');
  const [containerHeight, setContainerHeight] = useState(null);
  const [productList, setProductList] = useState([]);

  const hiddenItemsCount = useMemo(() => products.length - productList.length, [
    productList.length,
    products.length,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      const refWidth = containerRef.current.clientWidth;
      const itemHeight =
        products.length > 1 ? Math.round(refWidth / 2) : refWidth;

      setContainerHeight(refWidth);
      setImgHeight(itemHeight);
    }
  }, [products.length]);

  useEffect(() => {
    const preparedList = expanded ? products : products.slice(0, 4);
    const list = preparedList.map((productId) => productsLibrary[productId]);
    switch (list.length) {
      case 2: {
        list.splice(1, 0, { id: uuidv4() }, { id: uuidv4() });
        break;
      }
      case 3: {
        list.push({ id: uuidv4() });
        break;
      }
      default: {
        break;
      }
    }
    setProductList(list);
  }, [expanded, products, productsLibrary]);

  const handleClickProduct = useCallback(
    (productId, productSlug) => {
      history.push(routesByName.product.details(productId, productSlug));
    },
    [history]
  );

  const handleEditMix = useCallback(() => {
    onEditMix(inspirationImageId, slug);
  }, [onEditMix, inspirationImageId, slug]);

  const handleDeleteMix = useCallback(() => {
    onDeleteMix(id);
  }, [onDeleteMix, id]);

  return (
    <div ref={containerRef} className={classes.root}>
      <div className={classes.title}>Look Board</div>
      <div
        style={{ minHeight: containerHeight }}
        className={clsx(classes.imgContainer)}
      >
        {productList.map(
          ({ id: productId, media, itemClassId, slug: productSlug }) =>
            media ? (
              <div
                key={productId}
                className={clsx(
                  'cursor-pointer',
                  products.length > 1 ? classes.double : classes.single
                )}
                onClick={() => {
                  handleClickProduct(productId, productSlug);
                }}
              >
                <ItemThumbnail
                  id={productId}
                  src={media}
                  slug={productSlug}
                  height={imgHeight}
                  itemClassId={itemClassId}
                />
              </div>
            ) : (
              <div
                key={productId}
                className={clsx(classes.placeholderWrapper, classes.double)}
                style={{ height: imgHeight }}
              >
                <div className={classes.placeholder}>
                  <SpriteIcon name="logo" className={classes.placeholderIcon} />
                </div>
              </div>
            )
        )}
      </div>
      <div className={classes.bottomControls}>
        {!expanded && hiddenItemsCount > 0 && (
          <IconButton
            variant="inverted-grey"
            color="secondary"
            size="sm"
            className="mr-1"
            data-id={id}
            onClick={onOpenPreview}
            disabled={!isActive}
          >
            +{hiddenItemsCount}
          </IconButton>
        )}
        {!expanded && (
          <>
            <IconButton
              variant="inverted-grey"
              color="secondary"
              size="sm"
              className="mr-1"
              onClick={handleEditMix}
              disabled={!isActive}
            >
              <SpriteIcon name="edit" size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-grey"
              color="secondary"
              size="sm"
              className="mr-1"
              onClick={handleDeleteMix}
              disabled={!isActive}
            >
              <SpriteIcon name="trash" size="sm" />
            </IconButton>
          </>
        )}
      </div>
      {!isActive && (
        <div className={classes.staticOverlay}>
          <InActiveOverlay />
        </div>
      )}
    </div>
  );
};

MixItem.propTypes = {
  expanded: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    inspirationImageId: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  onOpenPreview: PropTypes.func,
  onEditMix: PropTypes.func,
  onDeleteMix: PropTypes.func,
};

MixItem.defaultProps = {
  expanded: false,
  onOpenPreview: undefined,
  onEditMix: () => {},
  onDeleteMix: () => {},
};

export default MixItem;
