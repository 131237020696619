import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { routesByName } from 'constants/routes';
import ImageSlideItem from 'components/MixMatchThumbnail/ImageSlideItem';
import SliderControls from 'components/MixMatchThumbnail/SliderControls';
import classes from './MixMatchThumbnail.module.scss';

const MixMatchThumbnail = ({
  title,
  imgUrls,
  onRateProduct,
  onChangeProduct,
  onSlideEndProduct,
}) => {
  const history = useHistory();
  const sliderRef = useRef(null);
  const { mixMatchSort } = useSelector((state) => state.getTheLook);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback(
    (index) => {
      setActiveSlideIndex(index);
      if (onChangeProduct) {
        onChangeProduct(imgUrls[index].id);
      }
      if (onSlideEndProduct && index === imgUrls.length - 2) {
        onSlideEndProduct(imgUrls[index].itemClassId);
      }
    },
    [imgUrls, onChangeProduct, onSlideEndProduct]
  );

  useEffect(() => {
    sliderRef.current.slickGoTo(0);
    setActiveSlideIndex(0);
  }, [mixMatchSort]);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleDotClick = useCallback(({ currentTarget }) => {
    const currentSlideIndex = currentTarget.getAttribute('data-index');
    sliderRef.current.slickGoTo(currentSlideIndex);
  }, []);

  const handleClickProduct = useCallback(
    ({ currentTarget }, isAffiliate, url, slug) => {
      const { id } = currentTarget.dataset;
      if (isAffiliate && onChangeProduct) {
        history.push(routesByName.product.details(id, slug));
      } else if (url) {
        window.open(url, '_blank');
      }
    },
    [onChangeProduct, history]
  );

  return (
    <div className={classes.root}>
      <p className={clsx('text-lg font-semi-bold', classes.title)}>{title}</p>
      <div className="flex-fill d-flex flex-column justify-content-center mb-1">
        <Slider
          ref={sliderRef}
          arrows={false}
          afterChange={handleSlideChange}
          infinite={false}
        >
          {imgUrls.map(
            ({
              id,
              url,
              averageRating,
              price,
              isAffiliate,
              externalUrl,
              slug,
            }) => (
              <ImageSlideItem
                key={id}
                id={id}
                url={url}
                averageRating={averageRating}
                onRate={onRateProduct}
                onClick={(event) =>
                  handleClickProduct(event, isAffiliate, externalUrl, slug)
                }
                price={price}
              />
            )
          )}
        </Slider>
      </div>
      <SliderControls
        items={imgUrls}
        activeSlideIndex={activeSlideIndex}
        onDotClick={handleDotClick}
        onSlidePrev={handleSlidePrev}
        onSlideNext={handleSlideNext}
      />
    </div>
  );
};

MixMatchThumbnail.propTypes = {
  title: PropTypes.string.isRequired,
  imgUrls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      itemClassId: PropTypes.number,
    })
  ).isRequired,
  onRateProduct: PropTypes.func,
  onChangeProduct: PropTypes.func,
  onSlideEndProduct: PropTypes.func,
};

MixMatchThumbnail.defaultProps = {
  onRateProduct: undefined,
  onChangeProduct: undefined,
  onSlideEndProduct: undefined,
};

export default MixMatchThumbnail;
