import React from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

const MetaTags = ({ metaData }) => {
    const { title, description, keywords } = metaData;

    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>
    );
};

MetaTags.propTypes = {
    metaData: {
        title: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
    }
}

MetaTags.defaultProps = {
    metaData: {
        title: null,
        description: null,
        keywords: null,
    }
}
export default MetaTags;