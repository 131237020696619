import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useCallbackRef from 'hooks/useCallbackRef';
import {
  selectImageAction,
  setImagesAction,
  unselectImageAction,
  updateFilterValuesAction,
  updateImagesAction,
  updateRequestIISearchParamsAction,
} from 'modules/requestTheLook/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { infoMenuConfig } from 'modules/requestTheLook/constants';
import SideBar from 'modules/curateTheLook/createLookBoard/components/SideBar';
import ScrollableDatabase from 'modules/curateTheLook/createLookBoard/components/ScrollableDatabase';
import ImageItem from 'modules/curateTheLook/createLookBoard/components/ImageItem/ImageItem';
import ImagesSearchParamsBlock from 'modules/requestTheLook/createRequest/components/ImagesSearchParamsBlock';
import SelectedImageBlock from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import useCancelToken from 'hooks/useCancelToken';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'components/ui/Button/Button';
import { routesByName } from 'constants/routes';
import { limit, unauthorizedLimit } from 'constants/inspirationImages';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import { createImageInstantlyForAction } from 'modules/inspirationImage/store/actions';
import featureNameKeys from 'constants/featureNames';
import LookBoardExamplesModal from 'modules/curateTheLook/createLookBoard/components/LookBoardExamplesModal/LookBoardExamplesModal';
import noImagesPlug from 'assets/img/curate-the-look-page/noImagesPlug.jpg';
import mergeLookBoardsWithProducts from 'utils/mergeLookBoardsWithProducts';
import classes from '../../curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import { initialFilterValues } from '../../getTheLook/store/reducer';
import {
  imagePageTypes,
  imageSourceKeys,
} from '../../../constants/inspirationImageSearchParams';

const LeftPanel = ({ onOpenPreview }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user);
  const {
    imageList: imageIdList,
    inspirationImageSearchParams: searchParams,
    filterValues,
    requestData: { inspirationImageId: selectedImage },
  } = useSelector((state) => state.requestTheLook);
  const library = useSelector((state) => state.inspirationImage.library);
  const imageList = useMemo(() => imageIdList.map((id) => library[id]), [
    imageIdList,
    library,
  ]);
  const enums = useSelector((state) => state.app.enums);

  const history = useHistory();
  const { pathname } = useLocation();
  const preferences = useSelector((state) => state.auth.user?.preferences);
  const [scrollBarNode, scrollBarRef] = useCallbackRef();
  const [scrollPosition, setScrollPosition] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    initialScrollableDatabaseHeight,
    setInitialScrollableDatabaseHeight,
  ] = useState(0);

  const [lookBoardsImgId, setLookBoardsImgId] = useState(null);
  const [lookBoardExamplesModalOpen, setLookBoardExamplesModalOpen] = useState(
    false
  );
  const [lookBoardExamples, setLookBoardExamples] = useState([]);
  const [lookBoardExamplesLoading, setLookBoardExamplesLoading] = useState(
    false
  );

  const [createCancelToken, isCancelled] = useCancelToken();

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();
      const colors = Object.values(enums.colors)
        .filter((color) => filterValues.colors.includes(color.color_group_id))
        .map((color) => color.id);

      const [transformedList, list] = await inspirationImageService.getImages(
        {
          ...searchParams,
          ...(searchParams.search.trim().length
            ? initialFilterValues
            : { ...filterValues, colors }),
          pageType: imagePageTypes.database,
        },
        { cancelToken }
      );
      const imageArrLength = Object.keys(list).length;

      dispatch(setImagesAction(transformedList, list));

      if (imageArrLength > 0) {
        dispatch(
          updateRequestIISearchParamsAction({
            offset: searchParams.offset + imageArrLength,
          })
        );
      }

      if (scrollBarNode) {
        scrollBarNode.scrollToTop();
      }

      setHasMore(imageArrLength === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error loadFirst', e.generalError);
      }
    }
  }, [
    dispatch,
    searchParams,
    filterValues,
    scrollBarNode,
    isCancelled,
    createCancelToken,
  ]);

  useEffect(() => {
    const filters = {
      colors: preferences
        ? [...new Set(Object.values(preferences.colors).map((c) => Number(c)))]
        : [],
      styles: preferences ? preferences.styles : [],
      roomTypes: preferences ? preferences.roomTypes : [],
      subStyles: preferences?.subStyles ?? [],
    };
    dispatch(updateFilterValuesAction(filters));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(updateRequestIISearchParamsAction({ offset: 0 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0 && scrollBarNode) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams, scrollBarNode]);

  useEffect(() => {
    if (!selectedImage && scrollBarNode) {
      scrollBarNode.scrollTop(scrollPosition);
    }
    // eslint-disable-next-line
  }, [selectedImage, scrollBarNode]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    const colors = Object.values(enums.colors)
      .filter((color) => filterValues.colors.includes(color.color_group_id))
      .map((color) => color.id);

    try {
      const [transformedList, list] = await inspirationImageService.getImages({
        ...searchParams,
        ...(searchParams.search.trim().length
          ? initialFilterValues
          : { ...filterValues, colors }),
        pageType: imagePageTypes.database,
      });

      const imageArrLength = Object.keys(list).length;
      const updatedListLength = searchParams.offset + imageArrLength;

      dispatch(updateImagesAction(transformedList, list));
      dispatch(
        updateRequestIISearchParamsAction({ offset: updatedListLength })
      );

      if (!currentUser && updatedListLength >= unauthorizedLimit) {
        setHasMore(false);
      } else {
        setHasMore(imageArrLength === limit);
      }
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [currentUser, dispatch, searchParams, filterValues]);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      dispatch(
        updateRequestIISearchParamsAction({
          ...params,
          offset: 0,
        })
      );

      setHasMore(false);
    },
    [dispatch]
  );

  const handleSelectImage = useCallback(
    (imgId) => {
      if (scrollBarNode) {
        const currentScroll = scrollBarNode.getScrollTop();
        setScrollPosition(currentScroll);
      }
      dispatch(selectImageAction(imgId));
      // updateRequestData()
    },
    [dispatch, scrollBarNode]
  );

  const handleRegisterClick = useCallback(() => {
    history.push(
      `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
      { title: 'REGISTER NOW' }
    );
  }, [history, pathname]);

  useEffect(() => {
    (async () => {
      if (lookBoardsImgId) {
        setLookBoardExamples([]);
        setLookBoardExamplesLoading(true);

        try {
          const {
            result: { lookBoards, products },
          } = await inspirationImageService.getLookBoardDetails(
            lookBoardsImgId,
            {
              params: {
                limit: 6,
              },
            }
          );
          const lookBoardList = mergeLookBoardsWithProducts(
            lookBoards,
            products
          );

          setLookBoardExamplesLoading(false);
          setLookBoardExamples(lookBoardList);
        } catch (e) {
          setLookBoardExamplesLoading(false);
          errorToastr('Error', e.message);
        }
      }
    })();
  }, [lookBoardsImgId]);

  const handleOpenLookBoardExamplesModal = useCallback((id) => {
    setLookBoardsImgId(id);
    setLookBoardExamplesModalOpen(true);
  }, []);

  const handleCloseLookBoardExamplesModal = useCallback(() => {
    setLookBoardExamplesModalOpen(false);
  }, []);

  const handleAddImageModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(createImageInstantlyForAction(featureNameKeys.curate));
  }, [currentUser, dispatch, pathname, history]);

  return (
    <div className="d-flex">
      <SideBar
        currentSource={searchParams.source}
        onUpdateSearchParams={handleUpdateSearchParams}
        unselectImage={() => dispatch(unselectImageAction())}
        infoMenuConfig={infoMenuConfig}
      />
      <div className={createLookBoardClasses.sidePanel}>
        <ImagesSearchParamsBlock
          currentSource={searchParams.source}
          currentImgType={searchParams.type}
          searchQuery={searchParams.search}
          selectedImage={selectedImage}
          onUpdateSearchParams={handleUpdateSearchParams}
        />
        {selectedImage ? (
          <SelectedImageBlock
            inspirationImageId={selectedImage}
            searchParams={searchParams}
            onBack={() => dispatch(unselectImageAction())}
            onOpenPreview={onOpenPreview}
            onSelectImage={(id) => dispatch(selectImageAction(id))}
            onViewLookBoardsModal={handleOpenLookBoardExamplesModal}
            showMagnifyIcon
          />
        ) : (
          <ScrollableDatabase
            initialHeight={initialScrollableDatabaseHeight}
            setInitialHeight={setInitialScrollableDatabaseHeight}
            scrollBarRef={scrollBarRef}
            loading={loading}
            hasMore={hasMore}
            loadMore={loadMore}
          >
            {imageList.length ? (
              imageList.map(
                ({
                  id,
                  url,
                  isLiked,
                  shareUrl,
                  media: { userId, hash },
                  image_source_name: imageSourceName,
                  source_url: sourceUrl,
                  title,
                  lookBoardsCount,
                  approval,
                  publish,
                  slug,
                }) => (
                  <ImageItem
                    key={id}
                    slug={slug}
                    id={id}
                    url={url}
                    userId={userId}
                    hash={hash}
                    isLiked={isLiked}
                    shareUrl={shareUrl}
                    onOpenPreview={onOpenPreview}
                    onSelectImage={handleSelectImage}
                    imageSource={imageSourceName}
                    title={title}
                    sourceUrl={sourceUrl}
                    lookBoardsCount={lookBoardsCount}
                    onLookBoardsView={
                      searchParams.source === imageSourceKeys.all
                        ? handleOpenLookBoardExamplesModal
                        : undefined
                    }
                    approval={approval}
                    publish={publish}
                  />
                )
              )
            ) : (
              <div className={classes.noImagesWrapper}>
                <h5 className={classes.noImagesWrapper__title}>
                  Pin images to view them here
                </h5>
                <IconButton
                  color="primary"
                  size="lg"
                  onClick={handleAddImageModalOpen}
                  className={classes.noImagesWrapper__button}
                >
                  <SpriteIcon name="pin" size="lg" />
                </IconButton>
                <img
                  src={noImagesPlug}
                  className={classes.noImagesWrapper__plug}
                  alt="noImages"
                />
              </div>
            )}
            {!currentUser && searchParams.offset >= unauthorizedLimit && (
              <div className="font-weight-normal d-flex justify-content-center pb-2">
                <Button
                  size="md"
                  className={clsx('w-100 mx-auto', classes.btn)}
                  onClick={handleRegisterClick}
                >
                  Register Now to View All Images
                </Button>
              </div>
            )}
          </ScrollableDatabase>
        )}
      </div>
      <LookBoardExamplesModal
        lookBoards={lookBoardExamples}
        open={lookBoardExamplesModalOpen}
        onClose={handleCloseLookBoardExamplesModal}
        loading={lookBoardExamplesLoading}
        inspirationImgInfo={lookBoardsImgId}
        title="Pre-curated lookboards for that image"
      />
    </div>
  );
};

LeftPanel.propTypes = {
  onOpenPreview: PropTypes.func.isRequired,
};

export default LeftPanel;
